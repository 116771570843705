import React from 'react';
import '@atomfinance/atom-viz-web-components';

const NewsWrapper = ({ asset }) => {
  return (
    <>
      <atom-news-feed asset={asset} />
    </>
  );
};

export default NewsWrapper;
