import React from 'react';
import styled from 'styled-components';
import { useStreaming } from './hooks';
import { format } from 'd3';
import AtomIcon from './AtomIcon';
import { AtomIconRotatedContainer } from './commonStyledElements';

const MarketPill = ({ ticker, name, color }) => {
  const streamingFields = ['changePercent', 'extendedChangePercent'];
  const quote = useStreaming(ticker, streamingFields);

  let textColor = 'var(--text-primary)',
    direction = 'up';
  if (quote?.changePercent) {
    textColor =
      quote.changePercent < 0 ? 'var(--price-down)' : 'var(--price-up)';
    direction = quote.changePercent < 0 ? 'down' : 'up';
  }
  return (
    <StyledPill>
      <Left>
        <Color color={color} />
        <Name>{name}</Name>
      </Left>
      <Right>
        {quote && (
          <>
            <AtomIconRotatedContainer direction={direction}>
              <AtomIcon
                icon='price-arrow'
                size={5}
                iconCss={`fill: var(--price-${direction});`}
              />
            </AtomIconRotatedContainer>
            <StyledValue textColor={textColor}>
              {format('.2%')(Math.abs(quote.changePercent))}
            </StyledValue>
          </>
        )}
      </Right>
    </StyledPill>
  );
};

const StyledPill = styled.div`
  display: flex;
  height: 2.5em;
  min-width: 150px;
  flex-grow: 1;
  justify-content: space-between;
  background-color: var(--background-secondary);
  border-radius: 5px;
  padding: 4px 20px;
  font-size: 1em;
`;

const Left = styled.div`
  display: flex;
  margin: auto 0;
  height: 1em;
  font-weight: 600;
`;

const Name = styled.div`
  display: inline-block;
  line-height: 1em;
`;

const Color = styled.div<{ color: string }>`
  display: inline-block;
  background-color: ${({ color }) => color};
  width: 5px;
  height: 1em;
  margin-right: 8px;
  border-radius: 2px;
`;

const Right = styled.div`
  display: flex;
  line-height: 1em;
  margin: auto 0;
`;

const StyledValue = styled.span<{ textColor: string }>`
  color: textColor;
`;

const MarketPills = ({ marketReferences }) => {
  return (
    <StyledContainer>
      <PillsRow>
        {marketReferences.slice(0, marketReferences.length / 2).map(ref => (
          <MarketPill
            key={ref.symbol}
            ticker={ref.symbol}
            name={ref.name}
            color={ref.color}
          />
        ))}
      </PillsRow>
      <PillsRow>
        {marketReferences.slice(marketReferences.length / 2).map(ref => (
          <MarketPill
            key={ref.symbol}
            ticker={ref.symbol}
            name={ref.name}
            color={ref.color}
          />
        ))}
      </PillsRow>
    </StyledContainer>
  );
};

export default React.memo(MarketPills);

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  padding: 20px;
`;

const PillsRow = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-evenly;
  gap: 5px;
  flex-wrap: wrap;
`;
