import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { GET_CHART_DATA } from '../common/queries';
import { useQuery } from '@apollo/react-hooks';
import {
  AtomMarketsChart,
  PriceTimeDomains,
  widgetChartSpanMapping,
} from '@atom-finance/atom-viz';
import TimeSelection, { dateFormatMapping } from './TimeSelection';
import { Spinner } from './ui';
import { MarketReference } from '../routes/MarketsWidget';

const MarketsChart = ({
  marketReferences,
}: {
  marketReferences: MarketReference[];
}) => {
  const symbols = useMemo(
    () => marketReferences.map(({ symbol }) => symbol),
    [marketReferences],
  );
  const colors = marketReferences.reduce(
    (t, { symbol, color }) => ({ ...t, [symbol]: color }),
    {},
  );
  const [timespan, setTimespan] = useState<string>(PriceTimeDomains.oneDay);
  const [data, setData] = useState<[] | any[]>([]);
  const [resizeTrigger, setResizeTrigger] = useState(0);
  const containerRef = useRef(null);

  const now = DateTime.now().startOf('hour');
  const { periodUnit, periodLength, startDateFn } =
    widgetChartSpanMapping[timespan];
  const startDate = startDateFn(now);

  /* Query Data and Transform */
  const endDate = now;
  const { loading, data: rawData } = useQuery(GET_CHART_DATA, {
    variables: {
      symbols: symbols.map(symbol => ({ symbol })),
      periodUnit,
      periodLength,
      startDate,
      endDate,
    },
  });
  useEffect(() => {
    if (loading) setData([]);
    if (!loading && rawData) {
      if (rawData.chartData) {
        setData(
          rawData.chartData?.reduce(
            (t, data, index) => ({
              ...t,
              [symbols[index]]: calculatePercentChange(
                data?.quotes.filter(d => d.Close !== null),
              ),
            }),
            {},
          ),
        );
      }
    }
  }, [symbols, loading, rawData]);
  const formats = dateFormatMapping[timespan];

  const resizeObserver = new ResizeObserver(function (entries) {
    setResizeTrigger(entries[0].contentRect.width);
  });
  if (containerRef.current) resizeObserver.observe(containerRef.current);

  if (data === undefined) return null;

  return (
    <StyledContainer>
      <TimeSelection timespan={timespan} callback={setTimespan} />
      <StyledPriceLineContainer ref={containerRef}>
        {loading ? (
          <Spinner />
        ) : (
          <AtomMarketsChart
            resize={resizeTrigger}
            data={data}
            colors={colors}
            margin={{ top: 10, left: 10, right: 50, bottom: 40 }}
            axisDateFormat={formats.axis}
            tooltipDateFormat={formats.tooltip}
            crosshair={true}
            dateDomain={[startDate, now]}
          ></AtomMarketsChart>
        )}
      </StyledPriceLineContainer>
    </StyledContainer>
  );
};

export default React.memo(MarketsChart);

const StyledContainer = styled.div`
  box-sizing: inherit;
  padding: 20px;
  overflow: hidden;

  svg {
    overflow: visible;
  }

  .percent-line {
    fill: none;
    stroke: var(--green);
  }

  .tick {
    line {
      stroke: var(--text-primary);
      stroke-opacity: 0.1;
    }
    text {
      fill: var(--text-primary);
      font-size: 0.75em;
    }
  }
`;

const StyledPriceLineContainer = styled.div`
  width: 100%;
  min-width: 200px;
  height: 200px;
  padding: 0;
  margin: 0;
`;

// TODO: [EF] we should probably calculate this anywhere but here
function calculatePercentChange(quotes) {
  const reference = quotes[0];
  return quotes.map(quote => ({
    ...quote,
    changePercent: (quote.Close - reference.Close) / reference.Close,
  }));
}
