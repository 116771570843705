import { useMutation } from '@apollo/react-hooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import AtomIcon from '../../../common/AtomIcon';
import { SIGN_UP } from '../../../common/queries';
import { Spinner } from '../../../common/ui';
import { SimpleTextBox } from './TextBox';
import { TextButton } from './TextButton';

// Stolen from admin-client
type Checkbox = {
  label: string;
  checked: boolean;
  updateBox: () => void;
};

const Checkbox = ({ label, checked, updateBox }: Checkbox) => {
  // style div, style input?
  return (
    <div>
      <input type={'checkbox'} checked={checked} onChange={updateBox} />
      <label htmlFor={'checkbox'}>{label}</label>
    </div>
  );
};

export const GettingStartedContent = ({
  setModalIsOpen,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  workEmail,
  setWorkEmail,
  password,
  setPassword,
  companyName,
  setCompanyName,
  confirmedPassword,
  setConfirmedPassword,
  setModalContent,
  jobTitle,
  setJobTitle,
  companyWebsite,
  setCompanyWebsite,
  // this allowCommunications boolean is currently unused, but it will eventually be used for HubSpot tracking,
  // so will just leave this here for the time being
  allowCommunications,
  setAllowCommunications,
}) => {
  const [signUp, signUpResponse] = useMutation(SIGN_UP);
  const signInButtonRef: any = useRef(null);
  const [hasScrolledOnce, setHasScrollOnce] = useState(false);

  const signUpUser = useCallback(
    async (
      firstName: string,
      lastName: string,
      companyName: string,
      email: string,
      password: string,
      confirmPassword: string,
      companyWebsite: string | undefined,
    ) => {
      signUp({
        variables: {
          firstName,
          lastName,
          companyName,
          email,
          password,
          confirmPassword,
          referrer: 'viz-client-sign-up',
          companyWebsite,
        },
      });
    },
    [signUp],
  );

  const shouldScrollToBottom =
    !!firstName.length &&
    !!lastName.length &&
    !!jobTitle.length &&
    !!companyName.length &&
    !!workEmail.length;

  // since the scroll bar might be confusing for some users, we scroll automatically to the bottom once
  // they have filled in a certain amount of fields. we only need to run this scroll function once, since by then, the user should know it is scrollable
  useEffect(() => {
    if (shouldScrollToBottom && signInButtonRef?.current && !hasScrolledOnce) {
      signInButtonRef.current?.scrollIntoView({ behavior: 'smooth' });
      setHasScrollOnce(true);
    }
  }, [shouldScrollToBottom, hasScrolledOnce]);

  const { loading, data } = signUpResponse;
  const success = data?.signUp?.success;
  const message = data?.signUp?.message;
  const disableSubmit =
    !firstName?.length ||
    !lastName?.length ||
    !companyName?.length ||
    !workEmail?.length ||
    !password?.length ||
    !confirmedPassword?.length ||
    !jobTitle?.length ||
    loading;

  if (success) {
    setModalContent('confirmEmail');
  }

  return (
    <ModalContentContainer>
      <AtomIcon
        icon='close'
        size={24}
        iconCss={'fill: var(--green)'}
        onClick={() => {
          setModalIsOpen(false);
        }}
        style={{
          alignSelf: 'flex-end',
          paddingTop: '1rem',
          paddingRight: '1rem',
        }}
      />
      <HeaderTitle>{`Let's get started`}</HeaderTitle>
      <TextInputContainer>
        <ButtonRowContainer>
          <ContainerWithWidth width={50}>
            <SimpleTextBox
              current={firstName}
              update={setFirstName}
              height={3.5}
              placeholder='First name'
            ></SimpleTextBox>
          </ContainerWithWidth>
          <ContainerWithWidth width={50}>
            <SimpleTextBox
              current={lastName}
              update={setLastName}
              height={3.5}
              placeholder='Last name'
            ></SimpleTextBox>
          </ContainerWithWidth>
        </ButtonRowContainer>
        <ButtonRowContainer>
          <ContainerWithWidth width={50}>
            <SimpleTextBox
              current={jobTitle}
              update={setJobTitle}
              height={3.5}
              placeholder='Job title'
            ></SimpleTextBox>
          </ContainerWithWidth>
          <ContainerWithWidth width={50}>
            <SimpleTextBox
              current={companyWebsite}
              update={setCompanyWebsite}
              height={3.5}
              placeholder='Company website'
            ></SimpleTextBox>
          </ContainerWithWidth>
        </ButtonRowContainer>
        <ButtonRowContainer>
          <ContainerWithWidth width={50}>
            <SimpleTextBox
              current={companyName}
              update={setCompanyName}
              height={3.5}
              placeholder='Company name'
            ></SimpleTextBox>
          </ContainerWithWidth>
          <ContainerWithWidth width={50}>
            <SimpleTextBox
              current={workEmail}
              update={setWorkEmail}
              height={3.5}
              placeholder='Work email address'
            ></SimpleTextBox>
          </ContainerWithWidth>
        </ButtonRowContainer>
        <ButtonRowContainer>
          <ContainerWithWidth width={50}>
            <SimpleTextBox
              current={password}
              update={setPassword}
              height={3.5}
              placeholder='Password'
              type='password'
            ></SimpleTextBox>
          </ContainerWithWidth>
          <ContainerWithWidth width={50}>
            <SimpleTextBox
              current={confirmedPassword}
              update={setConfirmedPassword}
              height={3.5}
              placeholder='Confirm password'
              type='password'
            ></SimpleTextBox>
          </ContainerWithWidth>
        </ButtonRowContainer>
      </TextInputContainer>
      <ResponseText success={success}>{message}</ResponseText>
      <PrivacyBlock>
        <Disclaimer>
          Before you submit your contact details, please read our
          <a href='https://go.atom.finance/legal/privacy-policy'>
            {' '}
            Privacy Policy{' '}
          </a>
          to understand how we will handle your information.
        </Disclaimer>
        <Checkbox
          label={
            ' Atom Finance may contact you about relevant content, products, and services.\n' +
            'Please check this box if you do NOT wish to receive our communications.'
          }
          checked={!allowCommunications}
          updateBox={() => setAllowCommunications(!allowCommunications)}
        />
      </PrivacyBlock>
      <TextButtonsContainer>
        <TextButton
          style={{ width: '25rem', borderRadius: '3px' }}
          backgroundColor='var(--green)'
          hoverColor='var(--green-hover)'
          onClick={() => {
            if (!disableSubmit)
              signUpUser(
                firstName,
                lastName,
                companyName,
                workEmail,
                password,
                confirmedPassword,
                companyWebsite,
              );
          }}
          disabled={disableSubmit}
        >
          {loading ? <Spinner smaller={true} /> : `CREATE ACCOUNT`}
        </TextButton>
        <TextButton
          style={{
            width: '25rem',
            color: '#99999F',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            columnGap: '0.25rem',
            marginBottom: '2rem',
          }}
          ref={signInButtonRef}
          onClick={() => {
            setModalContent('partnerLogin');
          }}
        >
          HAVE AN ACCOUNT?&nbsp;
          <span style={{ color: '#52e3c2' }}>SIGN IN</span>
        </TextButton>
      </TextButtonsContainer>
    </ModalContentContainer>
  );
};

export const ResponseText = styled.div<{ success?: boolean }>`
  color: var(--atom-${props => (props.success ? 'dark-green' : 'red')});
  padding-top: 2px;
  text-align: center;
`;

const PrivacyBlock = styled.div`
  margin-top: -1rem;
  margin-bottom: 1rem;
  width: 80%;
  max-width: 44em;
`;

const Disclaimer = styled.div`
  justify-content: left;
  padding-bottom: 10px;
  & > a {
    color: var(--green);
    &:hover {
      color: var(--green-hover);
    }
  }
`;

const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  row-gap: 1rem;
`;

const ButtonRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 1rem;
`;

const ContainerWithWidth = styled.div<{ width: number }>`
  background-color: var(--background-secondary);
  border-radius: 3px;
  width: ${props => props.width}%;
`;

const TextButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
`;

const HeaderTitle = styled.div`
  font-size: 48px;
`;

const ModalContentContainer = styled.div`
  background-color: #32323e;
  width: 60rem;
  height: 36rem;
  box-shadow: 0px 0px 30px -5px #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
  overflow-y: scroll;

  /* Custom scroll bar styling since default was ugly, but we can remove this if needed */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--background-secondary);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--atom-brand-6);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--atom-brand-7);
  }
`;
