import { useStreaming } from '../hooks';
import {
  getMarketStatus,
  isLuxWeekend,
  MarketStatus,
  momentToLux,
} from '@atom-finance/atom-types';
import moment from 'moment-timezone';
import { DateTime } from 'luxon';

enum OTCTiers {
  'PK',
  'QX',
  'QB',
}

const streamingFields = [
  'ask',
  'bid',
  'changePercent',
  'extendedChange',
  'extendedChangePercent',
  'extendedPrice',
  'extendedPriceTime',
  'highlightClass',
  'price',
  'volume',
];

export const useTickerData = ticker => {
  const tickerData = useStreaming(ticker, streamingFields);
  const isDownOnDay = tickerData && tickerData.changePercent < 0;

  return {
    tickerData,
    isDownOnDay,
  };
};

export const marketText = (symbol: string) => {
  if (process.env.REACT_APP_FOREIGN_ON_CORE) {
    let suffix = symbol.split(':')[1];

    //Replace tier suffix with country code
    if (Object.values(OTCTiers).includes(suffix)) {
      suffix = 'US';
    }

    const text = suffix ? `Market: ${suffix}` : 'Market: US';
    return text;
  }

  return 'Company';
};

export const isOutsideNormalMarketHours = (exchangeCode, time = undefined) => {
  /**
   * Status indicates the current market status
   * * 0: Market Closed 8:00pm - 3:59am
   * * 1: Pre-Market 4:00am - 9:29am
   * * 2: Market Open 9:30am - 4:00pm
   * * 3: After-Hours 4:01pm - 7:59pm
   */

  // if no time is passed in then it will use current time
  // if time is passed in it will check if that time is outside of normal market hours
  if (isLuxWeekend(momentToLux(moment(time)))) return true;

  let marketStatus;
  if (time) {
    marketStatus = getMarketStatus(exchangeCode, DateTime.fromISO(time));
  } else {
    marketStatus = getMarketStatus(exchangeCode);
  }

  return marketStatus !== MarketStatus.Open;
};

const extractLatestPriceTime = (
  { priceLatestTime, extendedPriceTime },
  exchangeCode,
) => {
  // if current time (EST) is outside market hours
  // and last trade is outside of market hours (EST)
  // and it has a value
  // then return that last trade time
  if (
    isOutsideNormalMarketHours(exchangeCode) &&
    extendedPriceTime &&
    isOutsideNormalMarketHours(exchangeCode, extendedPriceTime) &&
    (!priceLatestTime ||
      moment(extendedPriceTime).isAfter(moment(priceLatestTime)))
  )
    return extendedPriceTime;

  return priceLatestTime;
};

const isDelayedQuote = (symbol: string) => {
  const suffix = symbol.split(':')[1];
  if (suffix === 'CA' || Object.values(OTCTiers).includes(suffix)) {
    return true;
  }

  return false;
};

export const getQuoteTimeText = (tickerData, exchangeCode) => {
  const latestPriceTimeUTC = extractLatestPriceTime(tickerData, exchangeCode);
  const latestPriceTimeEST = moment(latestPriceTimeUTC).tz('America/New_York');

  const isYesterdayTrade =
    latestPriceTimeEST.day() !== moment().tz('America/New_York').day();

  const tradeTime = latestPriceTimeEST.format(
    `${isYesterdayTrade ? 'ddd, ' : ''} h:mma`,
  );

  const serviceType = isDelayedQuote(tickerData.symbol)
    ? 'Delayed'
    : 'Real Time';

  return `${serviceType} (last trade ${tradeTime} ${
    latestPriceTimeEST.isDST() ? 'EDT' : 'EST'
  })`;
};

export const formatChange = (change, isPercent) => {
  return (change * (isPercent ? 100 : 1)).toFixed(2).replace('-', '');
};
