"use strict";

var _defineProperty = require("/home/node/workspace/viz-client/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _slicedToArray = require("/home/node/workspace/viz-client/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

var _exports$widgetChartS;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.widgetChartSpanMapping = exports.PriceTimeDomains = void 0;

var luxon_1 = require("luxon");

var atom_types_1 = require("@atom-finance/atom-types");

var PriceTimeDomains;

(function (PriceTimeDomains) {
  PriceTimeDomains["oneDay"] = "1D";
  PriceTimeDomains["fiveDay"] = "5D";
  PriceTimeDomains["twoWeek"] = "2W";
  PriceTimeDomains["oneMonth"] = "1M";
  PriceTimeDomains["threeMonth"] = "3M";
  PriceTimeDomains["sixMonth"] = "6M";
  PriceTimeDomains["yearToDate"] = "YTD";
  PriceTimeDomains["oneYear"] = "1Y";
  PriceTimeDomains["twoYear"] = "2Y";
  PriceTimeDomains["fiveYear"] = "5Y";
  PriceTimeDomains["max"] = "MAX";
})(PriceTimeDomains = exports.PriceTimeDomains || (exports.PriceTimeDomains = {}));

exports.widgetChartSpanMapping = (_exports$widgetChartS = {}, _defineProperty(_exports$widgetChartS, PriceTimeDomains.oneDay, {
  periodUnit: 'Minute',
  periodLength: 5,
  startDateFn: function startDateFn(now) {
    var exchangeCode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : atom_types_1.TrExchangeCode.bzx;
    var marketStatus = (0, atom_types_1.getMarketStatus)(exchangeCode);

    if (marketStatus === atom_types_1.MarketStatus.Closed) {
      var lastMarketDate = (0, atom_types_1.getLastMarketDay)(now, exchangeCode, undefined, true);
      return luxon_1.DateTime.fromJSDate(lastMarketDate);
    } else {
      var _ref = (0, atom_types_1.getTodayMarketOpenClose)(now, exchangeCode, true),
          _ref2 = _slicedToArray(_ref, 1),
          open = _ref2[0];

      return open;
    }
  }
}), _defineProperty(_exports$widgetChartS, PriceTimeDomains.fiveDay, {
  periodUnit: 'Minute',
  periodLength: 15,
  startDateFn: function startDateFn(now) {
    return now.minus({
      days: 5
    });
  }
}), _defineProperty(_exports$widgetChartS, PriceTimeDomains.twoWeek, {
  periodUnit: 'Minute',
  periodLength: 60,
  startDateFn: function startDateFn(now) {
    return now.minus({
      days: 14
    });
  }
}), _defineProperty(_exports$widgetChartS, PriceTimeDomains.oneMonth, {
  periodUnit: 'Day',
  periodLength: 1,
  startDateFn: function startDateFn(now) {
    return now.minus({
      days: 30
    });
  }
}), _defineProperty(_exports$widgetChartS, PriceTimeDomains.threeMonth, {
  periodUnit: 'Day',
  periodLength: 1,
  startDateFn: function startDateFn(now) {
    return now.minus({
      months: 3
    });
  }
}), _defineProperty(_exports$widgetChartS, PriceTimeDomains.sixMonth, {
  periodUnit: 'Day',
  periodLength: 1,
  startDateFn: function startDateFn(now) {
    return now.minus({
      months: 6
    });
  }
}), _defineProperty(_exports$widgetChartS, PriceTimeDomains.yearToDate, {
  periodUnit: 'Day',
  periodLength: 1,
  startDateFn: function startDateFn(now) {
    return luxon_1.DateTime.fromObject({
      year: now.get('year')
    });
  }
}), _defineProperty(_exports$widgetChartS, PriceTimeDomains.oneYear, {
  periodUnit: 'Day',
  periodLength: 1,
  startDateFn: function startDateFn(now) {
    return now.minus({
      years: 1
    });
  }
}), _defineProperty(_exports$widgetChartS, PriceTimeDomains.twoYear, {
  periodUnit: 'Day',
  periodLength: 1,
  startDateFn: function startDateFn(now) {
    return now.minus({
      years: 2
    });
  }
}), _defineProperty(_exports$widgetChartS, PriceTimeDomains.fiveYear, {
  periodUnit: 'Day',
  periodLength: 14,
  startDateFn: function startDateFn(now) {
    return now.minus({
      years: 5
    });
  }
}), _exports$widgetChartS);