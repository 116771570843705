import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { AtomDonut } from '@atom-finance/atom-viz';
import { SimpleTextBox } from './ui/TextBox';
import { ColorPreviewWithPalette } from './ui/ColorPreviewWithPalette';
import { ToggleButton } from './ui/ToggleButton';
import { RadioSlider } from './ui/RadioSlider';
import { TextButton } from './ui/TextButton';
import { AtomTooltip } from './ui/Tooltip';
import { APIGenerationModal } from './ui/APIGenerationModal';
import { ETF_HOLDINGS_DIST } from '../../common/queries';
import { useQuery } from '@apollo/react-hooks';
import {
  HoldingDistribution,
  HoldingsDistributionResponse,
} from '../FundAllocation';
import { Spinner } from '../../common/ui';
import useElementSize from '../../common/hooks';
import AtomIcon from '../../common/AtomIcon';
import { SymbolTextBox } from './ui/SymbolTextBox';
import { WidgetType } from '../../helpers/generateWidgetCode';

const defaultSettings = {
  asset: 'SPY',
  textColor: '#FFFFFF',
  bgColor: '#282833',
  hasLegendToggled: true,
  color1: '#52E3C2',
  color2: '#5FC0F0',
  color3: '#5194B9',
  color4: '#AC5BBE',
  color5: '#EFB068',
  color6: '#F5DD50',
  color7: '#EFB068',
  color8: '#E1953C',
  color9: '#459C8D',
  color10: '#B65F57',
  color11: '#F27362',
  color12: '#E0E0E0',
};

const DemoFundAllocation = () => {
  /* Demo Page State Items */
  const [asset, setAsset] = useState(defaultSettings.asset);
  const [textColor, setTextColor] = useState(defaultSettings.textColor);
  const [legend, setHasLegendToggled] = useState(
    defaultSettings.hasLegendToggled,
  );
  const [color1, setColor1] = useState(defaultSettings.color1);
  const [color2, setColor2] = useState(defaultSettings.color2);
  const [color3, setColor3] = useState(defaultSettings.color3);
  const [color4, setColor4] = useState(defaultSettings.color4);
  const [color5, setColor5] = useState(defaultSettings.color5);
  const [color6, setColor6] = useState(defaultSettings.color6);
  const [color7, setColor7] = useState(defaultSettings.color7);
  const [color8, setColor8] = useState(defaultSettings.color8);
  const [color9, setColor9] = useState(defaultSettings.color9);
  const [color10, setColor10] = useState(defaultSettings.color10);
  const [color11, setColor11] = useState(defaultSettings.color11);
  const [color12, setColor12] = useState(defaultSettings.color12);
  const [bgColor, setBgColor] = useState(defaultSettings.bgColor);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [width, setWidth] = useState<number>(350);
  const [height, setHeight] = useState<number>(650);

  /* Viz State Items */
  const [containerRefSizer, { width: vizWidth, height: vizHeight }] =
    useElementSize();
  const [data, setData] = useState<undefined | HoldingDistribution[]>();
  const [selection, setSelection] = useState<'Asset' | 'Sector'>('Sector');

  const colors = [
    [color1, setColor1, 'color1'],
    [color2, setColor2, 'color2'],
    [color3, setColor3, 'color3'],
    [color4, setColor4, 'color4'],
    [color5, setColor5, 'color5'],
    [color6, setColor6, 'color6'],
    [color7, setColor7, 'color7'],
    [color8, setColor8, 'color8'],
    [color9, setColor9, 'color9'],
    [color10, setColor10, 'color10'],
    [color11, setColor11, 'color11'],
    [color12, setColor12, 'color12'],
  ];

  const resetSettings = () => {
    setAsset(defaultSettings.asset);
    setColor1(defaultSettings.color1);
    setColor2(defaultSettings.color2);
    setColor3(defaultSettings.color3);
    setColor4(defaultSettings.color4);
    setColor5(defaultSettings.color5);
    setColor6(defaultSettings.color6);
    setColor7(defaultSettings.color7);
    setColor8(defaultSettings.color8);
    setColor9(defaultSettings.color9);
    setColor10(defaultSettings.color10);
    setColor11(defaultSettings.color11);
    setColor12(defaultSettings.color12);
    setTextColor(defaultSettings.textColor);
    setBgColor(defaultSettings.bgColor);
    setHasLegendToggled(defaultSettings.hasLegendToggled);
  };

  const widgetSettings = {
    asset,
    colors: [
      color1,
      color2,
      color3,
      color4,
      color5,
      color6,
      color7,
      color8,
      color9,
      color10,
      color11,
      color12,
    ].join(','),
    textColor,
    bgColor,
    legend,
  };

  /* Query Data and Transform */
  const { loading, data: rawData } = useQuery<{
    etfHoldingsDistribution: HoldingsDistributionResponse;
  }>(ETF_HOLDINGS_DIST, {
    variables: { symbol: asset },
  });
  useEffect(() => {
    if (!loading && rawData) {
      const keyLookup = {
        'Asset': 'assetDistribution',
        'Sector': 'sectorDistribution',
      };
      if (rawData.etfHoldingsDistribution) {
        const data = rawData.etfHoldingsDistribution[keyLookup[selection]];
        setData(
          data?.map(d => ({
            label: d.name,
            value: d.weight,
          })),
        );
      }
    }
  }, [loading, rawData, selection]);

  // [EF] Preserve in case we want to bring back width/height dimension edits after QA
  // useEffect(() => {
  //   setHeight(height);
  //   setWidth(width);
  // }, [width, height]);

  useEffect(() => {
    scroll(0, 0);
  }, []);

  return (
    <Container>
      <APIGenerationModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        widgetType={WidgetType.FundAllocation}
        staticSettings={widgetSettings}
      />
      <>
        <HeaderAndDescriptionContainer>
          <HeaderTitle>Fund Allocation Donut Chart</HeaderTitle>
          <DescriptionLabel>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </DescriptionLabel>
        </HeaderAndDescriptionContainer>
      </>
      <WidgetAndSettingsContainer>
        <WidgetParentContainer>
          <WidgetContainer
            id={'widget'}
            width={width}
            height={height}
            bg={bgColor}
          >
            <WidgetTitle
              textColor={textColor}
            >{`${asset} Allocation Breakdown`}</WidgetTitle>
            {loading && <Spinner />}
            {!loading && data === undefined && (
              <div>There is no data for the selected fund.</div>
            )}
            {!loading && data && (
              <>
                <StyledSliderContainer textColor={textColor}>
                  <RadioSlider
                    options={['Asset', 'Sector']}
                    current={selection}
                    update={setSelection}
                  />
                </StyledSliderContainer>
                <VizContainer ref={containerRefSizer}>
                  <AtomDonut
                    symbol={asset}
                    data={data}
                    legend={legend}
                    width={vizWidth}
                    height={vizHeight}
                    orientation={
                      vizWidth && vizHeight && vizWidth > vizHeight
                        ? 'horizontal'
                        : 'vertical'
                    }
                    colors={colors.map(([color]) => color)}
                    textColor={textColor}
                  />
                  <PoweredByWrapper>
                    <AtomIcon icon='powered-by' size={14} />
                  </PoweredByWrapper>
                </VizContainer>
              </>
            )}
          </WidgetContainer>
        </WidgetParentContainer>
        <WidgetSettingsContainer>
          <Row>
            <SettingsRowContainer>Asset:</SettingsRowContainer>
            <ContainerWithWidth width={27.5}>
              <SymbolTextBox
                current={asset}
                update={setAsset}
                isDisabled={true}
              ></SymbolTextBox>
            </ContainerWithWidth>
          </Row>
          {/* [EF] Preserve in case we want to bring back width/height dimension edits after QA */}
          {/* <Row>
            <SettingsRowContainer>{`Size (W x H):`}</SettingsRowContainer>
            <WidthAndHeightContainer>
              <ContainerWithWidth width={13}>
                <SimpleTextBox
                  current={width}
                  placeholder={width}
                  update={setWidth}
                  noLeftPadding
                ></SimpleTextBox>
              </ContainerWithWidth>
              <WidthHeightDivider>X</WidthHeightDivider>
              <ContainerWithWidth width={13}>
                <SimpleTextBox
                  current={height}
                  placeholder={width}
                  update={setHeight}
                  noLeftPadding
                ></SimpleTextBox>
              </ContainerWithWidth>
            </WidthAndHeightContainer>
          </Row> */}
          <Row>
            <SettingsRowContainer>Colors:</SettingsRowContainer>
            <ColorsContainer>
              {colors.map(([color, setColor, key]) => (
                <ColorPreviewWithPalette
                  key={`${key}`}
                  update={setColor}
                  current={`${color}`}
                  disable={false}
                ></ColorPreviewWithPalette>
              ))}
            </ColorsContainer>
          </Row>
          <Row>
            <SettingsRowContainer>Text Color:</SettingsRowContainer>
            <ColorPreviewWithPalette
              update={setTextColor}
              current={textColor}
              disable={false}
            ></ColorPreviewWithPalette>
          </Row>
          <Row>
            <SettingsRowContainer>Background Color:</SettingsRowContainer>
            <ColorPreviewWithPalette
              update={setBgColor}
              current={bgColor}
              disable={false}
            ></ColorPreviewWithPalette>
            <TooltipContainer>
              <AtomTooltip altText='backgroundColorTooltip' iconHeight='20px'>
                <TooltipTitleText>Background Color</TooltipTitleText>
                <TooltipDescriptionText>
                  {`The module is rendered transparent, so this background color only controls the background color of this page for testing purposes, not the module.`}
                </TooltipDescriptionText>
              </AtomTooltip>
            </TooltipContainer>
          </Row>
          <Row>
            <SettingsRowContainer>Legend:</SettingsRowContainer>
            <ToggleButton
              isToggled={legend}
              onClickHandler={setHasLegendToggled}
            ></ToggleButton>
          </Row>
          <Row>
            <SettingsRowContainer />
            <TextButton
              backgroundColor='var(--atom-dark-brand-4)'
              hoverColor='var(--atom-dark-brand-2)'
              onClick={resetSettings}
              style={{ color: 'var(--contrast)' }}
            >
              RESET SETTINGS
            </TextButton>
          </Row>
        </WidgetSettingsContainer>
      </WidgetAndSettingsContainer>
      <GenerateButtonContainer>
        <TextButton
          backgroundColor='var(--green)'
          hoverColor='var(--green-hover)'
          onClick={() => {
            setModalIsOpen(!modalIsOpen);
          }}
        >
          GENERATE MODULE
        </TextButton>
        {/* <TextButton
          style={{ marginTop: '2rem' }}
          backgroundColor='var(--green)'
          onClick={() => {
            delete localStorage.atom_userLoggedIn;
          }}
        >
          DELETE LOCAL STORAGE KEY
        </TextButton> */}
      </GenerateButtonContainer>
    </Container>
  );
};

export default React.memo(DemoFundAllocation);

const ColorsContainer = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 1.5rem;
  column-gap: 1rem;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
  width: 28rem;
`;

const TooltipTitleText = styled.div`
  font-size: 27px;
  margin-bottom: 1rem;
`;

const TooltipDescriptionText = styled.div`
  font-size: 18px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
`;

// [EF] Preserve in case we want to bring back width/height dimension edits after QA
// const WidthAndHeightContainer = styled.div`
//   column-gap: 0.5rem;
//   display: flex;
//   flex-direction: row;
// `;

const ContainerWithWidth = styled.div<{ width: number }>`
  width: ${props => props.width}rem;
`;

const HeaderAndDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  row-gap: 1.5rem;
  align-items: center;
`;

const HeaderTitle = styled.div`
  font-size: 60px;
`;

const DescriptionLabel = styled.div`
  font-size: 21px;
  max-width: 70rem;
`;

const WidgetAndSettingsContainer = styled.div`
  width: auto;
  margin-left: 18rem;
  margin-right: 18rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media (max-width: 1075px) {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    row-gap: 4rem;
  }
`;

const WidgetContainer = styled.div<{
  width: number;
  height: number;
  bg: string;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--atom-brand-4);
  align-items: center;
  padding: 1.5rem;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  overflow-y: scroll;
  background-color: ${({ bg }) => bg};
`;

const VizContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledSliderContainer = styled.div<{ textColor: string }>`
  margin: 0.75rem auto;
  width: fit-content;
  color: ${({ textColor }) => textColor};
`;

const WidgetSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 1rem;
`;

const WidgetTitle = styled.div<{ textColor: string }>`
  font-size: 24px;
  width: 100%;
  text-align: center;
  color: ${({ textColor }) => textColor};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
  height: 100%;
  background-color: #32323e;
  margin: -2rem;
  padding-top: 2rem;
  padding-bottom: 3rem;
`;

const PoweredByWrapper = styled.div`
  padding: 0.25rem 0.5rem;
  background-color: var(--atom-brand-4);
  width: fit-content;
  position: absolute;
  bottom: 0;
  right: 1rem;
  border-radius: 3px 3px 0px 0px;
  * {
    fill: white;
  }
`;

const GenerateButtonContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 30rem;
`;

const SettingsRowContainer = styled.div`
  width: 10rem;
  text-align: right;
  font-size: 18px;

  @media (max-width: 650px) {
    width: auto;
  }
`;

// [EF] Preserve in case we want to bring back width/height dimension edits after QA
// const WidthHeightDivider = styled.div`
//   font-weight: bold;
//   font-size: 14px;
//   margin-top: auto;
//   margin-bottom: auto;
// `;

const WidgetParentContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TooltipContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  margin-left: -1rem;
`;
