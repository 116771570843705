"use strict";

var _slicedToArray = require("/home/node/workspace/viz-client/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSVGSize = void 0;

var react_1 = require("react");

function useElementSize() {
  // Mutable values like 'ref.current' aren't valid dependencies
  // because mutating them doesn't re-render the component.
  // Instead, we use a state as a ref to be reactive.
  var _ref = (0, react_1.useState)(null),
      _ref2 = _slicedToArray(_ref, 2),
      ref = _ref2[0],
      setRef = _ref2[1];

  var _ref3 = (0, react_1.useState)({
    width: 0,
    height: 0
  }),
      _ref4 = _slicedToArray(_ref3, 2),
      size = _ref4[0],
      setSize = _ref4[1];

  var handleSize = (0, react_1.useCallback)(function () {
    setSize({
      width: (ref === null || ref === void 0 ? void 0 : ref.offsetWidth) || 0,
      height: (ref === null || ref === void 0 ? void 0 : ref.offsetHeight) || 0
    });
  }, [ref === null || ref === void 0 ? void 0 : ref.offsetHeight, ref === null || ref === void 0 ? void 0 : ref.offsetWidth]);
  (0, react_1.useEffect)(function () {
    window.addEventListener('resize', handleSize);
    return function () {
      window.removeEventListener('resize', handleSize);
    };
  });
  (0, react_1.useEffect)(function () {
    handleSize(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref === null || ref === void 0 ? void 0 : ref.offsetHeight, ref === null || ref === void 0 ? void 0 : ref.offsetWidth]);
  return [setRef, size];
}

exports.default = useElementSize;

function useSVGSize() {
  // Mutable values like 'ref.current' aren't valid dependencies
  // because mutating them doesn't re-render the component.
  // Instead, we use a state as a ref to be reactive.
  var _ref5 = (0, react_1.useState)(null),
      _ref6 = _slicedToArray(_ref5, 2),
      ref = _ref6[0],
      setRef = _ref6[1];

  var _ref7 = (0, react_1.useState)({
    width: 0,
    height: 0
  }),
      _ref8 = _slicedToArray(_ref7, 2),
      size = _ref8[0],
      setSize = _ref8[1];

  var handleSize = (0, react_1.useCallback)(function () {
    setSize({
      width: (ref === null || ref === void 0 ? void 0 : ref.clientWidth) || 0,
      height: (ref === null || ref === void 0 ? void 0 : ref.clientHeight) || 0
    });
  }, [ref === null || ref === void 0 ? void 0 : ref.clientHeight, ref === null || ref === void 0 ? void 0 : ref.clientWidth]);
  (0, react_1.useEffect)(function () {
    window.addEventListener('resize', handleSize);
    return function () {
      window.removeEventListener('resize', handleSize);
    };
  });
  (0, react_1.useEffect)(function () {
    handleSize(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref === null || ref === void 0 ? void 0 : ref.clientHeight, ref === null || ref === void 0 ? void 0 : ref.clientWidth]);
  return [setRef, size];
}

exports.useSVGSize = useSVGSize;