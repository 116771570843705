import React from 'react';
import styled from 'styled-components';
import '@atomfinance/atom-viz-web-components';
import AssetFeedComponent from './AssetFeedComponent';

const SnapshotWrapper = ({ asset }) => {
  return (
    // NOTE: High level demos (placement and styles)
    // <GridContainer>
    //   <atom-asset-price-history asset={asset} />
    //   <atom-asset-overview asset={asset} />
    //   <atom-news-feed asset={asset} />
    // </GridContainer>

    // NOTE: CIBC specific designs
    <FlexContainer>
      <Column>
        <atom-asset-price-history asset={asset} header={false} />
        <atom-asset-stats asset={asset} />
        <atom-asset-description asset={asset} />
      </Column>
      <Column>
        <atom-asset-whats-happening asset={asset} />
        <atom-asset-events-cards asset={asset} recent-events={true} />
        <atom-asset-events-cards asset={asset} />
        <AssetFeedComponent asset={asset} />
      </Column>
    </FlexContainer>
  );
};

// NOTE: CIBC specific designs
const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6rem;
  width: 100%;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

// NOTE: High level demos (placement and styles)
// const GridContainer = styled.div`
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
//   grid-template-rows: repeat(2, auto);
//   grid-gap: 2em 6em;

//   atom-asset-overview {
//     grid-area: 2 / 1 / 3 / 2;
//   }
//   atom-news-feed {
//     grid-area: 1 / 2 / 3 / 3;
//   }
// `;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 50%;
  gap: 2rem;

  @media (max-width: 900px) {
    width: 100%;
    max-width: 100%;
  }
`;

const ComponentWrapper = styled.div`
  /* position: absolute; */
  width: 100%;
`;

export default SnapshotWrapper;
