import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import './TextBox.css';

export const SimpleTextBox = ({
  current,
  update,
  height,
  placeholder,
  noLeftPadding,
  type,
}: any) => {
  const [input, setInput] = useState(current || '');

  useEffect(() => {
    if (input && input.length && input !== current) {
      const handler = setTimeout(() => {
        update(input);
      }, 400);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [input, current, update]);

  return (
    <InputContainer
      style={{ columnSpan: 'all' }}
      height={height}
      noLeftPadding={noLeftPadding}
    >
      <input
        style={{
          width: '100%',
          backgroundColor: 'transparent',
          border: 'none',
          color: 'var(--contrast)',
          fontFamily: 'D-Din Exp',
          fontSize: '.9rem',
          marginTop: '.05rem',
          marginBottom: '.05rem',
        }}
        onChange={evt => setInput(evt.target.value)}
        value={input}
        placeholder={placeholder}
        type={type}
      />
    </InputContainer>
  );
};

const InputContainer = styled.div<{ height?: number; noLeftPadding?: boolean }>`
  background-color: var(--atom-dark-brand-4);
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding-left: ${props => (props.noLeftPadding ? '0rem' : '0.75rem')};
  height: ${props => (props.height ? `${props.height}rem` : 'auto')};
`;
