import React, { useState } from 'react';
import styled from 'styled-components';
import '@atomfinance/atom-viz-web-components';

const AssetFeedComponent = ({ asset }) => {
  const [activeComp, setActiveComp] = useState('inside');
  return (
    <StyledContainer>
      <Header>Asset Feed</Header>
      <Navigation>
        <NavElement
          selected={activeComp === 'briefs'}
          onClick={() => setActiveComp('briefs')}
        >
          Stock Briefs
        </NavElement>
        <NavElement
          selected={activeComp === 'inside'}
          onClick={() => setActiveComp('inside')}
        >
          Inside View
        </NavElement>
        <NavElement
          selected={activeComp === 'news'}
          onClick={() => setActiveComp('news')}
        >
          News
        </NavElement>
      </Navigation>
      <ComponentWrapper>{renderActiveComp(activeComp, asset)}</ComponentWrapper>
    </StyledContainer>
  );
};

const renderActiveComp = (param, asset) => {
  switch (param) {
    case 'briefs':
      return <atom-news-stock-briefs asset={asset} header={false} />;
    case 'inside':
      return <atom-asset-inside-view asset={asset} header={false} />;
    case 'news':
      return <atom-news-feed asset={asset} header={false} />;
  }
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0.75rem 6rem;
  border-top: 1px solid var(--atom-viz-border);
  border-bottom: 1px solid var(--atom-viz-border);
  font-weight: bold;
  font-size: 0.75rem;
`;

const NavElement = styled.div<{ selected: boolean }>`
  background-color: ${props =>
    props.selected
      ? 'var(--atom-viz-background-secondary);'
      : 'var(--atom-viz-background-primary);'};
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
`;

const ComponentWrapper = styled.div`
  height: 50rem;
`;

const Header = styled.div`
  font-size: 1.5rem;
`;

export default AssetFeedComponent;
