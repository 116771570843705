import { useMutation, useQuery } from '@apollo/react-hooks';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import AtomIcon from '../../../common/AtomIcon';
import {
  AUTHENTICATE_AS_ADMIN,
  GET_ADMIN_ID_AND_TOKEN,
} from '../../../common/queries';
import { Spinner } from '../../../common/ui';
import { ResponseText } from './GettingStartedContent';
import { SimpleTextBox } from './TextBox';
import { TextButton } from './TextButton';

export const PartnerLoginContent = ({
  setModalIsOpen,
  loginEmail,
  setLoginEmail,
  password,
  setPassword,
  setModalContent,
}) => {
  const [authenticateAsPartner, authenticateAsPartnerResponse] = useMutation(
    AUTHENTICATE_AS_ADMIN,
  );

  const authUser = useCallback(
    async (email: string, password: string) => {
      authenticateAsPartner({
        variables: { email, password },
      });
    },
    [authenticateAsPartner],
  );

  const { loading, data } = authenticateAsPartnerResponse;
  const success = data?.authenticateAsAdmin?.success;
  const message = data?.authenticateAsAdmin?.message;
  const disableSubmit = !loginEmail?.length || !password?.length || loading;

  const { data: adminIdAndToken } = useQuery(GET_ADMIN_ID_AND_TOKEN, {
    variables: { email: loginEmail },
  });

  if (success) {
    localStorage.setItem('atom_userLoggedIn', 'true');
    localStorage.setItem(
      'atom_userAdminId',
      adminIdAndToken.adminIdAndToken.id,
    ); // this is to save the user's id in case we need it later on
    localStorage.setItem(
      'atom_userTokenId',
      adminIdAndToken.adminIdAndToken.token,
    );
    window.dispatchEvent(new Event('storage')); // this is a workaround since we cannot trigger localStorage events from the same tab: https://stackoverflow.com/questions/35865481/storage-event-not-firing
  }

  return (
    <ModalContentContainer>
      <AtomIconsContainer>
        <AtomIcon
          icon='arrow-left-large'
          size={24}
          onClick={() => {
            setModalContent('getStarted');
          }}
          style={{
            paddingTop: '1rem',
            paddingLeft: '1rem',
          }}
        />
        <AtomIcon
          icon='close'
          size={24}
          iconCss={'fill: var(--green)'}
          onClick={() => {
            setModalIsOpen(false);
          }}
          style={{
            paddingTop: '1rem',
            paddingRight: '1rem',
          }}
        />
      </AtomIconsContainer>
      <SignInContentContainer>
        <HeaderTitle>{`Atom Partner Login`}</HeaderTitle>
        <TextInputsContainer>
          <ContainerWithWidth width={85}>
            <SimpleTextBox
              current={loginEmail}
              update={setLoginEmail}
              height={3.5}
              placeholder='Enter email address'
            ></SimpleTextBox>
          </ContainerWithWidth>
          <ContainerWithWidth width={85}>
            <SimpleTextBox
              current={password}
              update={setPassword}
              height={3.5}
              placeholder='Password'
              type='password'
            ></SimpleTextBox>
          </ContainerWithWidth>
          <ResponseText success={success}>{message}</ResponseText>
          <TextButton
            style={{ borderRadius: '0px', width: '75%' }}
            backgroundColor='var(--green)'
            hoverColor='var(--green-hover)'
            onClick={() => {
              if (!disableSubmit) authUser(loginEmail, password);
            }}
          >
            {loading ? <Spinner smaller={true} /> : `SIGN IN`}
          </TextButton>
        </TextInputsContainer>
      </SignInContentContainer>
    </ModalContentContainer>
  );
};

const SignInContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  justify-content: center;
  height: 70%;
`;

const AtomIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const TextInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  min-width: 27rem;
  row-gap: 1rem;
`;

const ContainerWithWidth = styled.div<{ width: number }>`
  width: ${props => props.width}%;
`;

const HeaderTitle = styled.div`
  font-size: 48px;
`;

const ModalContentContainer = styled.div`
  background-color: #32323e;
  width: 60rem;
  height: 36rem;
  box-shadow: 0px 0px 30px -5px #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
`;
