import React from 'react';
import styled from 'styled-components';
import { format } from 'd3';

const TrendingTopicPill = ({ topic, count, importance }) => {
  return (
    <StyledPill>
      <Topic>{topic}</Topic>
      <Count>({count})</Count>
      <Importance>IMP: {format('.2f')(importance)}</Importance>
    </StyledPill>
  );
};

export default TrendingTopicPill;

const StyledPill = styled.div`
  display: flex;
  flex-wrap: nowrap;
  background-color: var(--background-secondary);
  color: var(--text-primary);
  font-size: 14px;
  border-radius: 5px;
  padding: 5px 10px;
  gap: 5px;
`;

const Topic = styled.span`
  white-space: nowrap;
`;

const Count = styled.span`
  color: var(--text-secondary);
`;

const Importance = styled.span`
  color: var(--orange);
  font-size: 8px;
  font-weight: 800;
  line-height: 1em;
  margin: auto 0px;
  width: min-content;
`;
