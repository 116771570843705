import React from 'react';
import styled from 'styled-components';
import QuotePriceSection from './QuotePriceSection';
import { useTickerData, marketText } from './utils';
import { useQuery } from '@apollo/react-hooks';
import { GET_DATA_FOR_SYMBOL } from '../queries';
import { Spinner } from '../ui';

const LoadingDimmer = React.memo((props: any) => (
  <StyledLoadingDimmer isLoading={!!props.loading}>
    {props.children}
  </StyledLoadingDimmer>
));

const QuoteHeader = ({ symbol, width }: { symbol: string; width?: number }) => {
  const { tickerData } = useTickerData(symbol);
  const tickerName = tickerData?.name;

  const { data, loading } = useQuery(GET_DATA_FOR_SYMBOL, {
    variables: { symbol },
  });

  const exchangeCode = data?.dataForSymbol?.exchangeCode;
  const logoLink = data?.dataForSymbol?.link;

  return (
    <QuoteHeaderContainer>
      <LoadingDimmer loading={!symbol || !tickerData}>
        <FlexRow>
          <FlexColumn>
            <FlexRow style={{ justifyContent: 'normal', marginTop: '7px' }}>
              {logoLink ? (
                <LogoImg src={logoLink} />
              ) : (
                loading && (
                  <SpinnerContainer>
                    <Spinner smaller />
                  </SpinnerContainer>
                )
              )}
              <Title>{symbol}</Title>
            </FlexRow>
            <SymbolText>{tickerName}</SymbolText>
            <EntityTypeText>{marketText(symbol)}</EntityTypeText>
          </FlexColumn>
          {symbol && tickerData && exchangeCode && (
            <QuotePriceSection
              tickerData={tickerData}
              exchangeCode={exchangeCode}
            />
          )}
        </FlexRow>
      </LoadingDimmer>
    </QuoteHeaderContainer>
  );
};

export default React.memo(QuoteHeader);

const LogoImg = styled.img`
  height: 30px;
  width: 30px;
  margin-right: 9px;
  border-radius: 50%;
`;

const SpinnerContainer = styled.div`
  height: 30px;
  width: 30px;
  margin-right: 9px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const QuoteHeaderContainer = styled.div`
  box-sizing: inherit;
  width: 100%;
  padding: 24px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: var(--text-primary);

  @media (max-width: 414px) {
    padding: 15px;
  }
`;

const StyledLoadingDimmer = styled.div<{ isLoading?: boolean }>`
  opacity: ${props => (props.isLoading ? 0.3 : 1)};
  transition: opacity 0.2s ease-in-out;
  width: 100%;
`;

const Title = styled.div`
  font-size: 28px;
  line-height: 1em;
  margin-bottom: 0.5em;
`;

const SymbolText = styled.div`
  font-size: 1em;
  margin-bottom: 6px;
  color: var(--contrast);

  @media (max-width: 414px) {
    font-size: 0.75em;
  }
`;

const EntityTypeText = styled.div`
  font-size: 14px;
  color: var(--atom-brand-7);

  @media (max-width: 414px) {
    font-size: 0.75em;
  }
`;
