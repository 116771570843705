import React from 'react';
import styled from 'styled-components';
import {
  PriceTimeDomains,
  widgetChartSpanMapping,
} from '@atom-finance/atom-viz';
import { timeFormat } from 'd3';

const estTimeFormat = (time, zone = true) => {
  const timeString = new Date(time).toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'America/New_York',
  });
  return zone ? `${timeString} ET` : timeString;
};
const estTimeDateFormat = date => {
  const dateString = new Date(date).toLocaleString('en-US', {
    hour: 'numeric',
    day: 'numeric',
    month: 'numeric',
    timeZone: 'America/New_York',
  });
  return `${dateString} ET`;
};

export const dateFormatMapping = {
  [PriceTimeDomains.oneDay]: {
    axis: d => estTimeFormat(d, false),
    tooltip: estTimeFormat,
  },
  [PriceTimeDomains.fiveDay]: {
    axis: timeFormat('%m/%d'),
    tooltip: estTimeDateFormat,
  },
  [PriceTimeDomains.twoWeek]: {
    axis: timeFormat('%m/%d'),
    tooltip: estTimeDateFormat,
  },
  [PriceTimeDomains.oneMonth]: {
    axis: timeFormat('%m/%d'),
    tooltip: timeFormat('%m/%d'),
  },
  [PriceTimeDomains.threeMonth]: {
    axis: timeFormat('%m/%d'),
    tooltip: timeFormat('%m/%d'),
  },
  [PriceTimeDomains.sixMonth]: {
    axis: timeFormat('%b'),
    tooltip: timeFormat('%B %d'),
  },
  [PriceTimeDomains.yearToDate]: {
    axis: timeFormat('%b %y'),
    tooltip: timeFormat('%m/%d'),
  },
  [PriceTimeDomains.oneYear]: {
    axis: timeFormat('%b %y'),
    tooltip: timeFormat('%m/%d'),
  },
  [PriceTimeDomains.twoYear]: {
    axis: timeFormat('%b %y'),
    tooltip: timeFormat('%m/%d'),
  },
  [PriceTimeDomains.fiveYear]: {
    axis: timeFormat('%b %y'),
    tooltip: timeFormat('%m/%d'),
  },
};

const TimeSelectionButton = ({ display, callback, selected }) => {
  return (
    <StyledContainer onClick={callback}>
      <StyledSpan selected={selected}>
        <span>{display}</span>
      </StyledSpan>
    </StyledContainer>
  );
};

const TimeSelection = ({ timespan, callback }) => {
  return (
    <StyledTimespanContainer>
      {Object.keys(widgetChartSpanMapping).map(d => (
        <TimeSelectionButton
          display={d}
          callback={() => callback(d)}
          selected={d === timespan}
          key={d}
        />
      ))}
    </StyledTimespanContainer>
  );
};

export default React.memo(TimeSelection);

const StyledTimespanContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledContainer = styled.div`
  display: inline-block;
  padding: 8px;
  @media (max-width: 414px) {
    padding: 2px;
  }
`;

const StyledSpan = styled.span<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 18px;
  height: 18px;
  font-size: 10px;
  padding: 4px;
  color: var(--text-primary);
  background-color: ${({ selected }) =>
    selected ? 'var(--background-secondary)' : 'none'};
  font-weight: ${({ selected }) => (selected ? '800' : '400')};
  border-radius: 50%;
`;
