import React from 'react';
import styled from 'styled-components';

export const ToggleButton = ({ isToggled, onClickHandler }) => {
  return (
    <ToggleButtonContainer
      onClick={() => {
        onClickHandler(!isToggled);
      }}
      disabled={!isToggled}
    >
      <SmallCircle disabled={!isToggled} />
    </ToggleButtonContainer>
  );
};

const ToggleButtonContainer = styled.div<{ disabled?: boolean }>`
  background-color: var(--background-secondary);
  border-radius: 1em;
  height: fit-content;
  width: 3em;
  align-items: center;
  cursor: pointer;
  display: flex;
`;

const SmallCircle = styled.div<{ disabled?: boolean }>`
  border-radius: 100%;
  height: 1em;
  width: 1em;
  margin: 3px;
  background-color: var(--${props => (props.disabled ? 'highlight' : 'green')});
  border: solid var(--${props => (props.disabled ? 'highlight' : 'green')});
  position: relative;
  left: ${props => (props.disabled ? '1.45rem' : '0rem')};
  transition: left 0.2s ease-out, background-color 0.2s ease-out;
`;
