import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import '@atomfinance/atom-viz-web-components';
import { GEN_TOKEN } from '../../../common/queries';
import { useMutation } from '@apollo/react-hooks';
import ContentWrapper from './ContentWrapper';
import { Navigation, SectionNav, SubNavigation } from './Navigation';
import { SearchSection } from './SearchBar';

const ComponentsDemo3 = () => {
  const [selection, setSelection] = useState('Quotes and Research');
  const [subSelection, setSubSelection] = useState('Stock Centre');
  const [sectionSelection, setSectionSelection] = useState('Snapshot');
  const [searchTicker, setSearchTicker] = useState('AAPL');
  const [genToken] = useMutation(GEN_TOKEN);

  const getToken = useCallback(async () => {
    const { data } = await genToken();
    return data.genVizComponentsDemoToken;
  }, [genToken]);

  // FOR DEV so we're not making a new token every time we want to make a css change
  // const getToken = useCallback(() => {
  //   return 'cguSly5BLdhm9OzKxkwMGw';
  // }, []);

  useEffect(() => {
    const atomGetDataFns = {
      getTokenCallback: getToken,
    };
    // Append the object
    window['atomGetDataFns'] = atomGetDataFns;
  }, [getToken]);
  return (
    <StyledContainer>
      <Navigation selection={selection} setSelection={setSelection} />
      <SearchSection
        searchTicker={searchTicker}
        setSearchTicker={setSearchTicker}
      />
      <atom-auth />
      <atom-asset-header asset={searchTicker} />
      <ContentWrapper symbol={searchTicker} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  font-family: var(--font);
  min-height: 100%;

  .atom-asset-header-parent {
    padding: 20px 50px;
    width: auto;
    background-image: linear-gradient(
      90deg,
      #453b59,
      #771fff 25%,
      #f66f87 50%,
      #771fff 75%,
      #272036
    );
  }

  .atom-asset-overview-description-content {
    line-height: unset;
  }

  .atom-insights-select {
    select {
      background-color: var(--background);
      width: 200px;
      padding: 5px 5px;
      border-radius: 5px;
    }
  }

  --atom-viz-font: 'Inter';
  --atom-viz-green: #2f9577;
  --atom-viz-red: rgb(185, 78, 112);
  --atom-viz-price-up: var(--green);
  --atom-viz-price-down: var(--atom-viz-red);
  --atom-viz-vol-up: var(--green);
  --atom-viz-vol-down: var(--price-down);
  --atom-viz-text-primary: #eeeeee;
  --atom-viz-text-secondary: #888888;
  --atom-viz-highlight: #36323a;
  --atom-viz-good: var(--green);
  --atom-viz-average: #a87427;
  --atom-viz-poor: var(--price-down);
  --atom-viz-background-primary: #282832;
  --atom-viz-background-secondary: var(--highlight); ;
`;

export default React.memo(ComponentsDemo3);
