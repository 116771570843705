import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import '@atomfinance/atom-viz-web-components';
import { GEN_TOKEN } from '../../../common/queries';
import { useMutation } from '@apollo/react-hooks';
import { Navigation, SectionNav, SubNavigation } from './Navigation';
import { SearchSection, SearchTextEntry } from './SearchBar';
import SnapshotWrapper from './SnapshotWrapper';
import EarningsWrapper from './EarningsWrapper';
import FinancialsWrapper from './FinancialsWrapper';
import NewsWrapper from './NewsWrapper';
import FundamentalsWrapper from './FundamentalsWrapper';

const Contents = ({ sectionSelection, asset }) => {
  switch (sectionSelection) {
    case 'Snapshot':
      return <SnapshotWrapper asset={asset} />;
    case 'News':
      return <NewsWrapper asset={asset} />;
    case 'Fundamentals':
      return <FundamentalsWrapper asset={asset} />;
    case 'Earnings':
      return <EarningsWrapper asset={asset} />;
    case 'Financials':
      return <FinancialsWrapper asset={asset} />;
    default:
      return <SnapshotWrapper asset={asset} />;
  }
};

const ComponentsDemo2 = () => {
  const [selection, setSelection] = useState('Quotes and Research');
  const [subSelection, setSubSelection] = useState('Stock Center');
  const [sectionSelection, setSectionSelection] = useState('Snapshot');
  const [searchTicker, setSearchTicker] = useState('AAPL');
  const [genToken] = useMutation(GEN_TOKEN);

  const getToken: () => Promise<{
    token: string;
    ttl: number;
  }> = useCallback(async () => {
    const { data } = await genToken();
    return data.genVizComponentsDemoToken;
  }, [genToken]);

  // FOR DEV so we're not making a new token every time we want to make a css change
  // const getToken = useCallback(() => {
  //   return "psOe27f_fhJx8oRnPXca9g";
  // }, []);

  useEffect(() => {
    const atomGetDataFns = {
      atomAuthCallback: getToken,
      atomWhatsHappeningEarningsLearnMore: () =>
        setSectionSelection('Earnings'),
    };
    // Append the object
    window['atomCustomFunctions'] = atomGetDataFns;
  }, [getToken]);

  return (
    <StyledContainer>
      <TopHeader>
        <Investing>INVESTING</Investing>
        <Banking>BANKING</Banking>
      </TopHeader>
      <Navigation selection={selection} setSelection={setSelection} />
      <SubNavigation selection={subSelection} setSelection={setSubSelection} />
      <SearchSection
        searchTicker={searchTicker}
        setSearchTicker={setSearchTicker}
      />
      <atom-auth />
      <HeaderContainer>
        <atom-asset-header asset={searchTicker} />
      </HeaderContainer>
      <SectionNav
        selection={sectionSelection}
        setSelection={setSectionSelection}
      />
      <WrapperContainer>
        <Contents sectionSelection={sectionSelection} asset={searchTicker} />
      </WrapperContainer>
    </StyledContainer>
  );
};

const TopHeader = styled.div`
  height: 50px;
  width: calc(100% - 4rem);
  background-color: var(--atom-viz-color-brand-3);
  padding: 0rem 2rem;
`;

const Investing = styled.div`
  display: inline-block;
  padding: 1rem;
  background-color: white;
`;

const Banking = styled.div`
  display: inline-block;
  padding: 1rem;
  color: white;
`;

const SecondHeader = styled.div`
  width: calc(100% - 200px);
  padding: 1rem 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--atom-viz-background-secondary);

  input {
    height: 20px;
    border-radius: 20px;
  }

  span {
    color: var(--atom-viz-color-brand);
    font-size: 2rem;
    font-weight: 900;
  }
`;

const Logo = styled.div`
  background-image: url(./ComponentsDemo2/logo.png);
`;

const HeaderContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 20px 100px;
  background-color: var(--atom-viz-background-secondary);
  border-radius: 8px;
`;

const WrapperContainer = styled.div`
  width: calc(100% - 200px);
  padding: 10px 100px 200px 100px;
  margin: 20px 0px;
`;

const StyledContainer = styled.div`
  font-family: 'Whitney Book';
  background-color: var(--atom-viz-background-primary);
  min-height: 150%;
  margin-bottom: 5em;

  .atom-asset-overview-description-content {
    line-height: 1.2em;
  }

  atom-asset-overview [class$='header'] {
    font-size: 1.5em;
  }
  atom-header-text h3 {
    font-size: 1.5em;
  }
`;

export default React.memo(ComponentsDemo2);
