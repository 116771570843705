import React from 'react';
import styled from 'styled-components';
import '@atomfinance/atom-viz-web-components';

const EarningsWrapper = ({ asset }) => {
  return (
    <StyledContainer>
      {/* <Row> */}
      <atom-asset-earnings-update asset={asset} />
      {/* <atom-asset-events-cards asset={asset} header={false}/> */}
      {/* </Row> */}
      <Row>
        <OneDayContainer>
          <atom-asset-earnings-1day asset={asset} />
        </OneDayContainer>
      </Row>
      <Row>
        <Col>
          <atom-asset-events-table asset={asset} />
        </Col>
        <Col>
          <atom-asset-earnings-barchart asset={asset} />
        </Col>
      </Row>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4em;
`;

const Col = styled.div`
  width: 100%;
  max-width: 50%;
  display: flex;
  flex-direction: column;

  atom-asset-events-table {
    min-width: unset;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 4em;
  min-height: 300px;
  min-width: 100%;

  /* atom-asset-earnings-1day {
    flex-grow: 1;
  } */
`;

const OneDayContainer = styled.div`
  flex-grow: 1;
`;

export default EarningsWrapper;
