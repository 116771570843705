import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import '@atomfinance/atom-viz-web-components';
import { GEN_TOKEN } from '../../../common/queries';
import { useMutation } from '@apollo/react-hooks';
import ContentWrapper from './ContentWrapper';
import { Navigation } from './Navigation';

const ComponentsDemo = () => {
  const [selection, setSelection] = useState('Quotes and Research');
  const [genToken] = useMutation(GEN_TOKEN);

  const getToken = useCallback(async () => {
    const { data } = await genToken();
    return data.genVizComponentsDemoToken;
  }, [genToken]);

  // FOR DEV so we're not making a new token every time we want to make a css change
  // const getToken = useCallback(() => {
  //   return 'cguSly5BLdhm9OzKxkwMGw';
  // }, []);

  useEffect(() => {
    const atomGetDataFns = {
      getTokenCallback: getToken,
    };
    // Append the object
    window['atomGetDataFns'] = atomGetDataFns;
  }, [getToken]);

  return (
    <StyledContainer>
      <atom-auth />
      <Navigation selection={selection} setSelection={setSelection} />
      <ContentWrapper />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  background-color: #ffffff;
  font-family: var(--font);
  min-height: 100%;
  display: flex;
  flex-direction: row;

  --atom-viz-font: 'Poppins';
  --atom-viz-green: #43d7aa;
  --atom-viz-red: #c41f3e;
  --atom-viz-price-up: var(--green);
  --atom-viz-price-down: var(--atom-viz-red);
  --atom-viz-vol-up: var(--green);
  --atom-viz-vol-down: var(--atom-viz-red);
  --atom-viz-text-primary: #eeeeee;
  --atom-viz-text-secondary: #888888;
  --atom-viz-highlight: #ccc;
  --atom-viz-good: var(--green);
  --atom-viz-average: #eac285;
  --atom-viz-poor: var(--atom-viz-red);
  --atom-viz-background-primary: #1f1d26;
  --atom-viz-background-secondary: var(--highlight);
`;

export default React.memo(ComponentsDemo);
