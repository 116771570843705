import React from 'react';
import styled from 'styled-components';

export const SearchSection = ({ searchTicker, setSearchTicker }) => {
  let searchInput;
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      console.log(searchInput);
      setSearchTicker(searchInput);
    }
  };

  const handleChange = event => {
    searchInput = event.target.value;
  };

  return (
    <SearchBar>
      <SectionTitle>Quotes and Research</SectionTitle>
      <SearchContainer>
        <SearchTitle>Search</SearchTitle>
        <SearchTextEntry
          type='text'
          onKeyDown={handleKeyPress}
          onChange={handleChange}
          placeholder='Search by ticker'
        />
      </SearchContainer>
    </SearchBar>
  );
};

const SearchTextEntry = styled.input`
  color: var(--atom-viz-text-primary);
  padding: 8px 20px;
  background-color: #1f1d26;
  border-radius: 10px;
  border: none;
  font-family: var(--font);
  min-width: 250px;

  &::placeholder {
    font-family: var(--font);
  }
`;

const SectionTitle = styled.div`
  font-size: 2em;
`;

const SearchBar = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 90%;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
`;

const SearchTitle = styled.div`
  color: var(--atom-viz-text-primary);
  font-size: 1.5em;
  margin-right: 1em;
`;
