import React from 'react';
import styled from 'styled-components';

const TopNavItems = [
  'Account Information',
  'Trading',
  'Order Status',
  'Cash Transfer',
  'Quotes and Research',
  'More',
];

const BottomNavItems = ['Portfolio', 'Settings', 'History'];

const NavSectionTop = ({ selection, setSelection }) => {
  return (
    <>
      <StyledNavigation>
        {TopNavItems.map((item, i) => {
          return (
            <StyledNavLink
              key={i}
              className={selection === item ? 'selected' : ''}
              onClick={() => setSelection(item)}
            >
              {item}
            </StyledNavLink>
          );
        })}
      </StyledNavigation>
    </>
  );
};

const NavSectionBottom = ({ selection, setSelection }) => {
  return (
    <>
      <StyledNavigation>
        {BottomNavItems.map((item, i) => {
          return (
            <StyledNavLink
              key={i}
              className={selection === item ? 'selected' : ''}
              onClick={() => setSelection(item)}
            >
              {item}
            </StyledNavLink>
          );
        })}
      </StyledNavigation>
    </>
  );
};

const StyledNavLink = styled.div`
  padding: 10px 20px;
  &:hover {
  }
  &.selected {
    background-color: #2f2b3e;
    border-radius: 10px;
    font-weight: bold;
  }
`;

const StyledNavigation = styled.div`
  padding: 20px 0;
`;

export const Navigation = ({ selection, setSelection }) => {
  return (
    <MainNavContainer>
      <NavTitle>Stock Center</NavTitle>
      <NavHeader>MENU</NavHeader>
      <NavSectionTop selection={selection} setSelection={setSelection} />
      <NavHeader>ACCOUNT</NavHeader>
      <NavSectionBottom selection={selection} setSelection={setSelection} />
    </MainNavContainer>
  );
};

const MainNavContainer = styled.div`
  width: 20%;
  min-width: 170px;
  background-color: #201e27;
  color: var(--atom-viz-text-primary);
  padding: 30px;
  position: fixed;
  height: 100%;

  @media (max-width: 1000px) {
    visibility: hidden;
  }
`;

const NavHeader = styled.div`
  padding-top: 20px;
  color: #bbbbbb;
  font-size: 0.9em;
  letter-spacing: 0.2em;
`;

const NavTitle = styled.div`
  font-weight: bold;
  font-size: 2em;
  background-image: linear-gradient(60deg, #a47bfb, #a1e9d7);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`;
