import { AtomTooltip } from '../ui';
import React from 'react';

export type FinanceTerm =
  | 'Market Cap'
  | 'Net Debt & Pref.'
  | 'Enterprise Value (EV)'
  | 'Beta'
  | 'Borrow Cost'
  | 'Next Earnings'
  | 'Dividend Yield'
  | 'Avg. Volume';

interface TooltipData {
  title: string;
  body: string | JSX.Element;
  definitionURL?: string;
  definitionName?: string;
}

const termToInfo: Record<FinanceTerm, TooltipData> = {
  'Market Cap': {
    title: 'Market Capitalization',
    body: `Market Cap (Capitalization) is the total value of all outstanding shares of a company.`,
    definitionURL:
      'http://www.investopedia.com/investing/market-capitalization-defined/',
    definitionName: 'Market Cap',
  },
  'Net Debt & Pref.': {
    title: 'Net Debt',
    body: `Calculated as a company's total debt less its liquid assets. The generic formula is Net Debt = Short Term Debt (<12 months) + Long Term Debt (>12 months) - Cash & Cash Equivalents.`,
    definitionURL: 'http://www.investopedia.com/terms/n/netdebt.asp',
  },
  'Enterprise Value (EV)': {
    title: 'Enterprise Value (EV)',
    body: `A measure of the value of a company's total core assets, which takes into account the sum of all outstanding claims on the company's assets, less the company's excess (non-core) assets. The generic formula is EV = Market Cap + Total Debt + Preferred Stock + Minority Interest - Cash - Other Non-core Assets.`,
    definitionURL: 'http://www.investopedia.com/terms/e/enterprisevalue.asp',
    definitionName: 'Enterprise Value',
  },
  'Beta': {
    title: 'Beta',
    body: `Beta represents an expected % move relative to a 1% move in the overall market.`,
    definitionURL: 'http://www.investopedia.com/terms/b/beta.asp',
  },
  'Borrow Cost': {
    title: 'Borrow Cost',
    body: `Also known as a Stock Loan Fee, represents the annual amount a brokerage charges a short seller in order to borrow and sell shares short.`,
    definitionURL: 'http://www.investopedia.com/terms/s/stock-loan-fee.asp',
    definitionName: 'Stock Loan Fee',
  },
  'Next Earnings': {
    title: 'Next Earnings',
    body: `The next upcoming earnings release date for this company.`,
  },
  'Dividend Yield': {
    title: 'Dividend Yield',
    body: `The ratio of the company's annual dividend compared to its share price.`,
    definitionURL: 'http://www.investopedia.com/terms/d/dividendyield.asp',
  },
  'Avg. Volume': {
    title: 'Average Volume',
    body: `The average number of shares traded each trading day over the last 30 days.`,
  },
};

interface Props {
  term: string;
}

// eslint-disable-next-line react/display-name
const FinanceTermTooltip = React.memo(({ term }: Props) => {
  if (!termToInfo[term]) {
    return null;
  }
  const { title, body, definitionURL, definitionName } =
    termToInfo[term as FinanceTerm];
  return (
    <AtomTooltip
      title={title}
      subtitle={
        <>
          {body}
          {definitionURL && (
            <>
              <br />
              <br />
              For more information, see the{' '}
              <a
                href={definitionURL}
                target='_blank'
                rel='noopener noreferrer'
                style={{ color: 'var(--green-hover)' }}
              >
                {`${definitionName || title} Definition`}
              </a>
            </>
          )}
        </>
      }
      altText={title}
      tooltipDirection={'right'}
      iconHeight={'15px'}
      marginLeft={'6px'}
    />
  );
});

export default FinanceTermTooltip;
