import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { GET_NEWS_SENTIMENT, GET_TRENDING_TOPICS } from './queries';
import { useQuery } from '@apollo/react-hooks';
import { AtomSentimentLineChart } from '@atom-finance/atom-viz';
import { Spinner } from './ui';
import {
  ComponentSubtitle,
  ComponentTitle,
  ComponentTitleContainer,
  NoDataCenteredText,
} from './commonStyledElements';
import TrendingTopicPill from './TrendingTopicPill';
import { AtomTooltip } from '../routes/demo/ui/Tooltip';

type SentimentDataResp = {
  date: string;
  avgSentimentScore30Days: string;
};

const SentimentChart = ({ symbol }) => {
  const [sentimentData, setSentimentData] = useState<[] | SentimentDataResp[]>(
    [],
  );
  const [topicsData, setTopicsData] = useState<[] | any[]>([]);
  const [resizeTrigger, setResizeTrigger] = useState(0);
  const containerRef = useRef(null);

  /* Query Data and Transform */
  const { loading: loadingSentiment, data: rawSentimentData } = useQuery(
    GET_NEWS_SENTIMENT,
    {
      variables: { symbol: { symbol } },
    },
  );
  useEffect(() => {
    if (loadingSentiment) setSentimentData([]);
    if (!loadingSentiment && rawSentimentData) {
      if (rawSentimentData?.vizNewsSentiment.length) {
        setSentimentData(
          rawSentimentData.vizNewsSentiment.map(d => ({
            'date': +d.date,
            'avgSentimentScore30Days': +d.avgSentimentScore30Days,
          })),
        );
      }
    }
  }, [loadingSentiment, rawSentimentData]);

  const { loading: loadingTopics, data: trendingTopics } = useQuery(
    GET_TRENDING_TOPICS,
    {
      variables: { symbol: { symbol } },
    },
  );
  useEffect(() => {
    if (loadingTopics) setTopicsData([]);
    if (!loadingTopics && trendingTopics?.vizNewsTrendingTopics.length) {
      setTopicsData(trendingTopics.vizNewsTrendingTopics);
    }
  }, [loadingTopics, trendingTopics]);

  const resizeObserver = new ResizeObserver(function (entries) {
    setResizeTrigger(entries[0].contentRect.width);
  });
  if (containerRef.current) resizeObserver.observe(containerRef.current);

  if (sentimentData === undefined) return null;

  return (
    <StyledContainer>
      <ComponentTitleContainer>
        <ComponentTitle>News Sentiment</ComponentTitle>
        <ComponentSubtitle>
          30 day moving average sentiment scores from the news.
        </ComponentSubtitle>
      </ComponentTitleContainer>
      <StyledSentimentLineContainer ref={containerRef}>
        {loadingSentiment ? (
          <Spinner />
        ) : sentimentData.length ? (
          <AtomSentimentLineChart
            resize={resizeTrigger}
            data={sentimentData}
            margin={{ top: 10, left: 10, right: 40, bottom: 20 }}
          ></AtomSentimentLineChart>
        ) : (
          <NoDataCenteredText>No sentiment data available</NoDataCenteredText>
        )}
      </StyledSentimentLineContainer>
      <StyledTrendingTopicContainer>
        <ComponentSubtitle>
          <StyledTrendingSubtitleContainer>
            Trending topics:
            <AtomTooltip
              subtitle={`
                Trending topics are extracted from news multiple times per day, 
                and are aggregated from all of Atom's news sources. Each trending topic
                includes the phrase, the number of mentions in the last 7 days in parentheses, 
                and the importance score used for trending calculations.
              `}
              altText={'trending topic details'}
              tooltipDirection={'right'}
              iconHeight={'15px'}
              marginLeft={'2px'}
            ></AtomTooltip>
          </StyledTrendingSubtitleContainer>
        </ComponentSubtitle>
        {topicsData.length &&
          topicsData.map(topic => (
            <TrendingTopicPill
              key={topic.phrase}
              topic={topic.phrase}
              count={topic.newsHeadlineCount}
              importance={topic.importanceScore}
            />
          ))}
      </StyledTrendingTopicContainer>
    </StyledContainer>
  );
};

export default React.memo(SentimentChart);

const StyledContainer = styled.div`
  box-sizing: inherit;
  padding: 20px;
  overflow: hidden;

  svg {
    overflow: visible;
  }

  .tick {
    line {
      stroke-opacity: 0;
    }
    text {
      fill: var(--text-primary);
      font-size: 0.75em;
    }
  }
`;

const StyledSentimentLineContainer = styled.div`
  width: 100%;
  min-width: 200px;
  height: 100px;
  padding: 0;
  margin-bottom: 20px;
  display: flex;
`;

const StyledTrendingTopicContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: start;
  width: 100%;
  gap: 10px;
  margin: 10px 0px;
`;

const StyledTrendingSubtitleContainer = styled.span`
  display: flex;
  flex-wrap: nowrap;
`;
