import gql from 'graphql-tag';

export const GET_AUTH_STATUS = gql`
  query authStatus($token: String) {
    authStatus(token: $token) {
      partnerId
      partnerName
      partnerTier
    }
  }
`;

export const ETF_HOLDINGS_DIST = gql`
  query etfHoldingsDistribution($symbol: String!) {
    etfHoldingsDistribution(symbol: $symbol) {
      sectorDistribution {
        name
        weight
      }
      assetDistribution {
        name
        weight
      }
    }
  }
`;

// VIZ LIBRARY DEMO QUERIES
export const SIGN_UP = gql`
  mutation signUp(
    $firstName: String!
    $lastName: String!
    $companyName: String!
    $email: String!
    $password: String!
    $confirmPassword: String!
    $referrer: String!
    $companyWebsite: String
  ) {
    signUp(
      firstName: $firstName
      lastName: $lastName
      companyName: $companyName
      email: $email
      password: $password
      confirmPassword: $confirmPassword
      referrer: $referrer
      companyWebsite: $companyWebsite
    ) {
      success
      message
    }
  }
`;

export const CONFIRM_SIGN_UP = gql`
  mutation confirmSignUp($token: String!, $id: String!) {
    confirmSignUp(token: $token, id: $id) {
      success
      message
    }
  }
`;

export const GET_ADMIN_INFO = gql`
  query adminInformation($token: String!, $id: String!) {
    adminInformation(token: $token, id: $id) {
      id
      firstName
      lastName
      email
      isAtomAdmin
      partner {
        name
        id
        tier
        description
        apiKey
      }
    }
  }
`;

export const AUTHENTICATE_AS_ADMIN = gql`
  mutation authenticateAsAdmin($email: String!, $password: String!) {
    authenticateAsAdmin(email: $email, password: $password) {
      success
      message
    }
  }
`;

export const GET_ADMIN_ID_AND_TOKEN = gql`
  query adminIdAndToken($email: String!) {
    adminIdAndToken(email: $email) {
      id
      token
    }
  }
`;

export const GET_ADMIN_DID_CONFIRM_EMAIL = gql`
  query adminDidConfirmEmail($email: String!) {
    adminDidConfirmEmail(email: $email) {
      confirmed
      admin_id
      token_id
    }
  }
`;

export const GET_CHART_DATA = gql`
  query getChartData(
    $symbols: [FindOneSymbolInfo!]!
    $periodUnit: PeriodUnit!
    $periodLength: Int!
    $startDate: DateTime
    $endDate: DateTime
    $filterOutExtendedHours: Boolean
    $padExtendedHours: Boolean
  ) {
    chartData(
      symbols: $symbols
      periodUnit: $periodUnit
      periodLength: $periodLength
      startDate: $startDate
      endDate: $endDate
      filterOutExtendedHours: $filterOutExtendedHours
      padExtendedHours: $padExtendedHours
    ) {
      symbolId
      timeSeriesInfo {
        firstTimestamp
        lastTimestamp
      }
      quotes {
        DT
        Open
        Close
        High
        Low
        Volume
      }
    }
  }
`;

export const GET_PARTNER_TOKEN_FROM_VIZ_ID = gql`
  query partnerTokenFromVizId($vizId: String) {
    partnerTokenFromVizId(vizId: $vizId) {
      token
    }
  }
`;

export const CREATE_VIZ_WIDGET = gql`
  mutation createVizWidget(
    $vizEndpoint: String!
    $partnerId: String!
    $customizations: String
  ) {
    createVizWidget(
      vizEndpoint: $vizEndpoint
      partnerId: $partnerId
      customizations: $customizations
    ) {
      id
    }
  }
`;

export const GET_VIZ_WIDGET_SETTINGS = gql`
  query vizWidgetSettings($vizId: String) {
    vizWidgetSettings(vizId: $vizId) {
      id
      vizEndpoint
      customizations
    }
  }
`;

export const GET_PARTNER_ID_FROM_ADMIN_ID = gql`
  query partnerIdFromAdminId($adminId: String!) {
    partnerIdFromAdminId(adminId: $adminId) {
      id
    }
  }
`;

export const GET_DATA_FOR_SYMBOL = gql`
  query dataForSymbol($symbol: String!) {
    dataForSymbol(symbol: $symbol) {
      link
      exchangeCode
    }
  }
`;

export const GET_OVERVIEW_FOR_SYMBOL = gql`
  query overviewForSymbol($symbol: String!) {
    overviewForSymbol(symbol: $symbol) {
      description
      sector
      industry
      marketCap
      netDebt
      enterpriseValue
      beta
      borrowCost
      nextEarningsDate
      dividendYield
      averageVolume
      week52High
      week52Low
    }
  }
`;

export const compsSymbolFragment = gql`
  fragment CompsSymbolData on Symbol {
    id
    name
    symbol
    sector
    trAssetCategory
    trExchangeCode
    metric {
      id
      price
      sharePrice: price
      change
      marketCap
      changePercent
      extendedPrice
      extendedChange
      extendedChangePercent
    }
    ratiosData {
      currencySymbol
    }
  }
`;
export const TOP_MOVERS_COMPS = gql`
  query topMoversComps($retrieveLimit: Int, $trExchangeCode: String) {
    topMoversComps(
      retrieveLimit: $retrieveLimit
      trExchangeCode: $trExchangeCode
    ) {
      winners {
        ...CompsSymbolData
      }
      losers {
        ...CompsSymbolData
      }
    }
  }
  ${compsSymbolFragment}
`;

export const GET_SPARK_DATA = gql`
  query getSparkIntraday(
    $symbols: [FindOneSymbolInfo!]!
    $periodUnit: PeriodUnit!
    $periodLength: Int!
    $startDate: DateTime
    $endDate: DateTime
  ) {
    chartData(
      symbols: $symbols
      periodUnit: $periodUnit
      periodLength: $periodLength
      startDate: $startDate
      endDate: $endDate
    ) {
      symbolId
      timeSeriesInfo {
        firstTimestamp
        lastTimestamp
      }
      quotes {
        DT
        Close
      }
    }
  }
`;

export const GET_NEWS_SENTIMENT = gql`
  query getVizNewsSentiment($symbol: FindOneSymbolInfo!) {
    vizNewsSentiment(symbol: $symbol) {
      date
      avgSentimentScore30Days
    }
  }
`;

export const GET_TRENDING_TOPICS = gql`
  query getVizNewsTrendingTopics($symbol: FindOneSymbolInfo!) {
    vizNewsTrendingTopics(symbol: $symbol) {
      phrase
      entityType
      importanceScore
      sentimentScore
      newsHeadlineCount
    }
  }
`;

export const GEN_TOKEN = gql`
  mutation genVizComponentsDemoToken {
    genVizComponentsDemoToken {
      token
      ttl
    }
  }
`;
