import styled from 'styled-components';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { ButtonAndIconContainer, DescriptionText } from '../index';
import AtomIcon from '../../../common/AtomIcon';
import { TextButton } from './TextButton';
import './Carousel.css';
import { Link } from 'react-router-dom';

const FeaturesCarousel = ({ data }) => {
  return (
    <Carousel
      stopOnHover={false}
      infiniteLoop
      showStatus={false}
      emulateTouch={true}
      showThumbs={false}
      renderArrowNext={() => false}
      renderArrowPrev={() => false}
    >
      {data.map(datum => {
        return (
          <Link key={datum.title} to={datum.route}>
            <CarouselItem
              key={datum.title}
              title={datum.title}
              description={datum.description}
              image={datum.image}
              route={datum.route}
            />
          </Link>
        );
      })}
    </Carousel>
  );
};

export type CarouselItemType = {
  title: string;
  description: string;
  image: any;
  route: string;
};

const CarouselItem = ({ title, description, image }: CarouselItemType) => {
  return (
    <>
      <DescriptionText style={{ fontSize: '40px', width: 'auto' }}>
        {title}
      </DescriptionText>
      <AssetImageContainer>
        <CarouselImgContainer>
          <img
            style={{
              height: '25rem',
              width: 'auto',
              marginTop: '1rem',
            }}
            src={image}
          />
        </CarouselImgContainer>
      </AssetImageContainer>
      <DescriptionText style={{ width: 'auto', marginTop: '1rem' }}>
        {description}
      </DescriptionText>
      <ButtonAndIconContainer
        style={{
          paddingBottom: '3.5rem',
          paddingTop: '1rem',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <TextButton>TRY IT OUT</TextButton>
        <AtomIcon
          style={{ marginLeft: '-.55rem', marginTop: '1px' }}
          size={22}
          icon={'bold-right-arrow'}
        ></AtomIcon>
      </ButtonAndIconContainer>
    </>
  );
};

export default FeaturesCarousel;

const CarouselImgContainer = styled.div`
  background-color: #f2f2f3;
`;

const AssetImageContainer = styled.div`
  height: 420px;
  text-align: center;
`;
