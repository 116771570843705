import { useLazyQuery } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AtomIcon from '../../../common/AtomIcon';
import { GET_ADMIN_DID_CONFIRM_EMAIL } from '../../../common/queries';
import { ResponseText } from './GettingStartedContent';
import { TextButton } from './TextButton';

export const EmailConfirmationContent = ({
  setModalIsOpen,
  setModalContent,
  email,
}) => {
  const [visible, setVisible] = useState(false);
  const [showError, setShowError] = useState(false);
  const [getAdminDidConfirmEmail, { loading, data }] = useLazyQuery(
    GET_ADMIN_DID_CONFIRM_EMAIL,
    { variables: { email: email } },
  );

  const checkIfUserConfirmed = () => {
    getAdminDidConfirmEmail();
    return;
  };

  useEffect(() => {
    if (loading || !data) return;
    // add logic to see if user did indeed confirm
    const userDidConfirmEmail = data?.adminDidConfirmEmail?.confirmed;
    const adminId = data?.adminDidConfirmEmail?.admin_id;
    const tokenId = data?.adminDidConfirmEmail?.token_id;
    localStorage.setItem('atom_userAdminId', adminId);
    localStorage.setItem('atom_userLoggedIn', 'true');
    localStorage.setItem('atom_userTokenId', tokenId);
    if (userDidConfirmEmail) {
      setModalContent('widgetCode');
    } else {
      setShowError(true);
    }
  }, [data, loading, setModalContent]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <ModalContentContainer>
      <AtomIcon
        icon='close'
        size={24}
        iconCss={'fill: var(--green)'}
        onClick={() => {
          setModalIsOpen(false);
        }}
        style={{
          paddingTop: '1rem',
          paddingRight: '1rem',
          alignSelf: 'flex-end',
        }}
      />
      <UIContentContainer>
        <HeaderTitle>Confirm your email</HeaderTitle>
        <DescriptionLabel>
          {`You’ll receive an email sent to ${email} with a confirmation link. This may take up to a minute to reach your inbox. Once you do, click the link to confirm your email and access your generated code.`}
        </DescriptionLabel>
        {visible && (
          <TextButton
            backgroundColor='var(--green)'
            hoverColor='var(--green-hover)'
            onClick={() => {
              checkIfUserConfirmed();
            }}
          >
            {"I'VE CONFIRMED"}
          </TextButton>
        )}
        {showError && (
          <ResponseText style={{ position: 'absolute', top: '60%' }}>
            {`Looks like our systems don't show a confirmation just yet.`}
            <br />
            {`You can try again, or if you're still having trouble, please contact sales.`}
          </ResponseText>
        )}
        <ContactFormContainer className='flex row'>
          DIDN&apos;T GET AN EMAIL?
          <ClickableText
            onClick={() => {
              window.open(
                'https://help.atom.finance/hc/en-us/requests/new?ticket_form_id=5277869014551&tf_subject=API%20key%20request',
              );
            }}
          >
            {`CONTACT SALES DIRECTLY`}
          </ClickableText>
        </ContactFormContainer>
      </UIContentContainer>
    </ModalContentContainer>
  );
};

const ContactFormContainer = styled.div`
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top: 12%;
  color: #99999f;
`;

const ClickableText = styled.a<{ disabled?: boolean }>`
  font-weight: bold;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  color: #52e3c2;
  text-transform: uppercase;
  margin-left: 10px;
`;

const UIContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  row-gap: 2.5rem;
  align-items: center;
  margin-top: 2rem;
`;

const HeaderTitle = styled.div`
  font-size: 48px;
  margin-bottom: -1rem;
`;

const DescriptionLabel = styled.div`
  font-size: 16px;
  width: 80%;
  text-align: left;
`;

const ModalContentContainer = styled.div`
  background-color: #32323e;
  width: 60rem;
  height: 36rem;
  box-shadow: 0px 0px 30px -5px #000000;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
`;
