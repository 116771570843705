import React from 'react';
import styled from 'styled-components';
import numeral from '@toggleglobal/numeral';
import {
  getMarketStatus,
  luxEST,
  MarketStatus,
} from '@atom-finance/atom-types';

const ClosedMarketSection = ({
  tickerData,
  exchangeCode,
  inlineRow,
}: {
  tickerData: any;
  exchangeCode: string;
  inlineRow?: boolean;
}) => {
  const marketStatus = getMarketStatus(exchangeCode, luxEST());
  const market =
    marketStatus === MarketStatus.PreMarket ? 'pre market' : 'after hours';

  return (
    <ExtendedPrices
      negative={tickerData.extendedChange && tickerData.extendedChange < 0}
      small={inlineRow}
    >
      <ExtendedItem style={{ marginRight: inlineRow ? '4px' : '9px' }}>
        {`(`}
        {tickerData.extendedPrice && tickerData.extendedPrice.toFixed(2)}
      </ExtendedItem>
      <ExtendedItem style={{ marginRight: inlineRow ? '4px' : '9px' }}>
        {tickerData.extendedChange && tickerData.extendedChange < 0 ? '▾' : '▴'}{' '}
        {numeral(
          tickerData.extendedChangePercent && tickerData.extendedChangePercent,
        )
          .format('0.00%')
          .replace('-', '')}
      </ExtendedItem>
      <ExtendedItem style={{ marginRight: inlineRow ? '4px' : '6px' }}>
        {tickerData.extendedChange && tickerData.extendedChange < 0 ? '▾' : '▴'}{' '}
        {tickerData.extendedChange &&
          tickerData.extendedChange.toFixed(2).replace('-', '')}
      </ExtendedItem>
      <ExtendedItem>
        {market}
        {`)`}
      </ExtendedItem>
    </ExtendedPrices>
  );
};

export default React.memo(ClosedMarketSection);

const ExtendedPrices = styled.div<{ negative?: boolean; small?: boolean }>`
  font-weight: 400;
  font-size: ${props => (props.small ? 12 : 14)}px;
  padding-bottom: 6px;

  color: ${props => (props.negative ? 'var(--price-down)' : 'var(--price-up)')};
  @media (max-width: 414px) {
    font-size: 12px;
  }
`;

const ExtendedItem = styled.span``;
