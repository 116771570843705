import React from 'react';
import styled from 'styled-components';

export const SearchSection = ({ searchTicker, setSearchTicker }) => {
  let searchInput;
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      console.log(searchInput);
      setSearchTicker(searchInput.toUpperCase());
    }
  };

  const handleChange = event => {
    searchInput = event.target.value;
  };

  return (
    <SearchBar>
      <SearchTitle>Stock Centre</SearchTitle>
      <SearchContainer>
        <SearchDescription>Search by symbol or company</SearchDescription>
        <SearchTextEntry
          type='text'
          onKeyDown={handleKeyPress}
          onChange={handleChange}
        />
      </SearchContainer>
    </SearchBar>
  );
};

const SearchTitle = styled.div`
  color: var(--atom-viz-text-primary);
  padding: 8px;
  font-size: 1.5em;
`;

const SearchTextEntry = styled.input`
  border: 1px solid #414141;
  border-radius: 5px;
  background-color: #282832;
  color: var(--atom-viz-text-primary);
  padding: 8px;
  text-transform: uppercase;
`;

const SearchBar = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 20px 50px;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SearchDescription = styled.div`
  color: var(--atom-viz-text-primary);
`;
