import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { CONFIRM_SIGN_UP, GET_ADMIN_INFO } from '../../common/queries';
import { withRouter } from 'react-router-dom';
import { ResponseText } from './ui/GettingStartedContent';

const ConfirmEmail = props => {
  const token = props?.match?.params?.token;
  const id = props?.match?.params?.id;
  const [saveAdminInfo, saveAdminInfoResponse] = useMutation(CONFIRM_SIGN_UP);
  const [hasRunOnce, setHasRunOnce] = useState(false);

  const { data: adminInfo } = useQuery(GET_ADMIN_INFO, {
    variables: { id, token },
  });

  const success = saveAdminInfoResponse?.data?.confirmSignUp.success;
  const message = saveAdminInfoResponse?.data?.confirmSignUp.message;

  //   const apiKey = adminInfo?.adminInformation.partner.apiKey;
  const partnerName = adminInfo?.adminInformation.partner.name;

  useEffect(() => {
    saveAdminInfo({
      variables: {
        token,
        id,
      },
    });
  }, [id, token, saveAdminInfo]);

  useEffect(() => {
    if (!id || !token || hasRunOnce) return;
    localStorage.setItem('atom_userLoggedIn', 'true');
    localStorage.setItem('atom_userAdminId', id); // this is to save the user's id in case we need it later on
    localStorage.setItem('atom_userTokenId', token);
    setHasRunOnce(true);
  }, [id, token, hasRunOnce]);

  return (
    /*Sign In Screen*/
    <>
      <SigninContainer>
        <SigninSection>
          <TitleSectionTop>{partnerName}</TitleSectionTop>
          <SignInSectionContent>
            <DescriptionText>
              You&apos;re all set to get started with your trial API key. Our
              sales team will be in touch to discuss your specific needs, after
              which they can get you set up with a production API key. In the
              meantime, you can use this key to test our API endpoint in our
              <a
                style={{ color: 'var(--green)', marginLeft: '5px' }}
                href='https://docs.atom.finance/reference/introduction'
              >
                docs
              </a>
              .
            </DescriptionText>
            <DescriptionText>
              Note: In order to access our full library of assets, you will need
              a production key. You can still test any of our endpoints with
              your Trial Key, though the responses you receive with your Trial
              Key will be limited to a select few assets (e.g. AAPL, TSLA, SPY,
              QQQ, VTSAX, BTC and DOGE). For more info, see our docs on
              <a
                style={{ color: 'var(--green)', marginLeft: '5px' }}
                href='https://docs.atom.finance/reference/authentication'
              >
                authentication
              </a>
              .
              <br />
              <br />
              You may close this tab at any time.
            </DescriptionText>
            {!success ? (
              <ResponseText success={success}>{message}</ResponseText>
            ) : null}
          </SignInSectionContent>
        </SigninSection>
      </SigninContainer>
    </>
  );
};

const DescriptionText = styled.div`
  font-size: 22px;
  margin-bottom: 1.5em;
  margin-left: 3.5em;
  margin-right: 3.5em;
`;

const TitleSectionTop = styled.div`
  font-size: 48px;
  margin-bottom: 1em;
  margin-left: 1.5em;
  margin-right: 1.5em;
`;

const SignInSectionContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const SigninSection = styled.div`
  padding: 6em;
  border-radius: 6px;
  border: 1px solid var(--atom-dark-brand-5);
  display: flex;
  flex-direction: column;
`;

const SigninContainer = styled.div`
  height: 80%;
  width: 100%;
  justify-content: center;
  display: flex;
`;

export default withRouter(ConfirmEmail);
