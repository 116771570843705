import React, { useState } from 'react';
import styled from 'styled-components';
import { AtomDonut } from '@atom-finance/atom-viz';
import { SimpleTextBox } from './ui/TextBox';
import { ColorPreviewWithPalette } from './ui/ColorPreviewWithPalette';
import { ToggleButton } from './ui/ToggleButton';
import { TextButton } from './ui/TextButton';
import { APIGenerationModal } from './ui/APIGenerationModal';
import { WidgetType } from '../../helpers/generateWidgetCode';

const DemoSparkline = () => {
  const [assets, setAssets] = useState('');
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [priceUpColor, setPriceUpColor] = useState('#52E3C2');
  const [priceDownColor, setPriceDownColor] = useState('#F27362');
  const [previousCloseLine, setPreviousCloseLine] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <Container>
      <APIGenerationModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        widgetType={WidgetType.Sparkline}
        staticSettings={{}}
      />
      <>
        <HeaderAndDescriptionContainer>
          <HeaderTitle>Intraday Snapshot</HeaderTitle>
          <DescriptionLabel>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </DescriptionLabel>
        </HeaderAndDescriptionContainer>
      </>
      <WidgetAndSettingsContainer>
        <WidgetParentContainer>
          <WidgetContainer>
            <PoweredByLogo>powered by atom</PoweredByLogo>
            <WidgetTitle>{`Intraday Snapshot`}</WidgetTitle>
            <div style={{ width: '250px', height: '30rem' }}>
              <AtomDonut
                data={[
                  { label: 'one', value: 0.5 },
                  { label: 'two', value: 0.25 },
                  { label: 'three', value: 0.25 },
                ]}
              />
            </div>
          </WidgetContainer>
        </WidgetParentContainer>
        <WidgetSettingsContainer>
          <Row>
            <SettingsRowContainer>Assets:</SettingsRowContainer>
            <ContainerWithWidth width={18.5}>
              <SimpleTextBox
                current={assets}
                update={setAssets}
                noLeftPadding
              ></SimpleTextBox>
            </ContainerWithWidth>
          </Row>
          <Row>
            <SettingsRowContainer>{`Size (W x H):`}</SettingsRowContainer>
            <WidthAndHeightContainer>
              <ContainerWithWidth width={8.5}>
                <SimpleTextBox
                  current={width}
                  update={setWidth}
                  noLeftPadding
                ></SimpleTextBox>
              </ContainerWithWidth>
              <WidthHeightDivider>X</WidthHeightDivider>
              <ContainerWithWidth width={8.5}>
                <SimpleTextBox
                  current={height}
                  update={setHeight}
                  noLeftPadding
                ></SimpleTextBox>
              </ContainerWithWidth>
            </WidthAndHeightContainer>
          </Row>
          <Row>
            <SettingsRowContainer>Price Up Color:</SettingsRowContainer>
            <ColorPreviewWithPalette
              update={setPriceUpColor}
              current={priceUpColor}
              disable={false}
            ></ColorPreviewWithPalette>
          </Row>
          <Row>
            <SettingsRowContainer>Price Down Color:</SettingsRowContainer>
            <ColorPreviewWithPalette
              update={setPriceDownColor}
              current={priceDownColor}
              disable={false}
            ></ColorPreviewWithPalette>
          </Row>
          <Row>
            <SettingsRowContainer>Previous Close Line:</SettingsRowContainer>
            <ToggleButton
              isToggled={previousCloseLine}
              onClickHandler={setPreviousCloseLine}
            ></ToggleButton>
          </Row>
        </WidgetSettingsContainer>
      </WidgetAndSettingsContainer>
      <GenerateButtonContainer>
        <TextButton
          backgroundColor='var(--green)'
          hoverColor='var(--green-hover)'
          onClick={() => {
            setModalIsOpen(!modalIsOpen);
          }}
        >
          GENERATE MODULE
        </TextButton>
      </GenerateButtonContainer>
    </Container>
  );
};

export default React.memo(DemoSparkline);

const Row = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
`;

const WidthAndHeightContainer = styled.div`
  column-gap: 0.5rem;
  display: flex;
  flex-direction: row;
`;

const ContainerWithWidth = styled.div<{ width: number }>`
  width: ${props => props.width}rem;
`;

const HeaderAndDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  row-gap: 1.5rem;
  align-items: center;
`;

const HeaderTitle = styled.div`
  font-size: 60px;
`;

const DescriptionLabel = styled.div`
  font-size: 21px;
  max-width: 70rem;
`;

const WidgetAndSettingsContainer = styled.div`
  width: auto;
  margin-left: 20rem;
  margin-right: 20rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media (max-width: 1075px) {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    row-gap: 4rem;
  }
`;

const WidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  border: 1px solid var(--atom-brand-4);
  align-items: center;
  padding: 3rem;
  padding-top: 0rem;
  width: 20rem;
`;

const WidgetSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 1rem;
`;

const WidgetTitle = styled.div`
  font-size: 24px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
  height: 100%;
  width: 100%;
`;

const PoweredByLogo = styled.div`
  padding: 0.25rem;
  background-color: var(--atom-brand-4);
  font-size: 14px;
  text-align: center;
  margin-bottom: 1rem;
  position: relative;
  left: 5rem;
`;

const GenerateButtonContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 30rem;
`;

const SettingsRowContainer = styled.div`
  width: 11rem;
  text-align: right;
  font-size: 18px;

  @media (max-width: 650px) {
    width: auto;
  }
`;

const WidthHeightDivider = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-top: auto;
  margin-bottom: auto;
`;

const WidgetParentContainer = styled.div`
  display: flex;
  justify-content: center;
`;
