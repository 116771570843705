import React from 'react';
import styled from 'styled-components';
import { TextButton } from './ui/TextButton';
import AtomIcon from '../../common/AtomIcon';
import FeaturesCarousel, { CarouselItemType } from './ui/Carousel';
import sectorBreakdownImg from '../../static/images/sectorBreakdown.png';
import revenueEarningsImg from '../../static/images/revenueEarnings.png';

const carouselData: CarouselItemType[] = [
  {
    title: 'Stock Page Widget',
    description: `Just click this one`,
    route: '/demo/stock',
    image: sectorBreakdownImg,
  },
  {
    title: 'Markets Widget',
    description: `Just click this one`,
    route: '/demo/markets',
    image: sectorBreakdownImg,
  },
  {
    title: 'Sector Breakdown Donut Chart',
    description: `Our Sector Breakdown Donut Chart will
    simplify the way your clients ipsum.`,
    route: '/demo/fund-allocation',
    image: sectorBreakdownImg,
  },
  {
    title: 'Revenue Earnings Bar Chart',
    description: `Our Revenue Earnings Bar Chart will knock your socks off, in addition to simplifying the way your clients ipsum.`,
    route: '/demo/revenue-earnings',
    image: revenueEarningsImg,
  },
  {
    title: 'Sparklines!',
    description: `Our Sparklines are very helpful and great in terms of displaying information visually.`,
    route: '/demo/sparkline',
    image: sectorBreakdownImg,
  },
];

const DemoPage = () => {
  return (
    <Container>
      <SectionContainer>
        <LargeHeaderText>
          Take your investment <br />
          experience to the next level
        </LargeHeaderText>
        <DescriptionContainer>
          <DescriptionText>
            Our cutting-edge embeddable investment intelligence lorem will
            simplify the way your clients ipsum the markets, consume financial
            news, and apply data to make smart investment decisions, and
            whatnot.
          </DescriptionText>
        </DescriptionContainer>
        <TextButtonsContainer>
          <TextButton
            backgroundColor='var(--green)'
            hoverColor='var(--green-hover)'
          >
            CONTACT US
          </TextButton>
          <TextButton
            onClick={() => {
              window.location.href =
                'https://docs.atom.finance/reference/introduction';
            }}
          >
            VISIT OUR API HUB
          </TextButton>
        </TextButtonsContainer>
      </SectionContainer>
      <SectionContainer style={{ backgroundColor: '#f2f2f3' }}>
        <LargeHeaderText>What we offer:</LargeHeaderText>
        <FullWidthContainer>
          <FeaturesCarousel data={carouselData} />
        </FullWidthContainer>
      </SectionContainer>
      <SectionContainer style={{ backgroundColor: '#32323E' }}>
        <LargeHeaderText
          style={{
            color: '#FFFFFF',
            marginTop: '7rem',
            marginBottom: '4rem',
          }}
        >
          What these visualizations will do for you:
        </LargeHeaderText>
        <TableContainer>
          <Row>
            <Column>
              <IconAndTextContainer>
                <AtomIcon size={60} icon={'calendar'} />
                <DescriptionTextLight>
                  Integrate in days — <br />
                  launch in weeks
                </DescriptionTextLight>
              </IconAndTextContainer>
            </Column>
            <Column>
              <IconAndTextContainer>
                <AtomIcon size={60} icon={'investor'} />
                <DescriptionTextLight>
                  Serve novice, casual, <br />
                  and advanced investors
                </DescriptionTextLight>
              </IconAndTextContainer>
            </Column>
          </Row>
          <Row>
            <Column>
              <IconAndTextContainer>
                <AtomIcon size={60} icon={'actionable'} />
                <DescriptionTextLight>
                  Present actionable insights <br />
                  and unique visualizations
                </DescriptionTextLight>
              </IconAndTextContainer>
            </Column>
            <Column>
              <IconAndTextContainer>
                <AtomIcon size={60} icon={'investing'} />
                <DescriptionTextLight>
                  Create an engaging one-stop <br />
                  investing experience
                </DescriptionTextLight>
              </IconAndTextContainer>
            </Column>
          </Row>
        </TableContainer>
      </SectionContainer>
      <SectionContainer>
        <LargeHeaderText>Discover the power of Atom</LargeHeaderText>
        <DescriptionText style={{ marginTop: '-1.25rem' }}>
          {`Transform your clients’ investing experience quickly, easily, and affordably with Atom.`}
        </DescriptionText>
        <TextButtonsContainer style={{ marginTop: '1rem' }}>
          <TextButton
            backgroundColor='var(--green)'
            hoverColor='var(--green-hover)'
            style={{ width: '350px' }}
          >
            CONTACT US
          </TextButton>
          <TextButton
            onClick={() => {
              window.location.href =
                'https://docs.atom.finance/reference/introduction';
            }}
          >
            VISIT OUR API HUB
          </TextButton>
        </TextButtonsContainer>
      </SectionContainer>
    </Container>
  );
};

export default React.memo(DemoPage);

const Container = styled.div`
  margin: -2rem;
`;

const DescriptionContainer = styled.div`
  max-width: 70rem;
`;

const FullWidthContainer = styled.div`
  width: 100%;
`;

const LargeHeaderText = styled.div`
  font-size: 72px;
  color: #32323e;
  text-align: center;
`;

export const DescriptionText = styled.div`
  font-size: 21px;
  color: #32323e;
  text-align: center;
`;

const DescriptionTextLight = styled.div`
  font-size: 24px;
  color: #ffffff;
  text-align: center;
  padding-top: 1rem;
`;

const TextButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding-top: 0.25rem;
`;

const SectionContainer = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  background-color: white;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  padding-top: 6rem;
  padding-bottom: 6rem;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media (max-width: 750px) {
    flex-direction: column;
    align-items: center;
    row-gap: 6rem;
  }
`;

const IconAndTextContainer = styled.div`
  justify-content: center;
  text-align: center;
  width: 20rem;
`;

const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 6rem;
  justify-content: space-between;
  margin-bottom: 6rem;
`;

export const ButtonAndIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-top: -20px;
`;
