import React from 'react';
import styled from 'styled-components';
import { AtomSparkline } from '@atom-finance/atom-viz';
import { SparkDataType } from '../hooks';
import QuotePriceSection from '../QuoteHeader/QuotePriceSection';
import { useTickerData } from '../QuoteHeader/utils';
import { GET_DATA_FOR_SYMBOL } from '../queries';
import { useQuery } from '@apollo/react-hooks';

const SparklineRow = ({
  symbol,
  name,
  data,
}: {
  symbol: string;
  name: string;
  data: SparkDataType | undefined;
}) => {
  const { tickerData } = useTickerData(symbol) as any;
  const { data: symbolData, loading } = useQuery(GET_DATA_FOR_SYMBOL, {
    variables: { symbol },
  });
  const exchangeCode = symbolData?.dataForSymbol?.exchangeCode;

  if (data === undefined) return null;

  /* Render */
  return (
    <StyledRow>
      <StyledNameContainer>
        <StyledSymbol>{symbol}</StyledSymbol>
        <StyledName>{name}</StyledName>
      </StyledNameContainer>
      <SparklineContainer>
        {data.quotes.length ? (
          <AtomSparkline
            data={data.quotes.filter(d => d.Close)}
            previousClose={tickerData?.previousClose}
            gradient={true}
            backgroundColor={'var(--background-secondary)'}
          />
        ) : null}
      </SparklineContainer>
      <StyledQuoteContainer>
        {!loading && tickerData && (
          <QuotePriceSection
            tickerData={tickerData}
            exchangeCode={exchangeCode}
            inlineRow={true}
          />
        )}
      </StyledQuoteContainer>
    </StyledRow>
  );
};

export default React.memo(SparklineRow);

const StyledRow = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  background-color: var(--background-secondary);
  border-radius: 5px;
  margin: 5px 0px;
  padding: 0px 5px;
`;

const StyledQuoteContainer = styled.div<{ textAlign?: string }>`
  display: flex;
  margin: auto 10px;
  max-width: 25%;
  width: inherit;
  min-width: fit-content;
  text-align: right;
  white-space: nowrap;
  flex-direction: row-reverse;
  align-items: flex-start;
  text-overflow: ellipsis;
`;

const StyledNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 10px;
  max-width: 25%;
  width: inherit;
`;

const StyledSymbol = styled.div`
  text-transform: uppercase;
  font-weight: 600;
`;

const StyledName = styled.div`
  color: var(--text-secondary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.75em;
`;

const SparklineContainer = styled.div`
  height: 30px;
  max-width: 25%;
  flex-basis: 40%;
  margin: auto auto auto 0;
`;
