import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import { GettingStartedContent } from './GettingStartedContent';
import { StyledModal } from './Modal';
import { PartnerLoginContent } from './PartnerLoginContent';
import { WidgetCodeContent } from './WidgetCodeContent';
import { EmailConfirmationContent } from './EmailConfirmationContent';
import { WidgetType } from '../../../helpers/generateWidgetCode';

export const APIGenerationModal = ({
  modalIsOpen,
  setModalIsOpen,
  widgetType,
  staticSettings,
  dynamicSettings,
}: {
  modalIsOpen: boolean;
  setModalIsOpen: Dispatch<SetStateAction<boolean>>;
  widgetType: WidgetType;
  staticSettings: { [key: string]: any };
  dynamicSettings?: { [key: string]: any };
}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [signupEmail, setSignupEmail] = useState('');
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [signupPassword, setSignupPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  // company website is an optional field
  const [companyWebsite, setCompanyWebsite] = useState(null);
  const [confirmedSignupPassword, setConfirmedSignupPassword] = useState('');
  const [allowCommunications, setAllowCommunications] = useState(true);
  const userIsLoggedIn = () => localStorage.getItem('atom_userLoggedIn');
  const userAdminId = () => localStorage.getItem('atom_userAdminId');
  const userTokenId = () => localStorage.getItem('atom_userTokenId');
  const [currentModalPage, setCurrentModalPage] = useState(
    userIsLoggedIn() === 'true' ? 'widgetCode' : 'getStarted',
  ); // possible modal pages are getStarted, partnerLogin, and widgetCode

  const updateModalContent = event => {
    // this is the sign up flow
    if (event.key === 'atom_userLoggedIn' && event.newValue === 'true') {
      const _userAdminId = userAdminId();
      const _userTokenId = userTokenId();
      setCurrentModalPage('widgetCode');
    } else if (!event.key) {
      // this is the log in flow
      const _userAdminId = userAdminId();
      const _userTokenId = userTokenId();
      const _userIsLoggedIn = userIsLoggedIn();
      if (_userIsLoggedIn === 'true') {
        setCurrentModalPage('widgetCode');
      }
    }
  };

  useEffect(() => {
    // if user is logged in, then show them the widget code
    window.addEventListener('storage', updateModalContent);
    return () => window.removeEventListener('storage', updateModalContent);
    // eslint-disable-next-line
  }, []);

  return (
    <StyledModal
      width={'100%'}
      height={'100%'}
      isOpen={modalIsOpen}
      onRequestClose={() => {
        setModalIsOpen(!modalIsOpen);
      }}
      style={{
        overlay: {
          backgroundColor: 'rgba(40,40,51,0.85)',
        },
      }}
    >
      <ModalContainer>
        {currentModalPage === 'getStarted' ? (
          <GettingStartedContent
            setModalIsOpen={setModalIsOpen}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            workEmail={signupEmail}
            setWorkEmail={setSignupEmail}
            setModalContent={setCurrentModalPage}
            password={signupPassword}
            setPassword={setSignupPassword}
            companyName={companyName}
            setCompanyName={setCompanyName}
            confirmedPassword={confirmedSignupPassword}
            setConfirmedPassword={setConfirmedSignupPassword}
            jobTitle={jobTitle}
            setJobTitle={setJobTitle}
            companyWebsite={companyWebsite}
            setCompanyWebsite={setCompanyWebsite}
            allowCommunications={allowCommunications}
            setAllowCommunications={setAllowCommunications}
          />
        ) : currentModalPage === 'partnerLogin' ? (
          <PartnerLoginContent
            setModalIsOpen={setModalIsOpen}
            loginEmail={loginEmail}
            setLoginEmail={setLoginEmail}
            password={loginPassword}
            setPassword={setLoginPassword}
            setModalContent={setCurrentModalPage}
          />
        ) : currentModalPage === 'confirmEmail' ? (
          <EmailConfirmationContent
            setModalIsOpen={setModalIsOpen}
            setModalContent={setCurrentModalPage}
            email={signupEmail}
          />
        ) : (
          <WidgetCodeContent
            setModalIsOpen={setModalIsOpen}
            widgetType={widgetType}
            staticSettings={staticSettings}
            dynamicSettings={dynamicSettings}
          />
        )}
      </ModalContainer>
    </StyledModal>
  );
};

const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
