import React from 'react';
import styled from 'styled-components';
import useElementSize from '../../common/hooks';
import RevenueEarningsBarChart from '../RevenueEarningsBarChart';

const DemoRevenueEarningsBarChart = () => {
  const [containerRef] = useElementSize();

  return (
    <>
      <StyledVizContainer ref={containerRef}>
        <RevenueEarningsBarChart></RevenueEarningsBarChart>
      </StyledVizContainer>
    </>
  );
};

export default React.memo(DemoRevenueEarningsBarChart);

const StyledVizContainer = styled.div`
  display: block;
  min-width: 200px;
  height: 500px;

  .projected_a {
    fill: #3b4e62;
  }
  .projected_b {
    fill: #4a3a58;
  }
`;
