import React from 'react';
import styled from 'styled-components';

const NavigationItems = [
  'Account Information',
  'Trading',
  'Order Status',
  'Cash Transfer',
  'Quotes and Research',
  'More',
];

export const Navigation = ({ selection, setSelection }) => {
  return (
    <>
      <StyledNavigation>
        {NavigationItems.map((item, i) => {
          return (
            <StyledNavLink
              key={i}
              className={selection === item ? 'selected' : ''}
              onClick={() => setSelection(item)}
            >
              {item}
            </StyledNavLink>
          );
        })}
      </StyledNavigation>
    </>
  );
};

const StyledNavLink = styled.div`
  border-top: solid white 2px;
  padding: 20px 10px;
  color: var(--atom-viz-text-primary);
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 1.5px;
  &:hover {
    color: #9a60eb;
    text-decoration: underline;
  }
  &.selected {
    border-top: solid #9a60eb 2px;
    color: #9a60eb;
  }
`;

const StyledNavigation = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  /* border-top: thin solid #dcdcdc; */
`;

const SubNavigationItems = [
  'Market Centre',
  'Stock Lists',
  'Stock Centre',
  'ETF Centre',
  'Fund Centre',
  'Analyst Reports',
  'My Alerts',
];

export const SubNavigation = ({ selection, setSelection }) => {
  return (
    <>
      <StyledSubNavigation>
        {SubNavigationItems.map((item, i) => {
          return (
            <StyledSubNavLink
              key={i}
              className={selection === item ? 'selected' : ''}
              onClick={() => setSelection(item)}
            >
              {item}
            </StyledSubNavLink>
          );
        })}
      </StyledSubNavigation>
    </>
  );
};

const StyledSubNavLink = styled.div`
  padding: 20px 10px;
  color: var(--atom-viz-text-primary);
  font-size: 0.8em;
  &:hover {
    color: var(--highlight);
    text-decoration: underline;
  }
  &.selected {
    color: var(--highlight);
  }
`;

const StyledSubNavigation = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  border-bottom: thin solid #dcdcdc;
`;

const SectionNavigationItems = [
  'Snapshot',
  'News',
  'Charts',
  'Analyst Ratings',
  'Reports',
  'Fundamentals',
  'Earnings',
  'Financials',
];

export const SectionNav = ({ selection, setSelection }) => {
  return (
    <>
      <StyledSectionNavigation>
        {SectionNavigationItems.map((item, i) => {
          return (
            <StyledSectionNavLink
              key={i}
              className={selection === item ? 'selected' : ''}
              onClick={() => setSelection(item)}
            >
              {item}
            </StyledSectionNavLink>
          );
        })}
      </StyledSectionNavigation>
    </>
  );
};

const StyledSectionNavLink = styled.div`
  padding: 0.5em 1em;
  background-color: #f2f3f2;
  border-radius: 80px;
  color: var(--atom-viz-text-primary);
  &.selected {
    color: white;
    background-color: #333333;
  }
`;

const StyledSectionNavigation = styled.div`
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
`;
