import React from 'react';
import styled from 'styled-components';

type Props = {
  customJsx?: JSX.Element;
  children?: React.ReactNode;
};
type State = {
  hasError: Boolean;
};

/**
 * This component can be wrapped around any page or sub component to make sure that any JS errors will not
 * crash the the entire component tree.
 * React docs: https://reactjs.org/docs/error-boundaries.html.
 *
 * This has to be built as a class component for now, as hooks do not have a menthod for `componentDidCatch`
 * and `getDerivedStateFromError` just yet (Jan 2022, ref:https://reactjs.org/docs/error-boundaries.html).
 * @param customJsx If you'd like custom html to appear in the error boundary, that would be component specific.
 */

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Potential to log issues to new relic or elsewhere here
    console.error(errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPage>
          {this.props.customJsx ? (
            this.props.customJsx
          ) : (
            <>
              <h1>Opps! Something went wrong.</h1>
              <div style={{ margin: '1em 0' }}>
                <span>
                  Try reloading the page, and if the problem persists, let us
                  know.
                </span>
              </div>
            </>
          )}
        </ErrorPage>
      );
    }

    return this.props.children;
  }
}

const ErrorPage = styled.div`
  padding: 1em;
`;

export const withErrorBoundary = Component => {
  return props => (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  );
};
