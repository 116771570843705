import React from 'react';
import styled from 'styled-components';
import useElementSize from '../../../common/hooks';
import '@atomfinance/atom-viz-web-components';

const ContentWrapper = ({ symbol }) => {
  const [containerRef] = useElementSize();

  return (
    <>
      <StyledContainer ref={containerRef}>
        <AssetSection>
          <SectionTitle>Asset Overview</SectionTitle>
          <atom-asset-overview asset={symbol} />
        </AssetSection>
        <PriceSection>
          <atom-asset-price-history asset={symbol} />
        </PriceSection>
      </StyledContainer>
      <StyledContainer>
        <AssetSection>
          <atom-insights-pillar-table
            asset={symbol}
            header={true}
            compare={true}
            pillar-score={true}
            difference-column={true}
            score-column={true}
            pillar={'valuation'}
          />
        </AssetSection>
        <AssetSection>
          <SectionTitle>News Feed</SectionTitle>
          <atom-news-feed asset={symbol} />
        </AssetSection>
      </StyledContainer>
    </>
  );
};

const StyledContainer = styled.div`
  width: calc(100% - 100px);
  display: grid;
  align-items: start;
  grid-template-columns: repeat(auto-fit, minmax(550px, 1fr));
  gap: 40px;
  padding: 50px;
  color: var(--atom-viz-text-primary);

  @media (max-width: 1000px) {
    flex-direction: column;
  }

  .atom-asset-overview-description-content {
    margin: 1em 0;
    line-height: 150%;
  }

  .atom-asset-overview-header {
    font-size: 1.5em;
  }
`;

const SectionTitle = styled.div`
  font-size: 2em;
  /* padding: 8px; */
`;

const AssetSection = styled.div`
  .atom-news-feed-filters {
    display: none;
  }

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const PriceSection = styled.div`
  width: 100%;
  border: 1px solid var(--atom-background-secondary);
  border-radius: 5px;

  @media (max-width: 1000px) {
    width: 100%;
  }

  atom-news-feed .atom-news-feed-cards {
    height: 600px;
    overflow-y: scroll;
  }

  atom-news-feed .atom-news-feed-filters {
    padding: 0.75em 0;
  }

  atom-news-feed .atom-news-feed-card-parent .atom-news-feed-card-teaser {
    line-height: 150%;
  }

  .atom-news-feed-filters select {
    color: var(--atom-viz-text-primary);
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #dcdcdc;
    font-family: var(--font);

    &::placeholder {
      font-family: var(--font);
    }
  }
`;

export default ContentWrapper;
