import React from 'react';
import { AtomDualAxisBarChart } from '@atom-finance/atom-viz';
import styled from 'styled-components';
import useElementSize from '../common/hooks';

const RevenueEarningsBarChart = () => {
  const [containerRef, { width, height }] = useElementSize();

  return (
    <>
      <h1>RevenueEarningsBarChart</h1>
      <StyledVizContainer ref={containerRef}>
        {width && height && (
          <AtomDualAxisBarChart
            data={[
              {
                group: 'one',
                label: 'a',
                value: 90904282,
                color: 'var(--atom-dark-blue)',
              },
              {
                group: 'one',
                label: 'b',
                value: 1.25,
                color: 'var(--atom-dark-purple)',
              },
              {
                group: 'two',
                label: 'a',
                value: 86235135,
                color: 'var(--atom-dark-blue)',
              },
              {
                group: 'two',
                label: 'b',
                value: 1.35,
                color: 'var(--atom-dark-purple)',
              },
              {
                group: 'three',
                label: 'a',
                value: 8952386,
                classname: 'projected_a',
                color: 'var(--atom-dark-blue)',
              },
              {
                group: 'three',
                label: 'b',
                value: -0.45,
                classname: 'projected_b',
                color: 'var(--atom-dark-purple)',
              },
            ]}
            y1Label={'a'}
            y2Label={'b'}
            axisColor='var(--highlight)'
            width={width}
            height={height}
          />
        )}
      </StyledVizContainer>
    </>
  );
};

export default React.memo(RevenueEarningsBarChart);

const StyledVizContainer = styled.div`
  display: block;
  min-width: 200px;
  height: 500px;

  .projected_a {
    fill: #3b4e62;
  }
  .projected_b {
    fill: #4a3a58;
  }
`;
