import { useMutation, useQuery } from '@apollo/react-hooks';
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import AtomIcon from '../../../common/AtomIcon';
import {
  CREATE_VIZ_WIDGET,
  GET_PARTNER_ID_FROM_ADMIN_ID,
} from '../../../common/queries';
import { Spinner } from '../../../common/ui';
import generateWidgetScaffolding from '../../../helpers/generateWidgetCode';
import { TextButton } from './TextButton';

export const WidgetCodeContent = ({
  setModalIsOpen,
  widgetType,
  staticSettings,
  dynamicSettings,
}) => {
  const [createVizWidget, createVizWidgetResponse] =
    useMutation(CREATE_VIZ_WIDGET);

  const createVizWidgetEntry = useCallback(
    async (vizEndpoint: string, partnerId: string, customizations?: string) => {
      createVizWidget({
        variables: {
          vizEndpoint,
          partnerId,
          customizations,
        },
      });
    },
    [createVizWidget],
  );

  const adminId = localStorage.getItem('atom_userAdminId');
  const { data: partnerIdData, error: partnerIdError } = useQuery(
    GET_PARTNER_ID_FROM_ADMIN_ID,
    {
      variables: { adminId },
    },
  );
  const partnerId = partnerIdData?.partnerIdFromAdminId?.id;

  useEffect(() => {
    if (!partnerId) return;
    createVizWidgetEntry(
      `/${widgetType}`,
      partnerId,
      JSON.stringify(staticSettings),
    );
    // eslint-disable-next-line
  }, [partnerId]);

  const { data, error: vizWidgetError } = createVizWidgetResponse;
  const vizId = data?.createVizWidget?.id;

  const widgetCode = generateWidgetScaffolding(
    widgetType,
    vizId,
    dynamicSettings,
  );
  const copyWidgetCodeAndCloseModal = () => {
    const textarea: any = document.getElementById('widgetCode');
    if (textarea) {
      textarea.select();
      // for mobile
      textarea.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(textarea.value).then(
        function () {
          /* clipboard successfully set */
          setModalIsOpen(false);
        },
        function () {
          /* clipboard write failed */
          alert('Error: failed to copy to clipboard.');
        },
      );
    }
  };

  return (
    <ModalContentContainer>
      <AtomIcon
        icon='close'
        size={24}
        iconCss={'fill: var(--green)'}
        onClick={() => {
          setModalIsOpen(false);
        }}
        style={{
          paddingTop: '1rem',
          paddingRight: '1rem',
          alignSelf: 'flex-end',
        }}
      />
      <UIContentContainer>
        <HeaderTitle>{"You're all set"}</HeaderTitle>
        <DescriptionLabel>
          Copy the below HTML and paste directly into your site. For more
          details, check out
          <FAQLink href='https://help.atom.finance/hc/en-us'> our FAQ.</FAQLink>
        </DescriptionLabel>
        {(partnerIdError || vizWidgetError) && (
          <ErrorContainer>
            <div>
              Looks like there&apos;s been an error with retrieving your partner
              information. Please log in again and retry.
            </div>
          </ErrorContainer>
        )}
        {!vizId && !vizWidgetError && !partnerIdError && <Spinner />}
        {vizId && (
          <WidgetCodeTextArea id='widgetCode' name='widgetCode' readOnly>
            {widgetCode}
          </WidgetCodeTextArea>
        )}
        <TextButton
          backgroundColor='var(--green)'
          hoverColor='var(--green-hover)'
          onClick={copyWidgetCodeAndCloseModal}
          style={{
            width: '25rem',
            borderRadius: '0px',
          }}
        >
          COPY + CLOSE
        </TextButton>
      </UIContentContainer>
    </ModalContentContainer>
  );
};

const WidgetCodeTextArea = styled.textarea`
  background-color: #3f3f49;
  width: 75%;
  height: 13rem;
  align-self: center;
  color: #ffffff;
  border: none;
  resize: none;
  outline: none;
`;

const UIContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  row-gap: 1.75rem;
  align-items: center;
`;

const HeaderTitle = styled.div`
  font-size: 48px;
  margin-bottom: -1rem;
`;

const FAQLink = styled.a`
  font-size: 18px;
  color: var(--green);
`;

const DescriptionLabel = styled.div`
  font-size: 18px;
`;

const ModalContentContainer = styled.div`
  background-color: #32323e;
  width: 60rem;
  height: 36rem;
  box-shadow: 0px 0px 30px -5px #000000;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
`;

const ErrorContainer = styled.div`
  width: 75%;
  height: 13rem;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding: 10%;
  box-sizing: border-box;
  font-style: italic;
`;
