import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Streamed,
  streamedDataStore,
  subscribe,
  unsubscribe,
} from '@atom-finance/river-ws-client';
import { useQuery } from '@apollo/react-hooks';
import { GET_SPARK_DATA, GET_VIZ_WIDGET_SETTINGS } from './queries';
import { getTodayMarketOpenClose } from '@atom-finance/atom-types';

interface Size {
  width: number;
  height: number;
}

function useElementSize<T extends HTMLElement = HTMLDivElement>(): [
  (node: T | null) => void,
  Size,
] {
  // Mutable values like 'ref.current' aren't valid dependencies
  // because mutating them doesn't re-render the component.
  // Instead, we use a state as a ref to be reactive.
  const [ref, setRef] = useState<T | null>(null);
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  const handleSize = useCallback(() => {
    setSize({
      width: ref?.offsetWidth || 0,
      height: ref?.offsetHeight || 0,
    });
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  useEffect(() => {
    window.addEventListener('resize', handleSize);
    return () => {
      window.removeEventListener('resize', handleSize);
    };
  });

  useEffect(() => {
    handleSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  return [setRef, size];
}

export default useElementSize;

export const useStreaming = (
  symbol?: string,
  fields?: string[],
): Streamed | null => {
  const [data, setData] = useState<Streamed | null>(null);

  useEffect(() => {
    const filteredSetData = newData => {
      if (!fields?.length) return setData(newData);

      const previousData =
        symbol && streamedDataStore.get(symbol)?.streamedData;
      if (!previousData) return setData(newData);
      for (const field of fields) {
        if (previousData[field] !== newData[field]) {
          return setData(newData);
        }
      }
    };

    if (!symbol) {
      return;
    }
    subscribe(symbol, filteredSetData);
    return () => {
      unsubscribe(symbol, filteredSetData);
      setData(null);
    };
  }, [symbol, fields]);

  if (data) return data;
  const action = symbol && streamedDataStore.get(symbol);
  return (action && action.streamedData) || null;
};

// From https://stackoverflow.com/questions/53446020/how-to-compare-oldvalues-and-newvalues-on-react-hooks-useeffect
export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useCustomizationsFromToken = token => {
  const { data: vizWidgetData } = useQuery(GET_VIZ_WIDGET_SETTINGS, {
    variables: { vizId: token },
  });
  if (vizWidgetData?.vizWidgetSettings?.customizations) {
    const parsedCustomizations = JSON.parse(
      vizWidgetData?.vizWidgetSettings?.customizations,
    );
    const pairs = Object.entries(parsedCustomizations);
    return new Map<string, any>(pairs);
  } else return undefined;
};

export interface SparkDataType {
  quotes: any[];
  symbolId: string;
  timeSeriesInfo: object;
}

/**
 * useSparklineData is a query hook that will ping GET_SPARK_DATA every 5 minutes
 * once it is initialized. This is to prevent our sparkline data getting too old.
 * @param tickers an array of tickers that will be passed to the query.
 * @returns an array with 2 elements: a string (ticker symbol) and the associated SparkDataType
 */
export const useSparklineData = (
  tickers: string[],
): [string, SparkDataType][] => {
  const variables = useMemo(() => {
    // [start, end] should stay in useMemo, it was causing infinate triggers of the query
    // as it was changing the value of `variables`
    const [start, end] = getTodayMarketOpenClose();
    return {
      symbols: tickers.map(t => ({ symbol: t })),
      periodUnit: 'Minute',
      periodLength: 5,
      startDate: start.toJSDate(),
      endDate: end.toJSDate(),
    };
  }, [tickers]);
  const { loading, error, data } = useQuery(GET_SPARK_DATA, {
    variables,
    fetchPolicy: 'network-only',
    pollInterval: 300000, // re-fetch every 5 min
  });

  if (loading) return [];
  if (error) {
    console.debug('[Error - Intraday Data Fetcher]', error);
    return [];
  }
  return tickers.map((ticker, i) => [ticker, data.chartData[i]]); // this becomes a Map
};
