import React from 'react';
import styled from 'styled-components';

const NavigationItems = [
  'Account Information',
  'Trading',
  'Order Status',
  'Cash Transfer',
  'Quotes and Research',
  'More',
];

export const Navigation = ({ selection, setSelection }) => {
  return (
    <>
      <StyledNavigation>
        {NavigationItems.map((item, i) => {
          return (
            <StyledNavLink
              key={i}
              className={selection === item ? 'selected' : ''}
              // onClick={() => setSelection(item)}
            >
              {item}
            </StyledNavLink>
          );
        })}
      </StyledNavigation>
    </>
  );
};

const StyledNavLink = styled.div`
  border-top: solid white 2px;
  padding: 10px 10px;
  color: var(--atom-viz-text-primary);
  &:hover {
    color: var(--atom-viz-text-primary);
    text-decoration: underline;
  }
  &.selected {
    border-top: solid var(--atom-viz-color-brand-2) 2px;
    color: var(--atom-viz-color-brand-2);
  }
`;

const StyledNavigation = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  border-top: thin solid var(--atom-viz-background-secondary);
  border-bottom: thin solid var(--atom-viz-background-secondary);
`;

const SubNavigationItems = [
  { name: 'Market Center', active: true },
  { name: 'Stock Lists', active: false },
  { name: 'Stock Center', active: true },
  { name: 'ETF Center', active: true },
  { name: 'Fund Center', active: false },
  { name: 'Analyst Reports', active: false },
  { name: 'My Alerts', active: false },
];

export const SubNavigation = ({ selection, setSelection }) => {
  return (
    <>
      <StyledSubNavigation>
        {SubNavigationItems.map(({ name, active }, i) => {
          return (
            <StyledSubNavLink
              key={i}
              className={selection === name ? 'selected' : ''}
              onClick={() => (active ? setSelection(name) : null)}
            >
              {name}
            </StyledSubNavLink>
          );
        })}
      </StyledSubNavigation>
    </>
  );
};

const StyledSubNavLink = styled.div`
  padding: 20px 10px;
  color: var(--atom-viz-text-primary);
  font-size: 0.8em;
  &:hover {
    /* color: var(--atom-viz-color-brand-2); */
    font-weight: 900;
  }
  &.selected {
    color: var(--atom-viz-color-brand-2);
    font-weight: 900;
  }
`;

const StyledSubNavigation = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  border-bottom: thin solid var(--atom-viz-background-secondary);
`;

const SectionNavigationItems = [
  { name: 'Snapshot', active: true },
  { name: 'News', active: true },
  { name: 'Charts', active: false },
  { name: 'Analyst Ratings', active: false },
  { name: 'Reports', active: false },
  { name: 'Fundamentals', active: true },
  { name: 'Earnings', active: true },
  { name: 'Financials', active: true },
];

export const SectionNav = ({ selection, setSelection }) => {
  return (
    <>
      <StyledSectionNavigation>
        {SectionNavigationItems.map(({ name, active }, i) => {
          return (
            <StyledSectionNavLink
              key={i}
              className={selection === name ? 'selected' : ''}
              onClick={() => (active ? setSelection(name) : null)}
            >
              {name}
            </StyledSectionNavLink>
          );
        })}
      </StyledSectionNavigation>
    </>
  );
};

const StyledSectionNavLink = styled.div`
  padding: 0.5em 1em;
  background-color: var(--atom-viz-background-primary);
  border-radius: 80px;
  color: var(--atom-viz-text-primary);
  cursor: pointer;
  &.selected {
    font-weight: 900;
    background-color: var(--atom-viz-color-brand-3);
    color: var(--atom-viz-text-contrast);
  }
  &:hover {
    background-color: var(--atom-viz-color-brand-3);
    color: var(--atom-viz-text-contrast);
  }
`;

const StyledSectionNavigation = styled.div`
  padding: 20px 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 5px;
`;
