import React from 'react';
import '@atomfinance/atom-viz-web-components';

const FinancialsWrapper = ({ asset }) => {
  return (
    <>
      <atom-asset-income-statement asset={asset} />
    </>
  );
};

export default FinancialsWrapper;
