import React from 'react';
import '@atomfinance/atom-viz-web-components';
import styled from 'styled-components';

const FundamentalsWrapper = ({ asset }) => {
  return (
    <FlexContainer>
      <Column>
        <atom-insights-pillar-slider asset={asset} pillar={'growth'} />
        <atom-insights-pillar-slider asset={asset} pillar={'businessQuality'} />
        <atom-insights-pillar-slider asset={asset} pillar={'valuation'} />
        <atom-insights-pillar-slider asset={asset} pillar={'financialHealth'} />
        {/* <atom-insights-pillar-slider asset={asset} pillar={'risks'} /> */}
        <atom-insights-metric-slider
          asset={asset}
          pillar={'businessQuality'}
          metric={'recentProfitability'}
        />
        <atom-insights-metric-slider-check
          asset={asset}
          pillar={'businessQuality'}
          metric={'recentProfitability'}
        />
      </Column>
      <Column>
        <atom-insights-pillar-table asset={asset} pillar={'growth'} />
        <atom-insights-pillar-table asset={asset} pillar={'businessQuality'} />
        <atom-insights-pillar-table asset={asset} pillar={'valuation'} />
        <atom-insights-pillar-table asset={asset} pillar={'financialHealth'} />
        <atom-insights-pillar-table asset={asset} pillar={'risks'} />
        <atom-insights-metric-data
          asset={asset}
          pillar={'businessQuality'}
          metric={'recentProfitability'}
        />
      </Column>
    </FlexContainer>
  );
};

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4em;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 50%;
  gap: 4rem;
`;

export default FundamentalsWrapper;
