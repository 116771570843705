import React from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import { ReactComponent as InfoIcon } from '../../../static/icons/InfoLetterI.svg';

type AtomTooltipType = {
  title?: string;
  subtitle?: string | JSX.Element;
  icon?: any;
  customItem?: any;
  altText: string;
  iconHeight?: string;
  tooltipDirection?:
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'top-start'
    | 'top-end'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'bottom-end'
    | 'bottom-start';
  marginLeft?: string;
  marginRight?: string;
  children?: any;
  tooltipNumber?: number;
  customWidth?: string;
  verticalOffset?: string;
  featureSource?: string;
  color?: string;
};

interface StyleTippyContentProps {
  customWidth: string;
}

export const AtomTooltip = React.memo(function AtomTooltip({
  title,
  subtitle,
  icon,
  customItem,
  altText,
  iconHeight,
  tooltipDirection,
  marginLeft,
  marginRight,
  children,
  customWidth,
  verticalOffset,
  color,
}: AtomTooltipType) {
  let offset: [number, number] = [
    0,
    verticalOffset ? parseInt(verticalOffset) : 0,
  ];

  // if the direction is horizontal, the x, y axis will swap
  if (tooltipDirection?.search(/(right|left)/) !== -1) {
    offset = [offset[1], offset[0]];
  }

  const width: string = customWidth || '350px';
  const customStyle = {
    height: iconHeight || '14px',
    marginLeft: marginLeft || '8px',
    marginRight: marginRight || '0px',
    color: color,
  };

  return (
    <>
      <StyledTippyIcon
        arrow
        interactive
        trigger='mouseenter'
        delay={50}
        theme={'light'}
        placement={tooltipDirection || 'top'}
        maxWidth={'none'}
        offset={offset}
        content={
          <StyledTippyContent customWidth={width}>
            {title && (
              <TooltipTitle style={{ marginBottom: subtitle ? '8px' : '0px' }}>
                {title}
              </TooltipTitle>
            )}
            {subtitle && <TooltipSubtitle>{subtitle}</TooltipSubtitle>}
            {(title || subtitle) && children && (
              <div style={{ height: '1.5rem' }} />
            )}
            {children && children}
          </StyledTippyContent>
        }
      >
        <TooltipIcon>
          {customItem ? (
            <IconWrapper>{customItem}</IconWrapper>
          ) : icon ? (
            <img src={icon} style={customStyle} alt={altText} />
          ) : (
            <InfoIcon style={{ ...customStyle, stroke: color }} />
          )}
        </TooltipIcon>
      </StyledTippyIcon>
    </>
  );
});

const StyledTippyIcon = styled(Tippy)`
  min-width: 25em;
  margin: 20px;
`;

const StyledTippyContent = styled.div<StyleTippyContentProps>`
  width: ${props => props.customWidth};
  opacity: 1;
  padding: 1rem;
`;

const TooltipTitle = styled.div.attrs({
  className: 'font-din',
})`
  font-size: 20px;
`;

const TooltipSubtitle = styled.div.attrs({
  className: 'font-din',
})`
  font-size: 12px;
`;

const IconWrapper = styled.div`
  display: flex;
`;

const TooltipIcon = styled.div`
  &:hover {
    cursor: pointer;
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;
