import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import queryString from 'qs';
import PriceChart from '../common/PriceChart';
import QuoteHeader from '../common/QuoteHeader/QuoteHeader';
import PoweredByAtom from '../common/PoweredByAtom';
import StockOverview from '../common/StockDescription/Overview';
import { useCustomizationsFromToken } from '../common/hooks';
import ErrorBoundary from '../common/ErrorBoundary';
import SentimentChart from '../common/SentimentChart';

const StockPageWidget = ({
  demoSymbol,
  demoQuoteHeader = true,
  demoPriceChart = true,
  demoNewsSentiment = true,
  demoStockOverview = true,
  demoTheme = 'Light',
}: {
  demoSymbol?: string;
  demoQuoteHeader?: boolean;
  demoPriceChart?: boolean;
  demoNewsSentiment?: boolean;
  demoStockOverview?: boolean;
  demoTheme?: string;
}) => {
  /* Parse Query String */
  const { search } = useLocation();
  const { token, symbol, theme } = queryString.parse(search.slice(1));

  /* Parse Customizations */
  const customizations = useCustomizationsFromToken(token);
  // TODO: [EF] validate this is a real symbol
  const parsedSymbol: undefined | string = (symbol as string) ?? demoSymbol;
  const quoteHeader: undefined | boolean =
    customizations?.get('quoteHeader') ?? demoQuoteHeader;
  const priceChart: undefined | boolean =
    customizations?.get('priceChart') ?? demoPriceChart;
  const newsSentiment: undefined | boolean =
    customizations?.get('newsSentiment') ?? demoNewsSentiment;
  const stockOverview: undefined | boolean =
    customizations?.get('stockOverview') ?? demoStockOverview;
  const parsedTheme: undefined | string = (theme as string) ?? demoTheme;

  /* Render */
  return (
    <>
      <ErrorBoundary>
        <StyledContainer data-theme={parsedTheme}>
          {parsedSymbol && (
            <>
              {quoteHeader && <QuoteHeader symbol={parsedSymbol} />}
              {priceChart && <PriceChart symbol={parsedSymbol} />}
              <PoweredByAtom />
              {newsSentiment && <SentimentChart symbol={parsedSymbol} />}
              {stockOverview && <StockOverview symbol={parsedSymbol} />}
            </>
          )}
        </StyledContainer>
      </ErrorBoundary>
    </>
  );
};

export default React.memo(StockPageWidget);

const StyledContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  min-width: fit-content;
  width: 100%;
  max-width: 700px;
  height: fit-content;
  background-color: var(--background-primary);
`;
