import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { GET_CHART_DATA } from '../common/queries';
import { useQuery } from '@apollo/react-hooks';
import {
  AtomPriceLineChart,
  PriceTimeDomains,
  widgetChartSpanMapping,
} from '@atom-finance/atom-viz';
import { Spinner } from './ui';
import TimeSelection, { dateFormatMapping } from './TimeSelection';
import {
  ComponentSubtitle,
  ComponentTitle,
  ComponentTitleContainer,
  NoDataCenteredText,
} from './commonStyledElements';
import { useTickerData } from './QuoteHeader/utils';

const PriceChart = ({ symbol }) => {
  const [timespan, setTimespan] = useState<string>(PriceTimeDomains.oneDay);
  const [data, setData] = useState<[] | any[]>([]);
  const [resizeTrigger, setResizeTrigger] = useState(0);
  const containerRef = useRef(null);

  const now = DateTime.now().startOf('hour');
  const { periodUnit, periodLength, startDateFn } =
    widgetChartSpanMapping[timespan];
  const startDate = startDateFn(now);

  const { isDownOnDay } = useTickerData(symbol);

  /* Query Data and Transform */
  const endDate = now;
  const { loading, data: rawData } = useQuery(GET_CHART_DATA, {
    variables: {
      symbols: [{ symbol }],
      periodUnit,
      periodLength,
      startDate,
      endDate,
    },
  });
  useEffect(() => {
    if (loading) setData([]);
    if (!loading && rawData) {
      if (rawData.chartData) {
        setData(rawData.chartData[0]?.quotes.filter(d => d.Close !== null));
      }
    }
  }, [loading, rawData]);
  const formats = dateFormatMapping[timespan];

  const resizeObserver = new ResizeObserver(function (entries) {
    setResizeTrigger(entries[0].contentRect.width);
  });
  if (containerRef.current) resizeObserver.observe(containerRef.current);

  if (data === undefined) return null;

  return (
    <StyledContainer negative={isDownOnDay}>
      <ComponentTitleContainer>
        <ComponentTitle>Price History</ComponentTitle>
        <ComponentSubtitle>
          Line chart of the price history over time given a certain timespan.
        </ComponentSubtitle>
      </ComponentTitleContainer>
      <TimeSelection timespan={timespan} callback={setTimespan} />
      <StyledPriceLineContainer ref={containerRef}>
        {loading ? (
          <Spinner />
        ) : data.length ? (
          <AtomPriceLineChart
            resize={resizeTrigger}
            data={data}
            margin={{ top: 10, left: 10, right: 50, bottom: 40 }}
            axisDateFormat={formats.axis}
            tooltipDateFormat={formats.tooltip}
            crosshair={true}
          ></AtomPriceLineChart>
        ) : (
          <NoDataCenteredText>No price data available</NoDataCenteredText>
        )}
      </StyledPriceLineContainer>
    </StyledContainer>
  );
};

export default React.memo(PriceChart);

const StyledContainer = styled.div<{ negative: boolean | null }>`
  box-sizing: inherit;
  padding: 20px;
  overflow: hidden;

  @media (max-width: 414px) {
    padding: 5px;
  }

  svg {
    overflow: visible;
  }

  .price-line {
    fill: none;
    stroke: ${props => (props.negative ? 'var(--red)' : 'var(--green)')};
  }

  .tick {
    line {
      stroke: var(--text-primary);
      stroke-opacity: 0.1;
    }
    text {
      fill: var(--text-primary);
      font-size: 0.75em;
    }
  }
`;

const StyledPriceLineContainer = styled.div`
  width: 100%;
  min-width: 200px;
  height: 200px;
  padding: 0;
  margin: 0;
  display: flex;
`;
