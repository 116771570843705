import React from 'react';
import styled from 'styled-components';

export const RadioSlider = ({
  options,
  current,
  update,
}: {
  options: string[];
  current: string;
  update: any;
}) => {
  return (
    <Container>
      {options.map(option => (
        <ToggleButtonContainer
          key={option}
          selected={current === option}
          onClick={() => {
            if (option !== current) {
              update(option);
            }
          }}
        >
          {option}
        </ToggleButtonContainer>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
`;

const ToggleButtonContainer = styled.div<{ selected: boolean }>`
  background-color: ${props =>
    props.selected ? 'var(--background-secondary)' : 'transparent'};
  border-radius: 0.9rem;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  cursor: pointer;
  font-size: 14px;
  font-weight: ${props => (props.selected ? 'bold' : 'none')};
`;
