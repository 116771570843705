import React from 'react';
import styled from 'styled-components';
import { ReactComponent as AtomFinanceLogo } from '../static/images/atomFinanceLogo.svg';

const PoweredByAtom = () => {
  return (
    <Container
      onClick={() => {
        window.location.href = 'https://atom.finance/enterprise';
      }}
    >
      powered by
      <LogoContainer>
        <AtomFinanceLogo />
      </LogoContainer>
    </Container>
  );
};

export default React.memo(PoweredByAtom);

const Container = styled.div`
  background-color: var(--background-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
  font-weight: 400;
  color: #99999f;
  cursor: pointer;
  column-gap: 0.25em;
  svg > path {
    fill: var(--contrast);
  }
`;

const LogoContainer = styled.div`
  margin-top: 3px;
`;
