import React from 'react';
import styled, { keyframes } from 'styled-components';
import { usePrevious } from '../hooks';

const FlashingPrice = ({
  price,
  highlightClass,
  small = false,
}: {
  price: number;
  highlightClass: string;
  small?: boolean;
}) => {
  const oldPrice = usePrevious(price);
  return (
    <StyledFlashPriceContainer>
      <StyledFlashPrice
        key={price} // force a re-render for the keyframe
        className={oldPrice && highlightClass} // don't run keyframe on first render
        small={small}
      >
        {price}
      </StyledFlashPrice>
    </StyledFlashPriceContainer>
  );
};

const flashRed = keyframes`
  0% {
    background-color: var(--price-down);
  }
  100% {
    opacity: 100%;
  }
`;

const flashGreen = keyframes`
  0% {
    background-color: var(--price-up);
  }
  100% {
    opacity: 100%;
  }
`;

const StyledFlashPriceContainer = styled.div`
  margin-left: auto;
`;

const StyledFlashPrice = styled.div<{ small: boolean }>`
  font-size: ${({ small }) => (small ? 16 : 28)}px;
  width: fit-content;
  border-radius: 5px;
  padding: 2px 5px;
  /* This aligns the price on the right with the text below (due to the flashing span space) */
  transform: translate(5px);
  &.redFade {
    animation: 1s ${flashRed};
  }
  &.greenFade {
    animation: 1s ${flashGreen};
  }
`;

export default React.memo(FlashingPrice);
