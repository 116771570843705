/*
 * More detailed documentation here:
 * https://www.notion.so/atomfinance/AtomIcon-1c054b86c2604bc3b5673edc660786e4
 *
 * This component assumes that all imported icon svg assets have a background
 * and icon shape with classNames st0 and st1 respectively. These classnames
 * are used to style the icon background(.st0) and icon shape(.st1)
 *
 * <AtomIcon
 *   icon='chevron-down'
 *   size={16}
 *   backgroundCss={`
 *     fill: none;
 *   `}
 *   iconCss={`
 *     stroke-width: 1.5;
 *     stroke: #000;
 *   `}
 * />
 */
import React, { CSSProperties } from 'react';
import styled from 'styled-components';

import { ReactComponent as Share } from '../static/icons/Share.svg';
import { ReactComponent as Close } from '../static/icons/CloseLarge.svg';
import { ReactComponent as PoweredByLogo } from '../static/icons/PoweredByLogo.svg';
import { ReactComponent as ChevronDownSmall } from '../static/icons/ChevronDownSmall.svg';
import { ReactComponent as ArrowRightLarge } from '../static/icons/ArrowRightLarge.svg';
import { ReactComponent as ArrowLeftLarge } from '../static/icons/ArrowLeftLarge.svg';
import { ReactComponent as PriceArrow } from '../static/icons/PriceArrow.svg';
import { ReactComponent as Search } from '../static/icons/Search.svg';
import { ReactComponent as Bell } from '../static/icons/Bell.svg';
import { ReactComponent as SwitchOff } from '../static/icons/SwitchOff.svg';
import { ReactComponent as SwitchOn } from '../static/icons/SwitchOn.svg';
import { ReactComponent as Add } from '../static/icons/AddLarge.svg';
import { ReactComponent as Checkmark } from '../static/icons/CheckmarkLarge.svg';
import { ReactComponent as HeartOpen } from '../static/icons/HeartOpen.svg';
import { ReactComponent as HeartFilled } from '../static/icons/HeartFilled.svg';
import { ReactComponent as EyeOpen } from '../static/icons/EyeOpen.svg';
import { ReactComponent as EyeClose } from '../static/icons/EyeClose.svg';
import { ReactComponent as Web } from '../static/icons/Web.svg';
import { ReactComponent as Mobile } from '../static/icons/Mobile.svg';
import { ReactComponent as Feed } from '../static/icons/Feed.svg';
import { ReactComponent as Portfolio } from '../static/icons/Portfolio.svg';
import { ReactComponent as Company } from '../static/icons/Company.svg';
import { ReactComponent as Chat } from '../static/icons/Chat.svg';
import { ReactComponent as Globe } from '../static/icons/Globe.svg';
import { ReactComponent as Question } from '../static/icons/Question.svg';
import { ReactComponent as ListView } from '../static/icons/ListView.svg';
import { ReactComponent as GridView } from '../static/icons/GridView.svg';
import { ReactComponent as Key } from '../static/icons/Key.svg';
import { ReactComponent as Token } from '../static/icons/Token.svg';
import { ReactComponent as BoldRightArrow } from '../static/icons/BoldRightArrow.svg';
import { ReactComponent as Calendar } from '../static/icons/Calendar.svg';
import { ReactComponent as Investor } from '../static/icons/Investor.svg';
import { ReactComponent as Actionable } from '../static/icons/Actionable.svg';
import { ReactComponent as Investing } from '../static/icons/Investing.svg';
import { ReactComponent as Refresh } from '../static/icons/Refresh.svg';

export type AtomIcons =
  | 'question'
  | 'chat'
  | 'company'
  | 'portfolio'
  | 'feed'
  | 'mobile'
  | 'web'
  | 'share'
  | 'close'
  | 'powered-by'
  | 'chevron-down-small'
  | 'arrow-right-large'
  | 'arrow-left-large'
  | 'price-arrow'
  | 'search'
  | 'bell'
  | 'switch-off'
  | 'switch-on'
  | 'add'
  | 'checkmark'
  | 'heart-open'
  | 'heart-filled'
  | 'eye-open'
  | 'eye-close'
  | 'globe'
  | 'list-view'
  | 'grid-view'
  | 'key'
  | 'token'
  | 'bold-right-arrow'
  | 'calendar'
  | 'investor'
  | 'actionable'
  | 'investing'
  | 'refresh';

const iconToSVG: Record<AtomIcons, any> = {
  'question': Question,
  'chat': Chat,
  'company': Company,
  'portfolio': Portfolio,
  'feed': Feed,
  'mobile': Mobile,
  'web': Web,
  'share': Share,
  'close': Close,
  'powered-by': PoweredByLogo,
  'chevron-down-small': ChevronDownSmall,
  'arrow-right-large': ArrowRightLarge,
  'arrow-left-large': ArrowLeftLarge,
  'price-arrow': PriceArrow,
  'search': Search,
  'bell': Bell,
  'switch-off': SwitchOff,
  'switch-on': SwitchOn,
  'add': Add,
  'checkmark': Checkmark,
  'heart-open': HeartOpen,
  'heart-filled': HeartFilled,
  'eye-open': EyeOpen,
  'eye-close': EyeClose,
  'globe': Globe,
  'list-view': ListView,
  'grid-view': GridView,
  'key': Key,
  'token': Token,
  'bold-right-arrow': BoldRightArrow,
  'calendar': Calendar,
  'investor': Investor,
  'actionable': Actionable,
  'investing': Investing,
  'refresh': Refresh,
};

type AtomIconProps = {
  size: number;
  icon: AtomIcons;
  style?: CSSProperties;
  backgroundCss?: string;
  iconCss?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  cssForHover?: string;
  pointer?: boolean;
};

const CenterBox = styled.div<{
  backgroundCss?: string;
  iconCss?: string;
  clickable: boolean;
  cssForHover?: string;
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'inherit')};
  svg .st0 {
    ${props => props.backgroundCss};
  }
  svg .st1 {
    ${props => props.iconCss};
  }
  svg:hover {
    ${props => props.cssForHover}
  }
`;

const AtomIcon: React.FC<AtomIconProps> = ({
  icon,
  size,
  backgroundCss /* styles the svg background shape */,
  iconCss /* styles the svg icon shape */,
  cssForHover /* styles the icon when you hover */,
  onClick,
  onMouseEnter,
  onMouseLeave,
  pointer,
  style,
}) => {
  const SVG = iconToSVG[icon];
  return (
    <CenterBox
      backgroundCss={backgroundCss}
      iconCss={iconCss}
      style={style}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      clickable={!!(onClick || pointer)}
      cssForHover={cssForHover}
    >
      <SVG height={size} />
    </CenterBox>
  );
};

export default AtomIcon;
