import { useQuery } from '@apollo/react-hooks';
import React, { useState } from 'react';
import styled from 'styled-components';
import { GET_OVERVIEW_FOR_SYMBOL } from '../queries';
import numeral from '@toggleglobal/numeral';
import moment from 'moment-timezone';
import FinanceTermTooltip from '../Tooltip/FinanceTermTooltip';
import {
  ComponentTitle,
  ComponentTitleContainer,
} from '../commonStyledElements';

const formatDollars = (value): String => {
  return value ? numeral(value).format('($0.00a)').toUpperCase() : 'N/A';
};

const formatPercent = (value): String => {
  return value ? numeral(value).format('(0.00%)') : 'N/A';
};

const Overview = ({ symbol }: { symbol: string }) => {
  const { loading, data } = useQuery(GET_OVERVIEW_FOR_SYMBOL, {
    variables: { symbol },
  });
  const [expandedDescription, setExpandedDescription] = useState(false);

  const overviewData = data?.overviewForSymbol;
  const marketDataFields = {
    'Market Cap': formatDollars(overviewData?.marketCap),
    'Net Debt & Pref.': formatDollars(overviewData?.netDebt),
    'Enterprise Value (EV)': formatDollars(overviewData?.enterpriseValue),
    'Beta': overviewData?.beta
      ? `${numeral(overviewData.beta).format('(0.00a)')}x`
      : 'N/A',
    'Borrow Cost': formatPercent(overviewData?.borrowCost),
    'Next Earnings': overviewData?.nextEarningsDate
      ? moment(parseInt(overviewData.nextEarningsDate)).format('MM/DD/YYYY')
      : 'N/A',
    'Dividend Yield': formatPercent(overviewData?.dividendYield),
    'Avg. Volume': overviewData?.averageVolume
      ? `${numeral(overviewData.averageVolume)
          .format('(0.00a)')
          .toUpperCase()} shrs.`
      : 'N/A',
    '52 Week Hi': formatDollars(overviewData?.week52High),
    '52 Week Low': formatDollars(overviewData?.week52Low),
  };
  return (
    <>
      {!loading && !!overviewData && (
        <Container>
          <ComponentTitleContainer>
            <ComponentTitle>Overview</ComponentTitle>
          </ComponentTitleContainer>
          <SectionContainer style={{ marginTop: '0px' }}>
            <DescriptionAndExpandContainer>
              <MediumText>Description</MediumText>
              <ExpandToggle
                expanded={expandedDescription}
                onClick={() => {
                  setExpandedDescription(!expandedDescription);
                }}
              />
            </DescriptionAndExpandContainer>
            <DescriptionLabel expanded={expandedDescription}>
              {overviewData.description}
            </DescriptionLabel>
          </SectionContainer>
          <SectionContainer>
            <MediumText>Sector</MediumText>
            <SmallText>{overviewData.sector}</SmallText>
          </SectionContainer>
          <SectionContainer>
            <MediumText>Industry</MediumText>
            <SmallText>{overviewData.industry}</SmallText>
          </SectionContainer>
          <MarketDataContainer>
            <ComponentTitleContainer>
              <ComponentTitle>Market Data</ComponentTitle>
            </ComponentTitleContainer>
            <MarketDataRows>
              {Object.keys(marketDataFields).map(field => {
                return (
                  <MarketDataRow key={field}>
                    <TitleAndTooltipContainer>
                      <SmallText style={{ color: '#99999F' }}>
                        {field}
                      </SmallText>
                      <FinanceTermTooltip term={field} />
                    </TitleAndTooltipContainer>
                    <SmallText>{marketDataFields[field] ?? 'N/A'}</SmallText>
                  </MarketDataRow>
                );
              })}
            </MarketDataRows>
          </MarketDataContainer>
        </Container>
      )}
    </>
  );
};

export default React.memo(Overview);

const DescriptionAndExpandContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DescriptionLabel = styled.div<{ expanded: Boolean }>`
  font-size: 13px;
  font-weight: 400;
  color: var(--contrast);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${props =>
    props.expanded ? 100 : 4}; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

const ExpandToggle = styled.div.attrs<ExpandedType>(({ expanded }) => ({
  className: `fa ${expanded ? 'fa-minus-square' : 'fa-plus-square'}`,
}))<ExpandedType>`
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  color: var(--highlight);
  cursor: pointer;
  height: 15px;
  width: 15px;
  &:hover {
    color: var(--contrast);
  }
`;

interface ExpandedType {
  expanded: boolean;
}

const TitleAndTooltipContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const MarketDataContainer = styled.div`
  margin-top: 36px;
`;

const MarketDataRows = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3px;
`;

const MarketDataRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Container = styled.div`
  padding: 24px;
  @media (max-width: 414px) {
    padding: 20px;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  row-gap: 3px;
`;

const MediumText = styled.div`
  font-size: 13px;
  font-weight: 700;
  color: var(--contrast);
`;

const SmallText = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: var(--contrast);
`;
