import styled from 'styled-components';

export const AtomIconRotatedContainer = styled.div<{ direction: string }>`
  display: flex;
  transform-origin: center;
  margin: 0px 3px;
  ${({ direction }) => direction === 'down' && 'transform: rotate(180deg);'}
`;

export const ComponentTitleContainer = styled.div`
  color: var(--text-primary);
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 0.25em;
`;

export const ComponentTitle = styled.div`
  color: var(--text-primary);
  font-size: 20px;
`;

export const ComponentSubtitle = styled.div`
  color: var(--text-primary);
  font-size: 14px;
  margin: auto 0px;
`;

export const NoDataCenteredText = styled.div`
  color: var(--text-secondary);
  opacity: 0.5;
  width: 100%;
  margin: auto;
  text-align: center;
`;
