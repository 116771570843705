import styled from 'styled-components';
import Modal from 'react-modal';

type ModalProps = {
  width?: string;
  height?: string;
  atomOverflow?: string;
  overflow?: string;
  lightBackground?: boolean;
};

export const StyledModal = styled(Modal)<ModalProps>`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: ${props => (props.width ? props.width : '760px')};
  height: ${props => (props.height ? props.height : '350px')};
  overflow: ${props => props.atomOverflow || 'auto'};
  font-family: D-Din Exp;

  // Show full-screen modal on mobile
  @media (max-width: 768px) {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transform: none;
    width: 100vw;
    height: 100vh;
  }
`;
