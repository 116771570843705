import React from 'react';
import styled from 'styled-components';
import AtomIcon from '../AtomIcon';
import ClosedMarketSection from './ClosedMarketSection';
import {
  formatChange,
  getQuoteTimeText,
  isOutsideNormalMarketHours,
} from './utils';
import FlashingPrice from './FlashingPrice';
import { Streamed } from '@atom-finance/river-ws-client';
import { AtomIconRotatedContainer } from '../commonStyledElements';

interface PriceTypes {
  margin?: string;
  negative?: boolean;
  larger?: boolean;
  loading?: boolean;
  small?: boolean;
}

const ChangeInfoSection = React.memo(
  ({
    isMarketClosed,
    tickerData,
    inlineRow,
  }: {
    isMarketClosed?: boolean;
    tickerData: any;
    inlineRow?: boolean;
  }) => {
    return (
      <ChangeInfoContainer isMarketClosed={isMarketClosed}>
        <ChangePercent
          negative={tickerData.changePercent < 0}
          small={inlineRow}
        >
          {tickerData.changePercent && (
            <AtomIconRotatedContainer
              direction={tickerData.changePercent < 0 ? 'down' : 'up'}
            >
              <AtomIcon
                icon='price-arrow'
                size={5}
                iconCss={`fill: var(--price-${
                  tickerData.changePercent < 0 ? 'down' : 'up'
                });`}
              />
            </AtomIconRotatedContainer>
          )}
          <ChangeInfoTextContainer>
            {(tickerData.changePercent || tickerData.changePercent === 0) &&
              formatChange(tickerData.changePercent, true)}
            %
          </ChangeInfoTextContainer>
        </ChangePercent>
        <ChangeAmount
          negative={tickerData.changePercent < 0}
          margin={'0'}
          small={inlineRow}
        >
          {tickerData.changePercent && (
            <AtomIconRotatedContainer
              direction={tickerData.changePercent < 0 ? 'down' : 'up'}
            >
              <AtomIcon
                icon='price-arrow'
                size={5}
                iconCss={`fill: var(--price-${
                  tickerData.changePercent < 0 ? 'down' : 'up'
                });`}
              />
            </AtomIconRotatedContainer>
          )}
          <ChangeInfoTextContainer>
            {(tickerData.change || tickerData.change === 0) &&
              formatChange(tickerData.change, false)}
          </ChangeInfoTextContainer>
        </ChangeAmount>
      </ChangeInfoContainer>
    );
  },
);

const QuotePriceSection = ({
  tickerData,
  exchangeCode,
  inlineRow,
}: {
  tickerData: Streamed;
  exchangeCode: any;
  inlineRow?: boolean;
}) => {
  // get latest price time from either normal hours, after hours or pre market
  const quoteTimeText = getQuoteTimeText(tickerData, exchangeCode);
  const showClosedMarketSection =
    tickerData.extendedPrice !== 0 &&
    isOutsideNormalMarketHours(exchangeCode) &&
    tickerData.extendedPriceTime &&
    isOutsideNormalMarketHours(exchangeCode, tickerData.extendedPriceTime);

  return (
    <Container>
      <StockPrices>
        <PriceAndChangeInfoContainer isMarketClosed={showClosedMarketSection}>
          <FlashingPriceContainer small={inlineRow}>
            <FlashingPrice
              price={
                (tickerData.price && tickerData.price.toFixed(2)) as number
              }
              highlightClass={tickerData.highlightClass}
              small={inlineRow}
            />
          </FlashingPriceContainer>
          <ChangeInfoSection
            isMarketClosed={showClosedMarketSection}
            tickerData={tickerData}
            inlineRow={inlineRow}
          />
        </PriceAndChangeInfoContainer>
        <FlexEndContainer>
          {showClosedMarketSection && (
            <ClosedMarketSection
              tickerData={tickerData}
              exchangeCode={exchangeCode}
              inlineRow={inlineRow}
            />
          )}
        </FlexEndContainer>
        <FlexEndContainer>
          <BottomText small={inlineRow}>{quoteTimeText}</BottomText>
        </FlexEndContainer>
      </StockPrices>
    </Container>
  );
};

export default React.memo(QuotePriceSection);

const PriceAndChangeInfoContainer = styled.div<{ isMarketClosed?: boolean }>`
  display: flex;
  flex-direction: ${props => (props.isMarketClosed ? 'row-reverse' : 'column')};
  margin-bottom: ${props => (props.isMarketClosed ? '2px' : '0px')};
`;

const ChangeInfoTextContainer = styled.span`
  color: var(--contrast);
`;

const FlexEndContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ChangeInfoContainer = styled.div<{ isMarketClosed?: boolean }>`
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-end;

  align-items: ${props => (props.isMarketClosed ? 'center' : '')};
  margin-right: ${props => (props.isMarketClosed ? '12px' : '')};
  padding-top: ${props => (props.isMarketClosed ? '5px' : '')};
`;

const FlashingPriceContainer = styled.div<{ small?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: ${props => (props.small ? 2 : 8)}px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StockPrices = styled.div`
  font-size: 28px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const ChangePercent = styled.span<PriceTypes>`
  display: flex;
  margin-right: ${props => (props.margin ? props.margin : 12)}px;
  margin-left: 4px;
  font-size: ${props => (props.small ? 12 : 16)}px;
  color: ${props => (props.negative ? 'var(--price-down)' : 'var(--price-up)')};

  @media (max-width: 768px) {
    margin-right: ${props => (props.margin ? props.margin : 10)}px;
  }
  @media (max-width: 414px) {
    margin-right: ${props => (props.margin ? props.margin : 10)}px;
    font-size: 12px;
  }
`;

const ChangeAmount = styled.span<PriceTypes>`
  display: flex;
  margin-right: ${props => (props.margin ? props.margin : 0)}px;
  margin-left: 0.3rem;
  font-size: ${props => (props.small ? 12 : 16)}px;
  color: ${props => (props.negative ? 'var(--price-down)' : 'var(--price-up)')};

  @media (max-width: 768px) {
    margin-right: ${props => (props.margin ? props.margin : 10)}px;
  }
  @media (max-width: 414px) {
    font-size: 12px;
    margin-right: ${props => (props.margin ? props.margin : 10)}px;
  }
`;

const BottomText = styled.div<{ small?: boolean }>`
  font-size: ${props => (props.small ? 10 : 14)}px;
  color: var(--text-secondary);
  text-align: right;
  white-space: nowrap;

  @media (max-width: 414px) {
    font-size: 12px;
  }
`;
