import React, { useState } from 'react';
import styled from 'styled-components';
import { TextButton } from './ui/TextButton';
import { APIGenerationModal } from './ui/APIGenerationModal';
import { SymbolTextBox } from './ui/SymbolTextBox';
import { WidgetType } from '../../helpers/generateWidgetCode';
import StockPageWidget from '../StockPageWidget';
import { RadioSlider } from './ui/RadioSlider';
import { ToggleButton } from './ui/ToggleButton';

const defaultSettings = {
  symbol: 'AAPL',
  theme: 'Dark',
  features: {
    'Quote Header': true,
    'Price Chart': true,
    'News Sentiment': true,
    'Stock Overview': true,
  },
};

const DemoStockPageWidget = () => {
  /* Demo Page State Items */
  const [symbol, setSymbol] = useState(defaultSettings.symbol);
  const [theme, setTheme] = useState(defaultSettings.theme);
  const [features, setFeatures] = useState(defaultSettings.features);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const staticSettings = {
    features,
  };
  const dynamicSettings = {
    symbol,
    theme,
  };

  return (
    <Container>
      <APIGenerationModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        widgetType={WidgetType.Stock}
        staticSettings={staticSettings}
        dynamicSettings={dynamicSettings}
      />
      <>
        <HeaderAndSubtitleContainer>
          <HeaderTitle>Stock Page Widget</HeaderTitle>
          <Subtitle>
            Our stock page widget can give you real time stock information for
            your website. Customize the options, generate the module, and plug
            and play it into your website using the setting options below.
          </Subtitle>
        </HeaderAndSubtitleContainer>
      </>
      <WidgetAndSettingsContainer>
        <WidgetParentContainer>
          <WidgetContainer>
            <StockPageWidget
              demoSymbol={symbol}
              demoQuoteHeader={features['Quote Header']}
              demoPriceChart={features['Price Chart']}
              demoStockOverview={features['Stock Overview']}
              demoNewsSentiment={features['News Sentiment']}
              demoTheme={theme}
            />
          </WidgetContainer>
        </WidgetParentContainer>
        <WidgetSettingsContainer>
          <Row>
            <DescriptionLabel>
              This stock page widget comes with customizable theming and
              toggleable features. The symbol selection below edits the display
              on the left, but when generating the widget, the symbol will be
              dynamically included in the widget. See our implementation guides
              for more details.
            </DescriptionLabel>
          </Row>
          <Row>
            <SettingsRowContainer>Symbol:</SettingsRowContainer>
            <ContainerWithWidth width={27.5}>
              {/* TODO: [EF] make this a autocomplete dropdown as we have in client */}
              <SymbolTextBox current={symbol} update={setSymbol} />
            </ContainerWithWidth>
          </Row>
          <Row>
            <SettingsRowContainer>Theme:</SettingsRowContainer>
            <ContainerWithWidth width={27.5}>
              <RadioSlider
                options={['Dark', 'Light']}
                current={theme}
                update={setTheme}
              />
            </ContainerWithWidth>
          </Row>
          {Object.entries(features).map(([key, value]) => (
            <Row key={key}>
              <SettingsRowContainer>{key}:</SettingsRowContainer>
              <ContainerWithWidth width={27.5}>
                <ToggleButton
                  key={key}
                  isToggled={value}
                  onClickHandler={value =>
                    setFeatures({ ...features, [key]: value })
                  }
                />
              </ContainerWithWidth>
            </Row>
          ))}
        </WidgetSettingsContainer>
      </WidgetAndSettingsContainer>
      <GenerateButtonContainer>
        <TextButton
          backgroundColor='var(--green)'
          hoverColor='var(--green-hover)'
          onClick={() => {
            setModalIsOpen(!modalIsOpen);
          }}
        >
          GENERATE MODULE
        </TextButton>
        {/* <TextButton
          style={{ marginTop: '2rem' }}
          backgroundColor='var(--green)'
          onClick={() => {
            delete localStorage.atom_userLoggedIn;
          }}
        >
          DELETE LOCAL STORAGE KEY
        </TextButton> */}
      </GenerateButtonContainer>
    </Container>
  );
};

export default React.memo(DemoStockPageWidget);

const Row = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
`;

const ContainerWithWidth = styled.div<{ width: number }>`
  width: ${props => props.width}rem;
`;

const HeaderAndSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  row-gap: 1.5rem;
  align-items: center;
`;

const HeaderTitle = styled.div`
  font-size: 60px;
`;

const Subtitle = styled.div`
  font-size: 1.5em;
  max-width: 70rem;
`;

const DescriptionLabel = styled.div`
  font-size: 1em;
  padding: 1em;
`;

const WidgetAndSettingsContainer = styled.div`
  width: auto;
  margin-left: 18rem;
  margin-right: 18rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 1em;

  @media (max-width: 1075px) {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    row-gap: 4rem;
  }
`;

const WidgetContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--highlight);
  align-items: center;
  border-radius: 5px;
  width: 500px;
  resize: both;
  min-width: fit-content;
  overflow: auto;
`;

const WidgetSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
  height: 100%;
  background-color: var(--background-primary);
  margin: -2rem;
  padding-top: 2rem;
  padding-bottom: 3rem;
`;

const GenerateButtonContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 30rem;
`;

const SettingsRowContainer = styled.div`
  width: 10rem;
  text-align: right;
  font-size: 18px;

  @media (max-width: 650px) {
    width: auto;
  }
`;

const WidgetParentContainer = styled.div`
  display: flex;
  justify-content: center;
`;
