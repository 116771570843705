import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import queryString from 'qs';
import PoweredByAtom from '../common/PoweredByAtom';
import { useCustomizationsFromToken } from '../common/hooks';
import ErrorBoundary from '../common/ErrorBoundary';
import MarketsChart from '../common/MarketsChart';
import WinnersAndLosers from '../common/WinnersAndLosers';
import MarketsPill from '../common/MarketsPills';

export type MarketReference = {
  symbol: string;
  color: string;
  name: string;
};

const MarketsWidget = ({
  demoMarketPills = true,
  demoMarketsChart = true,
  demoWinnersAndLosers = true,
  demoTheme = 'Light',
}: {
  demoMarketPills?: boolean;
  demoMarketsChart?: boolean;
  demoWinnersAndLosers?: boolean;
  demoTheme?: string;
}) => {
  /* Parse Query String */
  const { search } = useLocation();
  const { token, theme } = queryString.parse(search.slice(1));

  /* Parse Customizations */
  const customizations = useCustomizationsFromToken(token);
  const marketPills: undefined | boolean =
    customizations?.get('marketPills') ?? demoMarketPills;
  const marketsChart: undefined | boolean =
    customizations?.get('marketsChart') ?? demoMarketsChart;
  const winnersAndLosers: undefined | boolean =
    customizations?.get('winnersAndLosers') ?? demoWinnersAndLosers;
  const parsedTheme: undefined | string = (theme as string) ?? demoTheme;

  const marketReferences: MarketReference[] = [
    { symbol: 'SPY', color: 'var(--yellow)', name: 'S&P 500' },
    { symbol: 'QQQ', color: 'var(--orange)', name: 'Nasdaq' },
    { symbol: 'IWM', color: 'var(--purple)', name: 'Russell 2K' },
    { symbol: 'DIA', color: 'var(--blue)', name: 'Dow' },
  ];

  /* Render */
  return (
    <>
      <ErrorBoundary>
        <StyledContainer data-theme={parsedTheme}>
          {marketPills && <MarketsPill marketReferences={marketReferences} />}
          {marketsChart && <MarketsChart marketReferences={marketReferences} />}
          <PoweredByAtom />
          {winnersAndLosers && <WinnersAndLosers />}
        </StyledContainer>
      </ErrorBoundary>
    </>
  );
};

export default React.memo(MarketsWidget);

const StyledContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: fit-content;
  background-color: var(--background-primary);
  color: var(--text-primary);
`;
