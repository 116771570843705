import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export const SymbolTextBox = ({
  current,
  update,
  height,
  placeholder,
  isDisabled = false,
}: any) => {
  const [input, setInput] = useState(current || '');

  useEffect(() => {
    if (input && input.length && input !== current) {
      const handler = setTimeout(() => {
        update(input);
      }, 400);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [input, current, update]);

  return (
    <InputContainer height={height}>
      <input
        style={{
          width: '100%',
          margin: '5px',
          backgroundColor: 'transparent',
          border: 'none',
          color: 'var(--contrast)',
          textTransform: 'uppercase',
        }}
        onChange={evt => setInput(evt.target.value.toUpperCase())}
        value={input}
        placeholder={placeholder}
        disabled={isDisabled}
      />
    </InputContainer>
  );
};

const InputContainer = styled.div<{ height?: number }>`
  background-color: var(--background-secondary);
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: ${props => (props.height ? `${props.height}rem` : 'auto')};
`;
