import React from 'react';
import styled from 'styled-components';
import '@atomfinance/atom-viz-web-components';

const MarketsPage = () => {
  return (
    <StyledContainer>
      <HeaderContainer>Markets Page</HeaderContainer>
      <Rows>
        <div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </div>
        <atom-sector-sunburst />
        <atom-news-feed />
      </Rows>
      {/* <Row>
        <atom-asset-earnings-1day asset={asset} />
      </Row>
      <Row>
        <Col>
          <atom-asset-events-table asset={asset} />
        </Col>
        <Col>
          <atom-asset-earnings-barchart asset={asset} />
        </Col>
      </Row> */}
    </StyledContainer>
  );
};

const HeaderContainer = styled.div`
  color: var(--atom-viz-text-primary);
  font-size: 1.5em;
  /* padding: 20px 100px; */
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  margin: auto;
  padding: 20px 100px;
`;

const Col = styled.div`
  max-width: 50%;
`;

const Rows = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 4em; */
  atom-sector-sunburst {
    /* max-width: 500px; */
    /* height: 500px; */
  }
`;

export default MarketsPage;
