import { useQuery } from '@apollo/react-hooks';
import React from 'react';
import styled from 'styled-components';
import { SparkDataType, useSparklineData } from './hooks';
import { TOP_MOVERS_COMPS } from './queries';
import SparklineRow from './SparklineTable/SparklineRow';

const WinnersAndLosers = () => {
  /* Get Data */
  const { data, loading } = useQuery(TOP_MOVERS_COMPS, {
    variables: {
      retrieveLimit: 6,
      trExchangeCode: 'BZX',
    },
  });
  const { winners, losers } = data?.topMoversComps || {
    winners: undefined,
    losers: undefined,
  };
  const intraday = useSparklineData(
    [...(winners || []), ...(losers || [])].map(t => t.symbol),
  );
  const intradayMap = new Map<string, SparkDataType>(intraday);

  /* Render */
  if (loading || data === null) return null;
  return (
    <StyledContainer>
      <StyledSection>
        <StyledHeader>Top Gainers</StyledHeader>
        <div>
          {winners &&
            winners
              .filter((_, i) => i <= 5)
              .map(d => (
                <SparklineRow
                  key={d.id}
                  symbol={d.symbol}
                  name={d.name}
                  data={intradayMap.get(d.symbol) || undefined}
                />
              ))}
        </div>
      </StyledSection>
      <StyledSection>
        <StyledHeader>Top Losers</StyledHeader>
        <div>
          {losers &&
            losers
              .filter((_, i) => i <= 5)
              .map(d => (
                <SparklineRow
                  key={d.id}
                  symbol={d.symbol}
                  name={d.name}
                  data={intradayMap.get(d.symbol) || undefined}
                />
              ))}
        </div>
      </StyledSection>
    </StyledContainer>
  );
};

export default React.memo(WinnersAndLosers);

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px 20px 20px 20px;

  /* @media (max-width: 1075px) {
    display: inline;
    padding: 0px;
  } */
`;

const StyledSection = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 0px;
  width: 100%;

  @media (max-width: 1075px) {
    padding: 0px 20px;
  }
`;

const StyledHeader = styled.div`
  font-size: 1.5em;
  margin: 20px 0px;

  @media (max-width: 414px) {
    font-size: 1em;
  }
`;
