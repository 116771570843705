import React from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/dist/tippy.css';
import infoIcon from '../static/icons/InfoLetterI.svg';

export const Spinner = styled.div<{
  smaller?: boolean;
}>`
  width: 100%;
  height: 100%;
  display: flex;

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  &:before {
    margin: auto;
    content: '';
    width: ${props => (props.smaller ? '20px' : '75px')};
    height: ${props => (props.smaller ? '20px' : '75px')};
    display: block;
    border-radius: 50%;
    border-top: 2px solid var(--highlight);
    animation: spinner 0.5s linear infinite;
  }
`;

interface StyleTippyContentProps {
  customWidth: string;
}

type AtomTooltipType = {
  title?: string;
  subtitle?: string | JSX.Element;
  icon?: any;
  customItem?: any;
  altText: string;
  iconHeight?: string;
  tooltipDirection?:
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'top-start'
    | 'top-end'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'bottom-end'
    | 'bottom-start';
  marginLeft?: string;
  marginRight?: string;
  children?: any;
  tooltipNumber?: number;
  customWidth?: string;
  verticalOffset?: string;
  featureSource?: string;
};

export const AtomTooltip = React.memo(function AtomTooltip({
  title,
  subtitle,
  icon,
  customItem,
  altText,
  iconHeight,
  tooltipDirection,
  marginLeft,
  marginRight,
  children,
  customWidth,
  verticalOffset,
}: AtomTooltipType) {
  let offset: [number, number] = [
    0,
    verticalOffset ? parseInt(verticalOffset) : 0,
  ];

  // if the direction is horizontal, the x, y axis will swap
  if (tooltipDirection?.search(/(right|left)/) !== -1) {
    offset = [offset[1], offset[0]];
  }

  const width: string = customWidth || '200px';

  return (
    <>
      <StyledTippyIcon
        arrow
        interactive
        trigger='mouseenter'
        delay={50}
        theme={'light'}
        placement={tooltipDirection || 'top'}
        maxWidth={'none'}
        offset={offset}
        content={
          <StyledTippyContent customWidth={width}>
            {title && (
              <TooltipTitle style={{ marginBottom: subtitle ? '8px' : '0px' }}>
                {title}
              </TooltipTitle>
            )}
            {subtitle && <TooltipSubtitle>{subtitle}</TooltipSubtitle>}
            {(title || subtitle) && children && (
              <div style={{ height: '1.5rem' }} />
            )}
            {children && children}
          </StyledTippyContent>
        }
      >
        <TooltipIcon>
          {customItem ? (
            <IconWrapper>{customItem}</IconWrapper>
          ) : (
            <img
              src={icon ? icon : infoIcon}
              style={{
                height: iconHeight || '14px',
                marginLeft: marginLeft || '8px',
                marginRight: marginRight || '0px',
              }}
              alt={altText}
            />
          )}
        </TooltipIcon>
      </StyledTippyIcon>
    </>
  );
});

const TooltipTitle = styled.div`
  font-size: 20px;
`;

const TooltipSubtitle = styled.div`
  font-size: 12px;
`;

const IconWrapper = styled.div`
  display: flex;
`;

const TooltipIcon = styled.div`
  &:hover {
    cursor: pointer;
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTippyContent = styled.div<StyleTippyContentProps>`
  width: ${props => props.customWidth};
  opacity: 1;
  padding: 0.5rem;
`;

const StyledTippyIcon = styled(Tippy)`
  min-width: 10em;
  margin-left: 9px;
`;
