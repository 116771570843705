import styled from 'styled-components';

export const TextButton = styled.div<{
  backgroundColor?: string;
  hoverColor?: string;
  disabled?: boolean;
}>`
  width: auto;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  border-radius: 3px;
  padding: 1.25rem;
  color: var(--atom-${props => (props.disabled ? 'brand-7' : 'brand-3')});
  cursor: pointer;
  background-color: ${props => props.backgroundColor || 'none'};
  &:hover {
    background-color: ${props =>
      props.disabled ? 'none' : props.hoverColor || 'none'};
  }
  text-transform: uppercase;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;
