/* eslint-disable no-undef */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/prefer-default-export */

import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import ApolloClient from 'apollo-client';
import { ApolloProvider } from '@apollo/react-hoc';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { BrowserRouter } from 'react-router-dom';
import {
  applyPolyfills,
  defineCustomElements,
} from '@atomfinance/atom-viz-web-components/loader';

const urlParams = new URLSearchParams(window.location.search);

const token = urlParams.get('token') || '';

const httpLink = createHttpLink({
  uri: '/graphql',
  credentials: 'same-origin',
  headers: { 'viz-token': token },
});

// eslint-disable-next-line import/prefer-default-export
export const apolloClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
  name: 'atom-web',
});

const Entry = (
  <ApolloProvider client={apolloClient}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>
);

// Atom Viz Web Components Polyfill
applyPolyfills().then(() => {
  defineCustomElements(window);
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(Entry);
