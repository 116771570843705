import React, { useState } from 'react';
import styled from 'styled-components';
import useElementSize from '../../../common/hooks';
import '@atomfinance/atom-viz-web-components';
import { SearchSection } from './SearchBar';

const CIBCWrapper = () => {
  const [containerRef] = useElementSize();
  const [searchTicker, setSearchTicker] = useState('AAPL');

  return (
    <StyledContainer ref={containerRef}>
      <SearchSection
        searchTicker={searchTicker}
        setSearchTicker={setSearchTicker}
      />
      <AssetSection>
        <BlockContainer>
          <SectionTitle>Asset Overview</SectionTitle>
          <atom-asset-header asset={searchTicker} />
          <atom-asset-overview asset={searchTicker} />
        </BlockContainer>
        {/* <BlockContainer>
          <atom-asset-price-history symbol={searchTicker}/>
        </BlockContainer> */}
        <BlockContainer>
          <atom-insights-pillar-table
            asset={searchTicker}
            header={true}
            compare={true}
            pillar-score={true}
            difference-column={true}
            score-column={true}
            pillar={'valuation'}
          />
        </BlockContainer>
        <BlockContainer>
          <SectionTitle>News</SectionTitle>
          <atom-news-feed asset={searchTicker} />
        </BlockContainer>
      </AssetSection>
    </StyledContainer>
  );
};

const SectionTitle = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  font-size: 2em;
  font-weight: bold;
`;

const StyledContainer = styled.div`
  min-height: 100%;
  background-color: #151418;
  width: 80%;
  padding: 40px;
  margin-left: calc(20% + 60px);
  @media (max-width: 1000px) {
    margin: 0%;
    width: 100%;
  }
`;

const AssetSection = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;

  @media (max-width: 1000px) {
    width: 95%;
  }

  atom-news-feed {
    padding: 0px 10px;
  }

  atom-asset-header-name,
  atom-asset-header-price {
    gap: 10px;
  }

  .atom-asset-overview-header {
    font-size: 1.5em;
  }

  .atom-news-feed-card-parent {
    &:hover {
      background-color: #2f2b3e;
    }
    gap: 0.8em;
  }

  atom-news-feed .atom-news-feed-cards {
    height: 600px;
    overflow-y: scroll;
  }

  atom-news-feed .atom-news-feed-filters {
    padding: 0.75em 0;
  }

  atom-news-feed .atom-news-feed-card-parent .atom-news-feed-card-teaser {
    line-height: 150%;
  }
`;

const BlockContainer = styled.div`
  margin-top: 40px;
  height: 100%;
  background-color: #1f1d26;
  border-radius: 10px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  atom-asset-header,
  atom-asset-overview {
    padding: 0px 10px;
  }

  .atom-news-feed-filters select {
    color: var(--atom-viz-text-primary);
    background-color: #2f2b3e;
    padding: 8px;
    border-radius: 4px;
    border: none;
    font-family: var(--font);

    &::placeholder {
      font-family: var(--font);
    }
  }
`;

export default CIBCWrapper;
