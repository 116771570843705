export enum WidgetType {
  RevenueEarnings = 'revenue-earnings',
  FundAllocation = 'fund-allocation',
  Sparkline = 'sparkline-table',
  Stock = 'stock',
  Markets = 'markets',
}

const generateWidgetScaffolding = (
  widget: WidgetType,
  vizId: String,
  dynamicSettings?: undefined | { [key: string]: string },
) => {
  const topText = `
      <!-- Atom Widget -->
        <div class="atom_widget" id="${widget}-container">
          <script class="atom_widget" id="${widget}-script" type="text/javascript" src="https://atom-packages.s3.amazonaws.com/atom-viz/embedWidget.js">
            {
              "widget": "${widget}",`;
  const middleText = generateWidgetSettings(vizId, dynamicSettings);
  const bottomText = `
            }
          </script>
          <div class="atom_widget" id="${widget}-widget"></div>
        </div>
      <!-- Atom Widget -->
    `;

  const widgetCode = topText + middleText + bottomText;
  return widgetCode;
};

const generateWidgetSettings = (
  vizId: String,
  dynamicSettings?: undefined | { [key: string]: string },
) => {
  let middleText;
  if (dynamicSettings) {
    const settings = {
      token: vizId,
      ...dynamicSettings,
    };
    // this is a little extra work but it makes for clear spacing when the text is generated so the dynamic values can be parsed
    middleText = `
              "settings": {${Object.entries(settings).map(
                ([key, value]) => `
                "${key}": "${value}"`,
              )}
              }`;
  } else {
    middleText = `
              "settings": {
                "token": "${vizId}",
              },`;
  }
  return middleText;
};

export default generateWidgetScaffolding;
