import React, { useState } from 'react';
import styled from 'styled-components';
import '@atomfinance/atom-viz-web-components';
import NasdaqComponentWrapper from './NasdaqComponentWrapper';

const Navigation = ({ selection, setSelection }) => {
  return (
    <StyledNavigation>
      <StyledNavLink
        className={selection === 'Environmental' ? 'selected' : ''}
        onClick={() => setSelection('Environmental')}
      >
        Environmental
      </StyledNavLink>

      <StyledNavLink
        className={selection === 'Social' ? 'selected' : ''}
        onClick={() => setSelection('Social')}
      >
        Social
      </StyledNavLink>

      <StyledNavLink
        className={selection === 'Governance' ? 'selected' : ''}
        onClick={() => setSelection('Governance')}
      >
        Governance
      </StyledNavLink>
    </StyledNavigation>
  );
};

const StyledNavLink = styled.div`
  border-bottom: solid white 2px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.75em;
  &:hover,
  &.selected {
    border-bottom-color: inherit;
    color: #3f92b9;
  }
`;

const StyledNavigation = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 20px 0px 0px 0px;
`;

const NasdaqDemo = () => {
  const [selection, setSelection] = useState('Social');

  return (
    <StyledContainer>
      <Navigation selection={selection} setSelection={setSelection} />
      <StyledDashboardParent>
        <StyledHeader>
          <StyledTitleContainer>
            <div>
              <div className='title'>
                {selection === 'Environmental'
                  ? '1.5C Global Target'
                  : selection === 'Social'
                  ? 'RepRisk'
                  : 'Gender Equality'}
              </div>
              <div className='sub-title'>Powered by [company]</div>
            </div>
            <div className='button'> &gt; Explore the dataset</div>
          </StyledTitleContainer>
          <div>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Pellentesque fermentum vulputate velit vel auctor. Donec tempor
            dolor in magna gravida tincidunt. Vivamus ultrices, massa vel
            volutpat pretium, velit velit volutpat est, eget sagittis nisl ante
            quis libero. Aenean a massa massa. Etiam nec elit erat. Fusce justo
            tortor, efficitur ac tincidunt in, rutrum id ex.
          </div>
        </StyledHeader>
        <NasdaqComponentWrapper selection={selection} />
        <SampleTable />
      </StyledDashboardParent>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  width: 100%;
  background-color: white;
  color: #838383;
  font-family: 'Rubik';
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #eee;
  padding: 20px 20%;
  gap: 10px;
`;

const StyledDashboardParent = styled.div`
  font-size: 0.75em;
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: black;

  .title {
    font-size: 1.5em;
  }
  .subtitle {
    font-size: 0.75em;
  }

  .button {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    font-family: 'Rubik';
    font-weight: 700;
    font-size: 0.75em;
    color: white;
    background-color: #3f92b9;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 0px 10px;
    border-radius: 2px;
  }
`;

const SampleTable = () => {
  return (
    <StyledTableContainer>
      <StyledTableHeader>Sample Data</StyledTableHeader>
      <StyledTable className='table table-bordered table-hover table-condensed'>
        <thead>
          <tr>
            <th title='Field #1'>isin</th>
            <th title='Field #2'>xdc_scope1</th>
            <th title='Field #3'>xdc_scope2</th>
            <th title='Field #4'>xdc_scope3</th>
            <th title='Field #5'>xdc_total_scope</th>
            <th title='Field #6'>company_name</th>
            <th title='Field #7'>country</th>
            <th title='Field #8'>FIELD8</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>US7427181091</td>
            <td align='right'>1.9</td>
            <td align='right'>1.7</td>
            <td align='right'>5.5</td>
            <td align='right'>5</td>
            <td>Procter &amp; Gamble Company</td>
            <td> </td>
            <td> </td>
          </tr>
          <tr>
            <td>US67066G1040</td>
            <td align='right'>1.3</td>
            <td align='right'>2.5</td>
            <td align='right'>2.3</td>
            <td align='right'>2.3</td>
            <td>NVIDIA Corporation</td>
            <td> </td>
            <td> </td>
          </tr>
          <tr>
            <td>US58933Y1055</td>
            <td align='right'>6.4</td>
            <td align='right'>4.7</td>
            <td align='right'>6.8</td>
            <td align='right'>6.6</td>
            <td>Merck &amp; Co., Inc.</td>
            <td> </td>
            <td> </td>
          </tr>
          <tr>
            <td>US57636Q1040</td>
            <td align='right'>1.5</td>
            <td align='right'>1.5</td>
            <td align='right'>2.7</td>
            <td align='right'>2.6</td>
            <td>Mastercard Incorporated Class A</td>
            <td> </td>
            <td> </td>
          </tr>
          <tr>
            <td>US4370761029</td>
            <td align='right'>2.7</td>
            <td align='right'>2.9</td>
            <td align='right'>6.7</td>
            <td align='right'>6.5</td>
            <td>Home Depot, Inc.</td>
            <td> </td>
            <td> </td>
          </tr>
          <tr>
            <td>US30231G1022</td>
            <td align='right'>6.2</td>
            <td align='right'>7.5</td>
            <td align='right'>5.1</td>
            <td align='right'>5.4</td>
            <td>Exxon Mobil Corporation</td>
            <td> </td>
            <td> </td>
          </tr>
          <tr>
            <td>US1912161007</td>
            <td align='right'>2.8</td>
            <td align='right'>4.3</td>
            <td align='right'>6.5</td>
            <td align='right'>6.1</td>
            <td>Coca-Cola Company</td>
            <td>United States</td>
            <td> </td>
          </tr>
        </tbody>
      </StyledTable>
    </StyledTableContainer>
  );
};

const StyledTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #eee;
  padding: 20px 20%;
  gap: 10px;
`;

const StyledTableHeader = styled.div`
  font-size: 1.5em;
  color: black;
`;

const StyledTable = styled.table`
  margin-top: 10px;
  border-collapse: collapse;
  border-radius: 3px;
  * {
    border: #ccc solid;
    padding: 5px 2px;
  }
`;

export default React.memo(NasdaqDemo);
