import React, { useEffect, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import styled from 'styled-components';
import AtomIcon from '../../../common/AtomIcon';

const ColorPaletteComponent = ({
  input,
  setInput,
  closePalette,
}: {
  input: string;
  setInput: (value: string) => void;
  closePalette: () => void;
}) => {
  const [current, setCurrent] = useState(input);
  const initialColor = useRef(input);
  const inputReference = useRef<any>(null);

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        closePalette();
      } else if (event.key === 'Escape') {
        event.preventDefault();
        setInput(initialColor.current);
        setCurrent(initialColor.current);
        closePalette();
      } else if (event.key === 'Tab') {
        event.preventDefault();
        if (inputReference?.current) {
          inputReference.current.focus();
        }
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [closePalette, setInput]);

  return (
    <>
      <FullWindowModal onClick={closePalette} />
      <PaletteWrapper>
        <HexColorPicker
          color={input}
          onChange={hex => {
            hex = hex.toUpperCase();
            setInput(hex);
            setCurrent(hex);
          }}
        />
        <ColorInput
          ref={inputReference}
          style={{ textAlign: 'left' }}
          value={current}
          onFocus={() => {
            setCurrent('#');
          }}
          onChange={evt => {
            const hex = evt.target.value;
            setCurrent(hex);
            if (hex.match(/^#[A-Ea-e0-9]{6}$/)) {
              setInput(hex.toUpperCase());
            }
          }}
        />
        <AtomIconContainer>
          <AtomIcon
            icon={'checkmark'}
            size={22}
            iconCss='stroke: var(--green)'
            onClick={closePalette}
          />
        </AtomIconContainer>
      </PaletteWrapper>
    </>
  );
};

const ColorPalette = React.memo(ColorPaletteComponent);

type BaseInputProps = {
  update: any;
  current: string;
  setChildOpen?: any;
  disable: boolean;
};

export const ColorPreviewWithPalette = (props: BaseInputProps): JSX.Element => {
  const { update, current, disable } = props;
  const [initialColor] = useState(current);
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <ConfigInputContainer>
      <InputContainer>
        <div
          style={{
            backgroundColor: current,
            width: '1em',
            height: '1em',
            margin: '5px',
          }}
        />
        <PaletterItem
          onClick={
            disable && !isOpen
              ? undefined
              : () => {
                  setOpen(!isOpen);
                }
          }
        >
          {current}
        </PaletterItem>
        <AtomIcon
          size={18}
          icon='refresh'
          onClick={() => {
            update(initialColor);
          }}
        />
      </InputContainer>
      {isOpen && (
        <ColorPalette
          closePalette={() => {
            setOpen(false);
          }}
          setInput={update}
          input={current}
        />
      )}
    </ConfigInputContainer>
  );
};

const AtomIconContainer = styled.div`
  position: absolute;
  right: 3.5rem;
  bottom: 2.6rem;
`;

const FullWindowModal = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const ConfigInputContainer = styled.div`
  background-color: var(--atom-dark-brand-2);
  margin-bottom: 1em;
  display: grid;
  column-gap: 10px;
  position: relative;
  height: 0px;
`;

const InputContainer = styled.div`
  background-color: var(--atom-dark-brand-4);
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding-right: 0.5rem;
  width: 8rem;
  justify-content: space-between;
`;

const PaletterItem = styled.div`
  background-color: var(--atom-dark-brand-4);
  letter-spacing: 1px;
  border: none;
  color: var(--contrast);
  word-break: break-all;
`;

const PaletteWrapper = styled.div`
  position: absolute;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  box-sizing: border-box;
  border: 1px solid var(--atom-dark-brand-6);
  width: 25rem;
  top: -20vh;
  left: 35%;
  background-color: var(--atom-dark-brand-4);
  border-radius: 3px;
  box-shadow: 0px 0px 40px 10px rgb(0, 0, 0, 0.5);
  z-index: 10;
  & .react-colorful {
    width: 100%;
    display: inherit;
    height: max-content;
  }
  & .react-colorful__saturation-pointer,
  & .react-colorful__hue-pointer,
  & .react-colorful__alpha-pointer {
    width: 0.8rem;
    height: 0.8rem;
    border: 0.1rem white solid;
  }
  & .react-colorful__hue,
  & .react-colorful__saturation,
  & .react-colorful__alpha {
    border-radius: 3px;
    margin-bottom: 1rem;
    height: 1rem;
  }
  & .react-colorful__saturation {
    aspect-ratio: 1 / 1;
    height: 100%;
  }
  & .react-colorful__alpha {
    background-image: linear-gradient(to right, white, black);
  }

  @media (max-width: 1075px) {
    top: -50vh;
    left: -75%;
  }
`;

const ColorInput = styled.input`
  padding: 1rem 2rem;
  text-align: center;
  color: var(--atom-dark-contrast);
  border: 1px var(--atom-dark-brand-6) solid;
  letter-spacing: 1px;
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--atom-dark-brand-4);
  &:focus {
    border: 1px var(--green-hover) solid;
  }
`;
