import React, { useState, useEffect, useCallback } from 'react';
import {
  Redirect,
  Route,
  Switch,
  withRouter,
  Link,
  useLocation,
} from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { withApollo } from '@apollo/react-hoc';
import queryString from 'qs';
import { hot } from 'react-hot-loader';
import {
  GET_AUTH_STATUS,
  GET_PARTNER_TOKEN_FROM_VIZ_ID,
} from './common/queries';
import ErrorBoundary from './common/ErrorBoundary';
import AssetAllocation from './routes/FundAllocation';
import { ContextType } from './types/main';
import styled from 'styled-components';
import RevenueEarningsBarChart from './routes/RevenueEarningsBarChart';
import DemoPage from './routes/demo/index';
import DemoFundAllocation from './routes/demo/FundAllocation';
import DemoRevenueEarningsBarChart from './routes/demo/RevenueEarningsBarChart';
import DemoStockPageWidget from './routes/demo/StockPageWidget';
import DemoMarketsWidget from './routes/demo/MarketsWidget';
import DemoSparkline from './routes/demo/Sparkline';
import ConfirmEmailScreen from './routes/demo/ConfirmEmail';
import StockPageWidget from './routes/StockPageWidget';
import { connectStreaming } from '@atom-finance/river-ws-client';
import ClientExample from './routes/demo/embedTest/ClientExample';
import MarketsWidget from './routes/MarketsWidget';
import NasdaqDemo from './routes/demo/NasdaqDemo/NasdaqDemo';
import ComponentsDemo from './routes/demo/ComponentsDemo/ComponentsDemo';
import ComponentsDemo2 from './routes/demo/ComponentsDemo2/ComponentsDemo2';
import ComponentsDemo3 from './routes/demo/ComponentsDemo3/ComponentsDemo3';
import { Components } from '@atomfinance/atom-viz-web-components';
import CibcDemo from './routes/demo/CibcDemo/CibcDemo';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'atom-esg-dashboard-map': any;
      'atom-auth': Components.AtomAuth;
      'atom-test-component': any;
      'atom-asset-header': Components.AtomAssetHeader;
      'atom-asset-overview': any; //Components.AtomAssetOverview;
      'atom-news-feed': any; //Components.AtomNewsFeed;
      'atom-news-sentiment': any;
      'atom-insights-metric-data': any;
      'atom-insights-pillar-slider': any;
      'atom-insights-pillar-table': any; //Components.AtomInsightsPillarTable;
      'atom-asset-price-history': any; //Components.AtomAssetPriceHistory;
      'atom-insights-metric-slider': any; //Components.AtomInsightsMetricSlider;
      'atom-insights-metric-slider-check': any; //Components.AtomInsightsMetricSlider;
      'atom-asset-earnings-1day': any;
      'atom-asset-earnings-table': any;
      'atom-asset-events-table': any;
      'atom-asset-earnings-barchart': any;
      'atom-asset-income-statement': any;
      'atom-asset-events-cards': any;
      'atom-asset-stats': any;
      'atom-asset-description': any;
      'atom-asset-earnings-update': any;
      'atom-news-stock-briefs': any;
      'atom-sector-sunburst': any;
      'atom-asset-whats-happening': any;
      'atom-asset-inside-view': any;
    }
  }
}

// [EF] TODO: Add redux instead
const context: ContextType = {};
export const AccountContext = React.createContext(context);

/* ⬇ TABLE OF CONTENTS PAGES GO HERE ⬇ */
const pages = [
  {
    name: 'Stock Page Widget [iFrame]',
    path: '/demo/stock',
    component: StockPageWidget,
  },
  {
    name: 'Markets Widget [iFrame]',
    path: '/demo/markets',
    component: MarketsWidget,
  },
  {
    name: 'Dark Theme Asset Page [Web Components]',
    path: '/demo/components-1',
    component: ComponentsDemo,
  },
  {
    name: 'Light Theme Asset Page [Web Components]',
    path: '/demo/components-2',
    component: ComponentsDemo2,
  },
  {
    name: 'Dark Custom Asset Page [Web Components]',
    path: '/demo/components-3',
    component: ComponentsDemo3,
  },
];

/* ⬇ NEW DEMO PAGES GO HERE ⬇ */
const demos = [
  {
    path: 'fund-allocation',
    component: DemoFundAllocation,
  },
  {
    path: 'revenue-earnings',
    component: DemoRevenueEarningsBarChart,
  },
  {
    path: 'sparkline',
    component: DemoSparkline,
  },
  {
    path: 'client-example',
    component: ClientExample,
  },
  {
    path: 'stock',
    component: DemoStockPageWidget,
  },
  {
    path: 'markets',
    component: DemoMarketsWidget,
  },
  {
    path: 'confirm-email/:id/:token',
    component: ConfirmEmailScreen,
  },
  {
    path: 'nasdaq',
    component: NasdaqDemo,
  },
  {
    path: 'components-1',
    component: ComponentsDemo,
  },
  {
    path: 'components-2',
    component: ComponentsDemo2,
  },
];

const AppInterior = withRouter(props => {
  const { isAuthenticated } = props;

  const AppContent = useCallback(
    () => (
      <>
        <Switch>
          {demos.map(demo => (
            <Route
              key={demo.path}
              path={`/demo/${demo.path}`}
              component={demo.component}
            />
          ))}
          {pages.map(page => (
            <Route
              key={page.path}
              path={page.path}
              component={page.component}
            />
          ))}
          <Route key='/demo/cibc' path='/demo/cibc' component={CibcDemo} />
          <Route key='/demo' path='/demo' component={DemoPage} />
          <Route path='/' render={() => <Redirect to='/' />} />
        </Switch>
      </>
    ),
    [],
  );

  const Unauthenticated = () => (
    <UnauthenticatedContainer>
      <UnauthenticatedText>
        Please login to access this content
      </UnauthenticatedText>
    </UnauthenticatedContainer>
  );

  return (
    <Container>
      <Switch>
        <Route
          exact
          path='/'
          render={() => (
            <ErrorBoundary>
              <AppContainer>
                <h1>Welcome to Atom&apos;s Viz Library</h1>
                <h3>Visualizations:</h3>
                {pages.map(page => (
                  <StyledLink key={page.path} to={page.path}>
                    {page.name}
                  </StyledLink>
                ))}
              </AppContainer>
            </ErrorBoundary>
          )}
        />
        {(isAuthenticated || props.location.pathname.includes('/demo')) && (
          <AppContent />
        )}
        {!isAuthenticated && (
          <Route path='/' render={() => <Unauthenticated />} />
        )}
      </Switch>
    </Container>
  );
});

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const { search } = useLocation();
  const { token: vizId } = queryString.parse(search.slice(1));

  const { data: partnerTokenForVizId } = useQuery(
    GET_PARTNER_TOKEN_FROM_VIZ_ID,
    {
      variables: { vizId },
      fetchPolicy: 'no-cache',
    },
  );

  let token = partnerTokenForVizId?.partnerTokenFromVizId?.token;
  if (!vizId) {
    token = process.env.REACT_APP_FREE_TIER_VIZ_API_TOKEN;
  }

  const {
    loading: authLoading,
    data: userData,
    refetch: refetchAuth,
  } = useQuery(GET_AUTH_STATUS, {
    variables: {
      token: token,
    },
    fetchPolicy: 'no-cache',
  });

  const partner = userData?.authStatus;

  const user = {
    id: partner?.partnerId,
    partnerId: partner?.partnerId,
  };

  useEffect(() => {
    if (token) {
      refetchAuth({ token });
    }
  }, [token, refetchAuth]);

  useEffect(() => {
    if (!partner?.partnerId || partner?.partnerId === '') {
      setIsAuthenticated(false);
      return;
    }
    setIsAuthenticated(true);
  }, [partner?.partnerId]);

  useEffect(() => {
    connectStreaming();
  }, []);

  if (authLoading || isAuthenticated == undefined) {
    return <LoadingContainer>Loading...</LoadingContainer>;
  }

  return (
    <AccountContext.Provider value={{ user }}>
      <ErrorBoundary>
        <AppInterior isAuthenticated={isAuthenticated} />
      </ErrorBoundary>
    </AccountContext.Provider>
  );
};

// eslint-disable-next-line no-undef
export default hot(module)(withApollo(App));

const UnauthenticatedContainer = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  height: 100%;
`;

const UnauthenticatedText = styled.span`
  position: relative;
  top: 50%;
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const Container = styled.div`
  font-family: D-Din Exp;
  height: 100%;
`;

const AppContainer = styled.div`
  margin: 2em;
  height: 100%;
`;

const StyledLink = styled(Link)`
  display: block;
  padding-bottom: 10px;
  color: var(--text-primary);
  text-decoration: underline;
`;
