import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import useElementSize from '../../../common/hooks';
import '@atomfinance/atom-viz-web-components';

const NasdaqComponentWrapper = ({ selection }) => {
  const [containerRef, { width, height }] = useElementSize();

  return (
    <StyledContainer ref={containerRef}>
      {width && height && (
        <atom-esg-dashboard-map
          width={width}
          height={height}
          color-min={
            selection === 'Environmental'
              ? '#ef8a41'
              : selection === 'Social'
              ? '#e44947'
              : '#ccc'
          }
          color-mid={
            selection === 'Environmental' || selection === 'Social'
              ? '#ccc'
              : '#b081ab'
          }
          color-max={
            selection === 'Environmental'
              ? '#59c070'
              : selection === 'Social'
              ? '#4093b9'
              : '#a1258a'
          }
        />
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  width: 100%;
  height: 50vh;
  background-color: white;
  color: #838383;
  font-family: 'Rubik';

  .atom-esg-dashboard-map-tooltip-name span {
    line-height: 16px;
  }
`;

export default NasdaqComponentWrapper;
